import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// Configure outbound request interceptor logic
axios.interceptors.request.use(request => {
  request.headers.set("Authorization", "Bearer " + localStorage.getItem("token"))
  return request;
}, error => Promise.reject(error));

// Configure incoming response interceptor logic
axios.interceptors.response.use(response => {
  return response;
}, error => {
  if(error?.response?.status == 401)
  {
    window.location.replace('/login');
  }
  return Promise.reject(error)
});
