export const IconPlaySvg = () => (
  <svg
    id="Component_38_12"
    data-name="Component 38 – 12"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    {" "}
    <rect
      id="Rectangle_1015"
      data-name="Rectangle 1015"
      width="18"
      height="18"
      fill="none"
    />{" "}
    <path
      id="Polygon_28"
      data-name="Polygon 28"
      d="M5.142,1.43a1,1,0,0,1,1.715,0l4.234,7.061A1,1,0,0,1,10.234,10H1.766A1,1,0,0,1,.908,8.491Z"
      transform="translate(15.005 3) rotate(90)"
      fill="none"
    />{" "}
  </svg>
);

export const IconFavSvg = () => (
  <svg
    id="Component_39_4"
    data-name="Component 39 – 4"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <rect
      id="Rectangle_1016"
      data-name="Rectangle 1016"
      width="18"
      height="18"
      fill="none"
    />
    <path
      id="heart-svgrepo-com"
      d="M2,6.809C2,9.737,4.42,11.3,6.192,12.694a3.389,3.389,0,0,0,1.829.957,3.389,3.389,0,0,0,1.829-.957c1.772-1.4,4.192-2.957,4.192-5.885s-3.312-5-6.021-2.19C5.312,1.8,2,3.881,2,6.809Z"
      transform="translate(0.979 0.532)"
      fill="none"
    />
  </svg>
);

export const IconPlayListSvg = () => (
  <svg id="Component_40_4" data-name="Component 40 – 4" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  <rect id="Rectangle_1017" data-name="Rectangle 1017" width="18" height="18" fill="none"/>
  <g id="playlist-minimalistic-2-svgrepo-com" transform="translate(1 4)">
    <path id="Path_7539" data-name="Path 7539" d="M13.448,6H3" transform="translate(-3 -6)" fill="none" stroke="#b3b3b5" stroke-linecap="round" stroke-width="1.2"/>
    <path id="Path_7540" data-name="Path 7540" d="M11.7,10H3" transform="translate(-3 -6.519)" fill="none" stroke="#b3b3b5" stroke-linecap="round" stroke-width="1.2"/>
    <path id="Path_7541" data-name="Path 7541" d="M8.224,14H3" transform="translate(-3 -4.863)" fill="none" stroke="#b3b3b5" stroke-linecap="round" stroke-width="1.2"/>
    <path id="Path_7542" data-name="Path 7542" d="M4.748,0H0" transform="translate(2.611 6.764) rotate(90)" fill="none" stroke="#b3b3b5" stroke-linecap="round" stroke-width="1.2"/>
    <path id="Path_7543" data-name="Path 7543" d="M17,15.4V8" transform="translate(-4.811 -6.26)" fill="none" stroke="#b3b3b5" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
    <ellipse id="Ellipse_26" data-name="Ellipse 26" cx="2.177" cy="2.176" rx="2.177" ry="2.176" transform="translate(7.836 6.962)" fill="none" stroke="#b3b3b5" stroke-linejoin="round" stroke-width="1.2"/>
    <path id="Path_7544" data-name="Path 7544" d="M20.483,11.481A3.482,3.482,0,0,1,17,8" transform="translate(-4.811 -6.26)" fill="none" stroke="#b3b3b5" stroke-linecap="round" stroke-width="1.2"/>
  </g>
</svg>
);

export const IconTimeLineSvg = () => (
  <svg
    id="Component_41_3"
    data-name="Component 41 – 3"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <g id="Group_72608" data-name="Group 72608" transform="translate(1.346 2)">
      <path
        id="Path_170329"
        data-name="Path 170329"
        d="M11296.777-1021.671H11289a2.568,2.568,0,0,1-1.551-.757,3.48,3.48,0,0,1-1.052-2.588,3.591,3.591,0,0,1,.758-2.318,2.718,2.718,0,0,1,1.845-1.058h7.774a2.225,2.225,0,0,0,2.414-2.51,2.251,2.251,0,0,0-2.414-2.513H11289v-.734h7.774a3.335,3.335,0,0,1,1.678.451,3.018,3.018,0,0,1,1.335,2.8,3.028,3.028,0,0,1-1.293,2.793,3.275,3.275,0,0,1-1.72.451H11289a2.184,2.184,0,0,0-1.418.84,2.741,2.741,0,0,0-.584,1.795,2.511,2.511,0,0,0,2,2.618h7.774Z"
        transform="translate(-11285.457 1035.388)"
        fill="none"
      />
      <ellipse
        id="Ellipse_62"
        data-name="Ellipse 62"
        cx="1.529"
        cy="1.529"
        rx="1.529"
        ry="1.529"
        transform="translate(1.223)"
        fill="none"
      />
      <path
        id="Ellipse_31_-_Outline"
        data-name="Ellipse 31 - Outline"
        d="M1.529.734a.795.795,0,1,0,.795.795A.8.8,0,0,0,1.529.734m0-.734A1.529,1.529,0,1,1,0,1.529,1.529,1.529,0,0,1,1.529,0Z"
        transform="translate(1.223 0)"
        fill="none"
      />
      <ellipse
        id="Ellipse_63"
        data-name="Ellipse 63"
        cx="1.529"
        cy="1.529"
        rx="1.529"
        ry="1.529"
        transform="translate(12.309 3.057)"
        fill="none"
      />
      <path
        id="Ellipse_32_-_Outline"
        data-name="Ellipse 32 - Outline"
        d="M1.529.734a.795.795,0,1,0,.795.795A.8.8,0,0,0,1.529.734m0-.734A1.529,1.529,0,1,1,0,1.529,1.529,1.529,0,0,1,1.529,0Z"
        transform="translate(12.309 3.057)"
        fill="none"
      />
      <circle
        id="Ellipse_64"
        data-name="Ellipse 64"
        cx="2.14"
        cy="2.14"
        r="2.14"
        transform="translate(5.784 5.503)"
        fill="none"
      />
      <path
        id="Ellipse_33_-_Outline"
        data-name="Ellipse 33 - Outline"
        d="M2.14.734A1.406,1.406,0,1,0,3.546,2.14,1.408,1.408,0,0,0,2.14.734M2.14,0A2.14,2.14,0,1,1,0,2.14,2.14,2.14,0,0,1,2.14,0Z"
        transform="translate(5.784 5.503)"
        fill="none"
      />
      <ellipse
        id="Ellipse_65"
        data-name="Ellipse 65"
        cx="1.529"
        cy="1.529"
        rx="1.529"
        ry="1.529"
        transform="translate(0 8.56)"
        fill="none"
      />
      <path
        id="Ellipse_34_-_Outline"
        data-name="Ellipse 34 - Outline"
        d="M1.529.734a.795.795,0,1,0,.795.795A.8.8,0,0,0,1.529.734m0-.734A1.529,1.529,0,1,1,0,1.529,1.529,1.529,0,0,1,1.529,0Z"
        transform="translate(0 8.56)"
        fill="none"
      />
      <ellipse
        id="Ellipse_66"
        data-name="Ellipse 66"
        cx="1.529"
        cy="1.529"
        rx="1.529"
        ry="1.529"
        transform="translate(10.475 11.618)"
        fill="none"
      />
      <path
        id="Ellipse_35_-_Outline"
        data-name="Ellipse 35 - Outline"
        d="M1.529.734a.795.795,0,1,0,.795.795A.8.8,0,0,0,1.529.734m0-.734A1.529,1.529,0,1,1,0,1.529,1.529,1.529,0,0,1,1.529,0Z"
        transform="translate(10.475 11.618)"
        fill="none"
      />
    </g>
    <rect
      id="Rectangle_1018"
      data-name="Rectangle 1018"
      width="18"
      height="18"
      fill="none"
    />
  </svg>
);

export const IconBugSvg = () => (
  <svg
    id="Component_42_4"
    data-name="Component 42 – 4"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <rect
      id="Rectangle_1018"
      data-name="Rectangle 1018"
      width="18"
      height="18"
      fill="none"
    />
    <g
      id="Group_72631"
      data-name="Group 72631"
      transform="translate(-1.797 -1.29)"
    >
      <path
        id="Path_170521"
        data-name="Path 170521"
        d="M13.874,9.739l.146-.86L11.8,8.514V6.63l2.176-.585-.219-.83-2.077.555A3.869,3.869,0,0,0,9.648,3.29V2h-.86v.959a3.788,3.788,0,0,0-1.72,0V2h-.86V3.29a3.869,3.869,0,0,0-2.025,2.5L2.107,5.216l-.2.83,2.15.585V8.514L1.84,8.879l.138.86,2.081-.353a3.827,3.827,0,0,0,.353,1.535L2.463,12.873l.611.611,1.8-1.806a3.869,3.869,0,0,0,6.105,0l1.8,1.806.611-.611-1.952-1.952A3.826,3.826,0,0,0,11.8,9.386ZM7.5,12.284a3.01,3.01,0,0,1-2.58-2.975V6.729H7.5ZM5.043,5.869a3.01,3.01,0,0,1,5.77,0Zm5.895,3.44a3.01,3.01,0,0,1-2.58,2.975V6.729h2.58Z"
        transform="translate(3.116 2.496)"
        fill="#b3b3b5"
      />
    </g>
  </svg>
);
