import axios from "axios";
import { searchUrl } from "../common/constants";
import { func } from "prop-types";
import { toast } from "react-toastify";
import { IGenerateVideoPayload } from "../types/interface";
import { errorToast, successToast } from "../common/utils";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};


function get_url(){
  return process.env.REACT_APP_API_BASE_URL;
  // return `https://api.language-machines.com`;
}

function getHostFromUrl(url: string){
  return new URL(url).hostname;
}

const base_url = get_url();
const host = getHostFromUrl(base_url as string);

export const login =async () => {
  try {
    const loginurl = `${base_url}/auth/login`;

    window.location.replace(loginurl);

    // Below code produce cors error
    // const response = await axios.get(loginurl);
    // return response.data;
  } catch (error) {
    console.error(error);
    
  }
}

export const getToken =async (queryString:any) => {
  try {
    const tokenUrl = `${base_url}/auth/token${queryString}`;
    const response = await axios.get(tokenUrl,{withCredentials:true});
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.error(error);
    
  }
}

export const getSearchResult = async (reqBody: any) => {
  try {
      const searchUrl = `${base_url}/search`;

      const response = await axios.post(searchUrl, reqBody);
      for (let i = 0; i < response.data.images.length; i++) {
       response.data.images[i].url = response.data.images[i].url.replace("0.0.0.0:8080",host)
      } 

      for (let i = 0; i < response.data.videos.length; i++) {
       response.data.videos[i].url = response.data.videos[i].url.replace("0.0.0.0:8080",host)
      }
      return response.data;
    // const a = await axios.get("https://hub.dummyapis.com/delay?seconds=1");
    // return staticSearchResponse;
  } catch (error) {
    console.error(error);
    
  }
};

export const getVersions =async () => {
  try {
    const versionUrl = `${base_url}/versions`;
    const response = await axios.get(versionUrl);
    return response.data;
    // return {versions: ["v1","v2"], current:"v2"};
  } catch (error) {
    console.error(error);
    
  }
}

export const getFilmsList =async () => {
  try {
    const moviesUrl = `${base_url}/movies`;
    const response = await axios.get(moviesUrl);
    // for (let i = 0; i < response.data.length; i++) {
    //   response.data[i].url = response.data[i].url.replace("0.0.0.0:8080",host)
    // }
    return response.data;
    // return [{url: "", title: "Kabhi Khushi Kabhi Gham", year: "2001"}, {url: "", title: "Don 2", year: "2007"}];
  } catch (error) {
    console.error(error);
    
  }
}

export const getFilmsListBasedOnVersion = async (params: {version: string}) => {
  try {
    const moviesListUrl = `${base_url}/movies`;
    const response = await axios.get(moviesListUrl, { params: params });
    return response.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

export const search = async (reqBody: any) => {
  // reqBody.categories = ["Action"]
  console.log('reqB: ', reqBody);
  
  try {
    const searchUrl = `${base_url}/search`;
    const response = await axios.post(searchUrl, reqBody);
    if(response.data.image.images){
      for (let i = 0; i < response.data.image.images.length; i++) {
      response.data.image.images[i].url = response.data.image.images[i].url.replace("0.0.0.0:8080",host)
      response.data.image.images[i].thumbnail_url = response.data.image.images[i].thumbnail_url.replace("0.0.0.0:8080",host)
      } 
    }

    if(response.data.video.videos){
      for (let i = 0; i < response.data.video.videos.length; i++) {
      response.data.video.videos[i].url = response.data.video.videos[i].url.replace("0.0.0.0:8080",host)
      response.data.video.videos[i].thumbnail_url = response.data.video.videos[i].thumbnail_url.replace("0.0.0.0:8080",host)
      }
    }
    
    return response.data;
    // const a = await axios.get("https://hub.dummyapis.com/delay?seconds=1");
    // return staticSearchResponse1;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);    
  }
};

export const get_media_count = async (reqBody: any) => {
  console.log('reqB: ', reqBody);
  
  try {
    const countsUrl = `${base_url}/media/counts`;
    const count_body = {version: reqBody.version, is_dialogue: reqBody.is_dialogue}
    const response = await axios.post(countsUrl, count_body);
    return response.data;
    // const a = await axios.get("https://hub.dummyapis.com/delay?seconds=1");
    // return staticSearchResponse1;
  } catch (error) {
    console.error(error);
    
  }
};
export const getStage =async (stage_name:string) => {
  try {
    // const stageUrl = `${base_url}/stage/${stage_name}`;
    // const response = await axios.get(stageUrl);
    // return response.data;

    const a = await axios.get("https://hub.dummyapis.com/delay?seconds=1");
    return {
      "name": "SNIP",
      "number": 14,
      "methodology": "magic again"
  };
  } catch (error) {
    console.error(error);
    
  }
}

export const getDebugById =async (id:string) => {
  try {
    // const debugUrl = `${base_url}/stage/${id}`;
    // const response = await axios.get(debugUrl);
    // return response.data;

    const debugUrl = `${base_url}/oracle/${id}`;
    const response = await axios.get(debugUrl);

    if(response.data.uri){
        response.data.uri = response.data.uri.replace("0.0.0.0:8080",host)
    }

    if(response.data.thumbnail_uri){
      response.data.thumbnail_uri = response.data.thumbnail_uri.replace("0.0.0.0:8080",host)
    }

    return response.data

  //   const a = await axios.get("https://hub.dummyapis.com/delay?seconds=1");
  //   return {
  //     "movie_name": "don",
  //     "type": "SNIP",
  //     "id": "de18afc5-696f-4d9c-aa8f-40727e2f2f3b",
  //     "characters": [],
  //     "content": "In a tense standoff set against a mountainous backdrop, Don, a powerful criminal, is confronted by an unknown speaker. The men, all dressed in suits, are engaged in a high-stakes negotiation involving gold. Despite the escalating tension and the revelation of armed men, Don remains unfazed. The unknown speaker demands the gold, but Don insists on the briefcase being handed over to him. In a show of bravado, Don even questions if the unknown speaker is afraid of death. The standoff continues, with the unknown speaker demanding the briefcase again. In a surprising move, Don hands over the gold worth 10 lakhs, but is cut off before he can finish his sentence.",
  //     "input_oracles": {
  //         "ENRICHED_DIALOGUE": [
  //             "8e7b4bb6-6b93-4384-bed4-cc054f54c2d8",
  //             "d45f9715-7323-4be8-8998-71adcc8174a8",
  //             "f6dd3dfe-b9e5-4b93-8509-2932c6cf5166",
  //             "a9c85329-f335-431f-b8c0-cccabe8462e7",
  //             "c8fa7748-4e65-4ac5-8c97-1950a26c76c3",
  //             "fa0c9949-d42c-465b-8771-5148ce0dc9f2",
  //             "1d99a8f5-fad1-40ea-adc1-f9d5997e3d66",
  //             "7fdbe0de-d24b-4f90-bbba-02fc4da5769c",
  //             "ff7f6547-3d90-4b91-aa90-68a355523aa4",
  //             "2b28fc39-c2a0-4f1b-8f00-f6368a239947",
  //             "e49490d7-647b-4acd-a034-65414e2b722d"
  //         ]
  //     },
  //     "start_time": 45.52,
  //     "end_time": 93.65,
  //     "embedding": { 
  //         "content": "sample text",
  //         "embedding": "[0.232323233223]",
  //         "input_oracles" : {
  //             "Frame" : [],
  //             "Dialogue": []
  //         }
  //     },
  //     "uri": "don_snip_0045_5200-0093_6500.mp4",
  //     "title": "High-Stakes Standoff: Don's Power Play",
  //     "version": "v3",
  //     // "stage": "extracting_transcript_using_chirp",
  //       "stage": {
  //           "name": "SNIP",
  //           "number": 14,
  //           "methodology": "magic again"
  //       },
  //     "created": "2023-07-30 16:30:00",
  //     "updated": "2023-07-30 16:35:00"
  // };
  } catch (error) {
    console.error(error);
    
  }
}


export const getComments =async (id:string) => {
  try {
    const url = `${base_url}/feedback/oracle/${id}/comment`;
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const addComment =async (id:string, reqBody:any) => {
  try {
    const url = `${base_url}/feedback/oracle/${id}/comment`;
    const response = await axios.post(url, reqBody);
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const updateComment =async (id:string, reqBody:any) => {
  try {
    const url = `${base_url}/feedback/oracle/comment/${id}`;
    const response = await axios.put(url, reqBody);
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const getVotes =async (id:string) => {
  try {
    const url = `${base_url}/feedback/oracle/${id}/vote`;
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const addVote =async (id:string, reqBody:any) => {
  try {
    const url = `${base_url}/feedback/oracle/${id}/vote`;
    const response = await axios.post(url, reqBody);
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}


export const getVoteStatics = async (id:string) => {
  try {
    const url = `${base_url}/feedback/oracle/${id}/vote/statics`;
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const getUserVote = async (id:string) => {
  try {
    const url = `${base_url}/feedback/oracle/${id}/user/vote`;
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const searchUsers = async (body: any) => {
  try {
    const url = `${base_url}/user/search`;
    const response = await axios.post(url, body);
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const getAllUsers = async () => {
  try {
    const url = `${base_url}/user/`;
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const getCategoryItems = async (category: string, top: number | undefined = undefined) => {
  try {
    let url = `${base_url}/category/items?category=${category}`;
    if (top){
      url = url + `&top=${top}`
    }
    const response = await axios.get(url);
    // if(response.data){
    //   for (let i = 0; i < response.data.length; i++) {
    //       response.data[i].url = response.data[i].url?.replace("0.0.0.0:8080",host)
    //       response.data[i].thumbnail_url = response.data[i].thumbnail_url?.replace("0.0.0.0:8080",host)
    //   } 
    // }
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const getCategoryItemsForSearch = async (category_id: string) => {
  try {
    const url = `${base_url}/category/${category_id}/search`;
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    console.log(error);
  }
}

export const getCategoriesForUser = async () => {
  try {
    let url = `${base_url}/category/user`;
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const getPlaylistsForUser = async () => {
  try {
    let url = `${base_url}/playlist/user`;
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

// export const getPlaylistItems = async (playlist: string, top: number | undefined = undefined) => {
//   try {
//     let url = `${base_url}/playlist/itemsnew?playlist=${playlist}`;
//     if (top){
//       url = url + `&top=${top}`
//     }
//     const response = await axios.get(url);
//     if(response.data){
//       for (let i = 0; i < response.data.length; i++) {
//           response.data[i].url = response.data[i].uri?.replace("0.0.0.0:8080",host)
//           response.data[i].thumbnail_url = response.data[i].thumbnail_uri?.replace("0.0.0.0:8080",host)
//       } 
//     }
//     return response.data
//   } catch (error) {
//     console.error(error);
//   }
// }

export const getPlaylistItems = async (playlist_id: string, top: number | undefined = undefined) => {
  try {
    let url = `${base_url}/playlist/${playlist_id}/items`;
    // if (top){
    //   url = url + `&top=${top}`
    // }
    const response = await axios.get(url);
    // if(response.data){
    //   for (let i = 0; i < response.data.length; i++) {
    //       response.data[i].url = response.data[i].uri?.replace("0.0.0.0:8080",host)
    //       response.data[i].thumbnail_url = response.data[i].thumbnail_uri?.replace("0.0.0.0:8080",host)
    //   } 
    // }
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const getPlaylistItemsForSearch = async (playlist_id: string) => {
  try {
    const url = `${base_url}/playlist/${playlist_id}/search`;
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const getWatchlistItems = async () => {
  try {
    let url = `${base_url}/playlist/watchlist/items`;
    
    const response = await axios.get(url);

    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const getProfile = async () => {
  try {
    let url = `${base_url}/user/profile`;
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const addItemInPlaylist = async (body: any) => {
  try {
    const url = `${base_url}/playlist/items`;
    const response = await axios.post(url, body);
    successToast("Item added in playlist");
    return response.data
  } catch (error) {
    console.error(error);
    errorToast("Item already added in playlist");
    // alert("Already Added!")
  }
}

export const addItemInWatchlist = async (body: any) => {
    const url = `${base_url}/playlist/items/watchlist`;
    const response = await axios.post(url, body);
    return response.data
}

export const getExploreMoreDataByVersion = async (movie_name: string, params: {version: string}) => {
  try {
    const exploreMoreDataByVersionUrl = `${base_url}/explore/${movie_name}`;
    const response = await axios.get(exploreMoreDataByVersionUrl, { params: params });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const getAllUsersforAdmin = async () => {
  try {
    const url = `${base_url}/admin/users`;
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const updateUserAdmin = async (userId: string, body: any) => {
  try {
    const url = `${base_url}/admin/users/${userId}`;
    const response = await axios.put(url, body);
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const deleteUserAdmin = async (userId: string) => {
    const url = `${base_url}/admin/users/${userId}`;
    const response = await axios.delete(url);
    return response.data
}

export const addWatchlistItem = async (oracle_id: string) => {
  const requestBody = {
      oracle_id: oracle_id
  }
  try{
    const res = await addItemInWatchlist(requestBody);
    console.log(res.status,'superman')
    successToast("Item added in watchlist!");
    return res;
  }catch(error : any){
    if (error.response.status === 409) {
      errorToast("Item already added in watchlist!");
    } 
    else{
      errorToast("Error Occurred! Please Try Again");
  }
  
}
}

export const isInWatchlist = (itemId: any, watchlistData: { id: string }[]): boolean => {
  const watchlistIds = watchlistData.map((item) => item.id);
  return watchlistIds.includes(itemId);
};

export const createUserPlaylist = async (body: any) => {
  try {
    const url = `${base_url}/playlist/user`;
    const response = await axios.post(url, body);
    return response.data
  } catch (error) {
    // console.error(error);
    return Promise.reject(error);    
  }
}

export const getHomeData = async (movie="") => {
  try {
    let url = `${base_url}/home${( movie && movie !== "")?("?m="+movie):""}`;
    const response = await axios.get(url);
    // if(response.data){
    //   for (let i = 0; i < response.data.length; i++) {
    //       response.data[i].data.url = response.data[i].uri?.replace("0.0.0.0:8080",host)
    //       response.data[i].thumbnail_url = response.data[i].thumbnail_uri?.replace("0.0.0.0:8080",host)
    //   } 
    // }
    return response.data
  } catch (error) {
    console.error(error);
    
  }
}

export const getHomepageCategoriesByVersion = async (params: {version: string}) => {
  try {
    const homepageCategoriesByVersionUrl = `${base_url}/home`;
    const response = await axios.get(homepageCategoriesByVersionUrl, { params: params });
    return response.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

export const getHomepageBannersDataByVersion = async (params: {version: string}) => {
  try {
    const homepageBannersDataByVersionUrl = `${base_url}/banners`;
    const response = await axios.get(homepageBannersDataByVersionUrl, { params: params });
    return response.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

export const getUserHistory = async () => {
  try {
    const url = `${base_url}/user/me/history`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addUserQuery =async (requestBody:any) => {
  try {
    const url = `${base_url}/user/me/history`;
    const response = await axios.post(url,requestBody);
    return response.data
  } catch (error) {
    console.log('api error')
    console.error(error)
  }
}

export const create_playListBulk = async(requestBody:any)=>{
  try {
    const url = `${base_url}/playlist/playlist-with-items`;
    const response = await axios.post(url,requestBody);
    return response.data
  } catch (error) {
    return Promise.reject(error);    
  }
}

//For generating virtual video
export const generateVideo = async (reqBody: IGenerateVideoPayload) => {
  try {
    const generateVideoUrl = `${base_url}/VirtualVideo`;
    const response = await axios.post(generateVideoUrl, reqBody);    
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);    
  }
};

//For getting version list and default version for virtual video
export const getVersionsForVirtualVideo = async () => {
  try {
    const getVersionsForVirtualVideoUrl = `${base_url}/VirtualVideo/versions`;
    const response = await axios.get(getVersionsForVirtualVideoUrl);
    return response.data;
  } catch (error) {
    return Promise.reject(error);    
  }
}

//For getting virtual video
export const getVideo = async (id: string) => {
  try {
    const getVideoUrl = `${base_url}/VirtualVideo/${id}`;
    const response = await axios.get(getVideoUrl);
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);    
  }
};

//For getting assets (virtual videos) list
export const getVideoList = async () => {
  try {
    const getVideoListUrl = `${base_url}/VirtualVideo`;
    const response = await axios.get(getVideoListUrl);
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);    
  }
};

//For updating virtual video's statuses
export const updateVideoStatus = async (assetId: string, reqBody: any) => {
  try {
    const updateVideoStatusUrl = `${base_url}/VirtualVideo/update/${assetId}`;
    const response = await axios.post(updateVideoStatusUrl, reqBody);    
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);    
  }
};

//For deleting virtual video
export const deleteVirtualVideo = async (assetId: string) => {
  try {
    const deleteVirtualVideoUrl = `${base_url}/VirtualVideo/${assetId}`;
    const response = await axios.delete(deleteVirtualVideoUrl);    
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);    
  }
};

//For getting virtual video shared users list and shared scope
export const getVideoSharedUsers = async (resource_id: string) => {
  try {
    const getVideoSharedUsersUrl = `${base_url}/VirtualVideo/share/${resource_id}`;
    const response = await axios.get(getVideoSharedUsersUrl);
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);    
  }
};

//For updating virtual video's shared users list and shared scope
export const updateVideoSharedUsers = async (assetId: string, reqBody: any) => {
  try {
    const updateVideoSharedUsersUrl = `${base_url}/VirtualVideo/share/${assetId}`;
    const response = await axios.post(updateVideoSharedUsersUrl, reqBody);    
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);    
  }
};

