import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import { Box, Button, CssBaseline, Skeleton, Tab, Tabs, TextField, Typography } from "@mui/material";
import iconAssets from '../../assets/assets_icon.svg'
import AssetCard from "../../components/AssetCard";
import { deleteVirtualVideo, getAllUsers, getVideoList } from "../../services/searchService";
import ShareDialog from "../../components/ShareDialog";
import DeleteDialog from "../../components/DeleteDialog";
import { toast } from "react-toastify";
import { errorToast, successToast } from "../../common/utils";

function otherProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >

            <Box sx={{ padding: "0px" }}>
                {children}
            </Box>

        </div>
    );
}

export default function Assets() {
    const [isLoadedData, setIsLoadedData] = useState<boolean>(false);
    const [isloading, setIsloading] = useState<boolean>(false);
    const [videoAssets, setVideoAssets] = useState<{generated: any[]; shared: any[]}>({generated: [], shared: []});
    const [userlist, setUserlist] = useState<any[]>([]);

    const [activeResource, setActiveResource] = useState<any>(null);
    const [openShareDialog, setOpenShareDialog] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

    const [tabvalue, setTabvalue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabvalue(newValue);
    };

    //For getting registered users list
    const handleGetUsers = useCallback(() => {
        // setIsloading(true);
        getAllUsers()
            .then((response) => {
                setUserlist([...response]);
                setIsloading(false);
            })
            .catch((err) => {
                // setIsloading(false);
            })
    }, []);

    //For getting video list
    const handleGetVideoList = useCallback(() => {
        setIsloading(true);
        getVideoList()
            .then((response) => {
                setVideoAssets({...response});
                // setIsloading(false);
                handleGetUsers();
            })
            .catch((err) => {
                // setIsloading(false);
            })
    }, [handleGetUsers]);

    useEffect(() => {
        handleGetVideoList();
    }, []);

    const handleClickShare = (resource:any) => {
        setActiveResource(resource);
        setOpenShareDialog(true);
    }

    const handleClickDelete = (resource:any) => {
        setActiveResource(resource);
        setOpenDeleteDialog(true);
    }

    const handleDeleteVideo = useCallback(() => {
        const resource_to_delete = {...activeResource};
        deleteVirtualVideo(activeResource?.id ?? '')
            .then((response) => {
                successToast('Video deleted successfully!');
                setActiveResource(null);
                setOpenDeleteDialog(false);
                handleUpdateVideoListLocallyAfterDelete(resource_to_delete);
            })
            .catch((err) => {
                errorToast('Failed to delete video');
            })
    }, [activeResource]);

    //For updating video list locally after rename
    const handleUpdateVideoListLocallyAfterRename = (newObj: any) => {
        setVideoAssets((prev) => ({...prev, generated: prev.generated.map((shItem) => {
            if (shItem.id === newObj.id) {
                return newObj
            } else {
                return shItem
            }
        })}));
    }

    //For updating video list locally after delete
    const handleUpdateVideoListLocallyAfterDelete = (newObj: any) => {
        setVideoAssets((prev) => ({...prev, generated: prev.generated.filter((shItem) => (shItem.id !== newObj.id))}));
    }

    return (
        <Box>
        <CssBaseline />
        <Box className="sub-container-assets">
            <Box className="assets-tab">
                <Box className="container">
                    <Box className="icon-container">
                        <img src={iconAssets} alt="" />
                        <Typography className="subheader-text">Assets</Typography>
                    </Box>
                </Box>
            </Box>
            <Box className="assets-tab-2">
                <Box className="container"  sx={{ 
                            display: 'flex', 
                            alignItems:{lg:"center", md:"center", sm:"flex-start", xs:"flex-start"},
                            justifyContent: 'space-between', 
                            flexDirection:{lg:"row", md:"row", sm:"column", xs:"column"},
                            rowGap:{lg:"0px", md:"0", sm:"15px", xs:"15px"}
                            }}>
                    <Box sx={{
                                display: 'flex', 
                                alignItems:{lg:"center", md:"center", sm:"center", xs:"flex-start"},
                                justifyContent:{lg:"flex-start", md:"flex-start", sm:"space-between", xs:"space-between"},
                                width:{lg:"auto", md:"auto", sm:"100%", xs:"100%"},
                                flexDirection:{lg:"row", md:"row", sm:"row", xs:"column"},
                                rowGap:{lg:"0px", md:"0", sm:"0", xs:"15px"}
                        }}>
                            <Tabs
                                value={tabvalue}
                                onChange={handleTabChange}
                                aria-label="search-result-tabs"
                                className=""
                            >
                                <Tab label={<span>Your Assets</span>} {...otherProps(0)} />
                                <Tab label={<span>Shared with you</span>} {...otherProps(1)} />
                            </Tabs>
                        </Box>
                    </Box>
            </Box>
            <CustomTabPanel value={tabvalue} index={0}>
                {!isloading ? (
                    videoAssets.generated.length > 0 ? (
                        <Box className="container" sx={{ marginBottom: 8, padding: '0px' }}>
                            <Box className="assets-body">
                                {videoAssets.generated.map((assetItem) => {
                                    return <AssetCard 
                                                key={assetItem.id} 
                                                assetData={assetItem} 
                                                userlist={userlist} 
                                                handleClickShare={handleClickShare} 
                                                handleGetVideoList={handleGetVideoList}
                                                handleClickDelete={handleClickDelete}
                                                handleUpdateVideoListLocallyAfterRename={handleUpdateVideoListLocallyAfterRename}
                                            />
                                })}
                            </Box>
                        </Box>
                    ) : (
                        <Box className="no-data-message" >
                            <Typography variant="h6">There are currently no assets in your created assets. </Typography>
                        </Box>)
                ) : (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', padding: '0px', gap: '30px', marginTop: 3 }} className="container">
                        {[1,2,3,4].map((skItem, ind) => {
                            return (
                                <Skeleton key={ind} variant="rounded" width={645} height={204} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            );
                        })}
                    </Box>
                )}
            </CustomTabPanel>
            <CustomTabPanel value={tabvalue} index={1}>
                {!isloading ? (
                    videoAssets.shared.length > 0 ? (
                        <Box className="container" sx={{ marginBottom: 8, padding: '0px' }}>
                            <Box className="assets-body">
                                {videoAssets.shared.map((assetItem) => {
                                    return <AssetCard 
                                                key={assetItem.id} 
                                                assetData={assetItem} 
                                                userlist={userlist} 
                                                handleClickShare={handleClickShare} 
                                                handleGetVideoList={handleGetVideoList}
                                                handleClickDelete={handleClickDelete}
                                                handleUpdateVideoListLocallyAfterRename={handleUpdateVideoListLocallyAfterRename}
                                            />
                                })}
                            </Box>
                        </Box>
                    ) : (
                        <Box className="no-data-message" >
                            <Typography variant="h6">There are currently no assets in shared assets. </Typography>
                        </Box>)
                ) : (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', padding: '0px', gap: '30px', marginTop: 3 }} className="container">
                        {[1,2,3,4].map((skItem, ind) => {
                            return (
                                <Skeleton key={ind} variant="rounded" width={645} height={204} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            );
                        })}
                    </Box>
                )}
            </CustomTabPanel>

        </Box>
        {openShareDialog && <ShareDialog 
            openShareDialog={openShareDialog}
            handleCloseShareDialog={(val:boolean) => {setOpenShareDialog(val); setActiveResource(null);}}
            userlist={userlist}
            activeResource={activeResource}
        />}
        {openDeleteDialog && <DeleteDialog 
            openDeleteDialog={openDeleteDialog}
            handleCloseDeleteDialog={(val:boolean) => {setOpenDeleteDialog(val); setActiveResource(null);}}
            handleSubmitDelete={handleDeleteVideo}
            primaryText="Are you sure?"
            secondaryText="Do you really want to delete this video? This process cannot be undone."
        />}        
        </Box>
    );
}
