import React, { useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom';

import CssBaseline from "@mui/material/CssBaseline";
import {
  Box,
  FormControl,
  InputAdornment,
  TextField,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Typography,
  Switch,
  Button,
  FormControlLabel,
} from "@mui/material";
import {
  SearchOutlined
} from "@mui/icons-material";
import { toast } from "react-toastify";

import { whiteColor } from "../../../common/constants";
import { ISearchPayload } from "../../../types/interface";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import "./index.css";
import { deleteUserAdmin, getAllUsersforAdmin, get_media_count, search, updateUserAdmin } from "../../../services/searchService";
import { errorToast, successToast } from "../../../common/utils";

const AdminUserList = () => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [isloading, setIsloading] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const [userUpdate, setUserUpdate] = useState<number>();

  const [searchpayload, setSearchpayload] = useState<ISearchPayload>({
    text: "",
    search_for: "all",
    movies: [],
    moviesByVersion: [],
    version: "",
    unique: true,
    similarity_min: 0,
    similarity_max: 1,
    confidence: 30,
    is_dialog: false,
    apply_video_HS: false,
    topK_from_video_HS_set: "",
    topK_videos: "20",
    categories: [],
  });
  const [searchResult, setSearchResult] = useState<any>({});
  const [mediaCountResult, setMediaCountResult] = useState<any>({});

  const navigate = useNavigate();


  const handleDrawerOpenClose = () => {
    setSidebarOpen((prev) => !prev);
  };

  const handleChangeInputs = (event:any) => {
    const name = event.target.name;
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    if (name !== "text" && !searchpayload.text.length) {
        // toast.error("Search text must not be blank!");
        return;
    }
    setSearchpayload({...searchpayload, [name]: value});
};

const handleSubmitSearch = (event: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const reqBody = {
        text: searchpayload.text, 
        search_for: searchpayload.search_for,
        movies: searchpayload.movies.filter((filmItem:any) => filmItem.selected).map((flItem) => flItem.name),
        version: searchpayload.version,
        unique: searchpayload.unique,
        similarity_min: searchpayload.similarity_min,
        similarity_max: searchpayload.similarity_max,
        // confidence: confidenceSliderMarks.find((csmItem:any) => csmItem.value === selectedfilters.confidence)?.label
        is_dialogue: searchpayload.is_dialog,
        apply_video_HS: searchpayload.apply_video_HS,
        topK_from_video_HS_set: searchpayload.topK_from_video_HS_set.length ? Number(searchpayload.topK_from_video_HS_set) : -1,
        topK_videos: searchpayload.topK_videos.length ? Number(searchpayload.topK_videos) : 20
    };
    setIsloading(true);
    search(reqBody)
      .then((response) => {
        setSearchResult({});
        setSearchResult(response);
        setIsloading(false);

        const mediaCountsReqBody = {
            version: reqBody.version, 
            is_dialogue: reqBody.is_dialogue
            }
        handleMediaCount(mediaCountsReqBody, response);
      })
      .catch((error) => {
        setIsloading(false);
        errorToast(`Cannot search`);
      });
  };

  const handleMediaCount = (reqBody: any, searchResp:any) =>{
    get_media_count(reqBody)
      .then((response) => {

        setMediaCountResult(response);
        navigate('/search', {state: {searchQuery: searchpayload.text, searchResponse: searchResp, mediaCount: response}});

      })
      .catch((error) => {
      });
  }
  const getUsers = async () => {
    const result = await getAllUsersforAdmin(); 
    setUsers(result)
    return result
  }

  const updateUserStatus = async (user: any) => {
    const reqBody = {
      is_active: user.is_active
    } 
    const result = await updateUserAdmin(user.id, reqBody); 
    //user.is_active = result.is_active
    return result
  }

  const deleteUser = async (user: any) => {
    await deleteUserAdmin(user.id).then((res)=>{
      let filteredUsers = users.filter(u => u.id !== user.id)
      setUsers(filteredUsers)
      successToast(`User ${user.name} deleted successfully!`)
    }).catch((err)=>{
      errorToast(`User ${user.name} deletion failed with following error: ${err?.message} ${err.response.data.detail}`)
    })
    
  }

  useEffect(() => {
          
    getUsers()
    
    
  },[]);

  useEffect(() => {
    if((userUpdate !== undefined) && (userUpdate !== -1)){
    let user = users[userUpdate]
    updateUserStatus(user).then((res)=>{
      successToast(`User ${user.name} updated successfully!`)
    }).catch((err)=>{
      errorToast(`User ${user.name} updation failed with following error: ${err?.message} ${err.response.data.detail}`)
    })
    }
    
    
  },[(users??[])[userUpdate??-1]?.is_active]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <CssBaseline />
      {isloading && (
            <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                width: "100%",
                height: "100vh",
                zIndex: "9999",
                background: "rgba(0,0,0,0.75)",
            }}
            >
            <CircularProgress sx={{color: whiteColor}} />
            </Box>
        )}
      <Box
        maxWidth={"xl"}
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
          width: "100%",
        }}
      >
        <Header
          sidebarOpen={sidebarOpen}
          handleDrawerOpenClose={handleDrawerOpenClose}
          handleUpdateSearchPayload={(field:any) => {}}
        >
        </Header>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
        }}
        maxWidth={"xl"}
      >
        <Box className="admin-user-list-page">
          <Box sx={{ paddingLeft: 10, paddingRight: 10 }}>
            <Box sx={{ paddingTop: 0, paddingBottom: 10, textAlign: "center" }}>
              <h3 style={{marginTop: '80px'}}>User List</h3>

              <List sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: '20px' }}>
                
                {
                users?.map((user, index) => {
                  return (
                    <Box key={index}>
                    <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={user.picture} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={user.name}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {user.email}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    <FormControlLabel
                      value="is active"
                      control={
                        <Switch
                        edge="end"
                        title="active"
                        sx={{marginRight: '50px'}}
                        checked={user.is_active}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                           users[index].is_active = event.target.checked
                           setUserUpdate(index)
                          setUsers([...users])
                      }}
                        inputProps={{
                          'aria-labelledby': 'switch-list-label-wifi',
                        }}
                      />
                      }
                      label="is active"
                      labelPlacement="start"
                    />
                    
                    <Button variant="outlined"
                    onClick={()=> deleteUser(user)}
                    
                    >Delete</Button>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  </Box>
                  )
                  
                })
              }
              </List>
              
              
            </Box>
            
          </Box>
        </Box>
      </Box>
      <Footer></Footer>
    </Box>
  );
};

export default AdminUserList;
