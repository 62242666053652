import React, { useState } from "react";
import {useNavigate} from 'react-router-dom';

import CssBaseline from "@mui/material/CssBaseline";
import {
  Grid,
  Button,
  Box,
  FormControl,
  InputAdornment,
  TextField,
  CircularProgress,
} from "@mui/material";
import {
  Favorite,
  PlayArrow,
  QueueMusic,
  SearchOutlined,
  Widgets,
} from "@mui/icons-material";
import { toast } from "react-toastify";

import { whiteColor } from "../../common/constants";
import { ISearchPayload } from "../../types/interface";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "./index.css";
import aboutBanner from "../../assets/about-banner.png";
import smallBanner from "../../assets/banner-img-small.png";
import largeBanner from "../../assets/banner-img-large.png";
import { get_media_count, search } from "../../services/searchService";
import { errorToast } from "../../common/utils";

const About = () => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [isloading, setIsloading] = useState(false);

  const [searchpayload, setSearchpayload] = useState<ISearchPayload>({
    text: "",
    search_for: "all",
    movies: [],
    moviesByVersion: [],
    version: "",
    unique: true,
    similarity_min: 0,
    similarity_max: 1,
    confidence: 30,
    is_dialog: false,
    apply_video_HS: false,
    topK_from_video_HS_set: "",
    topK_videos: "20",
    categories: [],
  });
  const [searchResult, setSearchResult] = useState<any>({});
  const [mediaCountResult, setMediaCountResult] = useState<any>({});

  const navigate = useNavigate();


  const handleDrawerOpenClose = () => {
    setSidebarOpen((prev) => !prev);
  };

  const handleChangeInputs = (event:any) => {
    const name = event.target.name;
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    if (name !== "text" && !searchpayload.text.length) {
        // toast.error("Search text must not be blank!");
        return;
    }
    setSearchpayload({...searchpayload, [name]: value});
};

const handleSubmitSearch = (event: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const reqBody = {
        text: searchpayload.text, 
        search_for: searchpayload.search_for,
        movies: searchpayload.movies.filter((filmItem:any) => filmItem.selected).map((flItem) => flItem.name),
        version: searchpayload.version,
        unique: searchpayload.unique,
        similarity_min: searchpayload.similarity_min,
        similarity_max: searchpayload.similarity_max,
        // confidence: confidenceSliderMarks.find((csmItem:any) => csmItem.value === selectedfilters.confidence)?.label
        is_dialogue: searchpayload.is_dialog,
        apply_video_HS: searchpayload.apply_video_HS,
        topK_from_video_HS_set: searchpayload.topK_from_video_HS_set.length ? Number(searchpayload.topK_from_video_HS_set) : -1,
        topK_videos: searchpayload.topK_videos.length ? Number(searchpayload.topK_videos) : 20
    };
    setIsloading(true);
    search(reqBody)
      .then((response) => {
        setSearchResult({});
        setSearchResult(response);
        setIsloading(false);

        const mediaCountsReqBody = {
            version: reqBody.version, 
            is_dialogue: reqBody.is_dialogue
            }
        handleMediaCount(mediaCountsReqBody, response);
      })
      .catch((error) => {
        setIsloading(false);
        errorToast(`Cannot search`);
      });
  };

  const handleMediaCount = (reqBody: any, searchResp:any) =>{
    get_media_count(reqBody)
      .then((response) => {

        setMediaCountResult(response);
        navigate('/search', {state: {searchQuery: searchpayload.text, searchResponse: searchResp, mediaCount: response}});

      })
      .catch((error) => {
      });
}

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}  className="about-page">
      <CssBaseline />
      {isloading && (
            <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                width: "100%",
                height: "100vh",
                zIndex: "9999",
                background: "rgba(0,0,0,0.75)",
            }}
            >
            <CircularProgress sx={{color: whiteColor}} />
            </Box>
        )}
        <Box className="about-banner">
            <img src={aboutBanner} alt="" />
            <div className="about-heading">About Us</div>
          </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
        }}
        maxWidth={"xl"}
      >
        <Box>
          <Box sx={{ paddingLeft: 10, paddingRight: 10 }}>
            <Box sx={{ paddingTop: 0, paddingBottom: 10, textAlign: "center" }}>
              <h3>Lorem Ipsum</h3>
              <p>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla par
              </p>
            </Box>
            <Grid
              container
              rowSpacing={5}
              columnSpacing={{ sm: 0, md: 5, lg: 7, xl: 10 }}
              marginBottom={10}
            >
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <img src={smallBanner} alt="" className="img-responsive" />
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                <h3>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                </h3>
                <p>
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla par
                </p>
                <p>
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla par
                </p>
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={5}
              columnSpacing={{ sm: 0, md: 5, lg: 7, xl: 10 }}
              sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
              marginBottom={10}
            >
              <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                <h3>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                </h3>
                <p>
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla par
                </p>
                <p>
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla par
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <img src={smallBanner} alt="" className="img-responsive" />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={10}>
                <img src={largeBanner} alt="" className="img-responsive" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{alignContent:"center", justifyContent:"center", textAlign:"center"}}>
                <h3>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                </h3>
                <p>
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla par
                </p>
                 
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
