import React, { useEffect, useState } from "react";
import { Box, Button, Drawer, FormControl, Grid, InputAdornment, InputLabel, ListItem, MenuItem, Select, SelectChangeEvent, Slider, Switch, TextField, Toolbar, Typography } from "@mui/material";
import { Close, ExpandMore, SearchOutlined, SortOutlined } from "@mui/icons-material";
import VideoSelectCard from "../VideoSelectCard";
import { blackColor, confidenceSliderMarks, whiteColor } from "../../common/constants";
import FsLightbox from "fslightbox-react";
import { createPortal } from "react-dom";
import { ISearchPayload } from "../../types/interface";

interface ISidebarProps {
    handleDrawerOpenClose: () => void;
    versionlist: string[]; 
    showversion:boolean; 
    version:string; 
    handleChangeVersion: (newValue:string) => void;
    unique: boolean;
    handleChangeUnique: (newValue:boolean) => void;
    minSimilarity: number;
    handleChangeMinSimilarity: (newValue:string) => void;
    maxSimilarity: number;
    handleChangeMaxSimilarity: (newValue:string) => void;
    confidenceValue: number;
    handleChangeConfidenceValue: (newValue:number) => void;
    handleChangeInputs: (event:any) => void;
    handleApplyFilter: (apply:boolean) => void;
    handleChangeFilmslist: (newValue:any) => void;
    searchpayload: ISearchPayload;

    videoHS: boolean;
    handleChangeVideoHS: (newValue:boolean) => void;
    topKHS: string;
    handleChangeTopKHS: (newValue:string) => void;
    topKresult: string;
    handleChangeTopKresult: (newValue:string) => void;
    categories: any[];
    handleChangeCategories: (newValue:any) => void;
}

interface IFiltersExpanded {
    movie: string | boolean;
    version: string | boolean;
}

export default function Sidebar(props: ISidebarProps) {
    const { 
        handleDrawerOpenClose,
        versionlist, 
        showversion, 
        version, 
        handleChangeVersion,
        unique,
        handleChangeUnique,
        minSimilarity,
        handleChangeMinSimilarity,
        maxSimilarity,
        handleChangeMaxSimilarity,
        confidenceValue,
        handleChangeConfidenceValue,
        handleChangeInputs,
        handleApplyFilter,
        handleChangeFilmslist,
        searchpayload,

        videoHS,
        handleChangeVideoHS,
        topKHS,
        handleChangeTopKHS,
        topKresult,
        handleChangeTopKresult,
        categories,
        handleChangeCategories,
    } = props;
    const [filmsearch, setFilmsearch] = useState<string>('');
    const [accordionExpanded, setAccordionExpanded] = useState(true);
    const [filtersExpanded, setFiltersExpanded] = useState<IFiltersExpanded>({
        movie: true,
        version: true,
    });

    //For Lightbox
    const [toggler, setToggler] = useState(false);
    const [productIndex, setProductIndex] = useState(1);

    const handleChangeFiltersExpanded = () => {}

    return (
        <>
        <Box className="filter-inner-bg">
        <Typography className="filter-header" component="div" sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '60px', paddingLeft: '47px', paddingRight: '44px', marginLeft: '-47px', marginRight: '-44px', borderBottom: `1px solid ${whiteColor}`}}>
            <Close className="icon-close" onClick={() => handleDrawerOpenClose()} />
            <Typography className="filter-title" sx={{color: "rgba(255, 255, 255, 0.7)"}}>
                {/* <SortOutlined fontSize="small" sx={{transform: 'rotateY(180deg)', verticalAlign: 'middle'}} />  */}
                Filters
            </Typography>
            {/* <Button 
                variant="text"
                onClick={() => handleApplyFilter(false)}
            >
                Reset All
            </Button> */}
        </Typography>
        {/* <Accordion expanded={accordionExpanded} onChange={() => setAccordionExpanded(val => !val)}>
            <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{background: `${blackColor} linear-gradient(90deg, rgba(135, 201, 255, 0.15), rgba(152, 152, 152, 0.15))`}}
            >
            <Typography sx={{color: whiteColor, fontSize: '18px'}}>Films List</Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{background: `${blackColor} linear-gradient(90deg, rgba(135, 201, 255, 0.15), rgba(152, 152, 152, 0.15))`}}
            >
            </AccordionDetails>
        </Accordion> */}

        {showversion && <Box>
            <Box className="title-films-list">
                Version
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center', paddingTop: '14px', marginBottom: '28px'}} >
                {showversion && 
                <FormControl size="small" sx={{width: '250px'}}>
                <Select
                  id="version-simple-select"
                  name="version"
                  value={version}
                  inputProps={{
                    MenuProps: {
                        MenuListProps: {
                            sx: {
                                    backgroundColor: '#1e1f20',
                                    color:'#ffffff'
                                }
                            }
                        }
                    }}
                onChange={(event: SelectChangeEvent) => handleChangeVersion(event.target.value as string)}
                // onChange={handleChangeInputs}
                sx={[{borderRadius: '100px', color: whiteColor, border: '1px solid #303030'}, (theme) => ({'.MuiOutlinedInput-notchedOutline': {borderColor: '#303030!important'}, '.MuiSelect-icon': {color: whiteColor}})]}
                >
                    <MenuItem value="" disabled>Select</MenuItem>
                  {versionlist?.map((vItem:string) => <MenuItem key={vItem} value={vItem}>{vItem}</MenuItem>)}
                </Select>
              </FormControl>}                        
            </Box>
        </Box>}

        <Box>
            <Box className="title-films-list">
                Films List
            </Box>
            <Box sx={{paddingTop: '14px', marginBottom: '28px'}}>
                <TextField
                    id="search-input-adornment"
                    className="search-box"
                    sx={{ m: 0, }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end" sx={{color: whiteColor}}>
                                    <FormControl sx={{minWidth: '32px', height: '32px', background: '#707070', borderRadius: '50%', marginRight: '-10px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: whiteColor}}>
                                        <SearchOutlined />
                                    </FormControl>
                        </InputAdornment>,
                        style: {borderRadius: '100px', color: whiteColor},
                    }}
                    size="small"
                    variant="outlined"
                    value={filmsearch}
                    placeholder="Search here..."
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setFilmsearch(event.target.value)
                    }
                />

                <Grid container sx={{ mt: 1.5, maxHeight: '256px', overflowY: 'auto' }}>
                    {searchpayload.moviesByVersion.length && searchpayload.moviesByVersion.filter((flItem) => flItem.display_name?.toLowerCase().includes(filmsearch.toLowerCase())).map((card, index) => (
                    <Grid
                        item
                        key={card.url}
                        xs={12}
                        sm={12}
                        md={12}
                        className="movie-list-group"
                    >
                        <VideoSelectCard card={card} component={"video"} setToggler={() => setToggler((val) => !val)} setProductIndex={() => setProductIndex(index + 1)} handleChangeFilmslist={() => handleChangeFilmslist(card)} />
                    </Grid>
                    ))}
                </Grid>

            </Box>
        </Box>


        <Box>
            <Box className="title-films-list">
            {/* <Box sx={{color: whiteColor, fontSize: '18px', py: '11px', borderBottom: `1px solid ${whiteColor}`}}> */}
                Categories
            </Box>
            <Box sx={{paddingTop: '14px', marginBottom: '28px', display: 'flex'}} className="flim-list-badges">
                {
                    searchpayload.categories?.map((item, index) => (
                        <ListItem 
                            key={item.id}
                            sx={{mr: 1, mb: 1, padding: '0px', width: 'auto'}}
                        >
                            <Button
                                type="button"
                                sx={item.selected ? { borderRadius: "50px", padding: '2px 10px', color: `${blackColor} !important`, background: `rgba(255, 255, 255, 0.7) !important` } : { borderRadius: "50px", padding: '2px 10px', color: "rgba(255, 255, 255, 0.7)", border: `1px solid rgba(255, 255, 255, 0.7)`, background: 'transparent', ':hover': {color: 'black'} } }
                                variant="contained"
                                color="inherit"
                                size="small"
                                onClick={()=> handleChangeCategories(index)}
                            >
                                {item.name}
                            </Button>
                        </ListItem>
                    )
                    )
                }
            </Box>
        </Box>

        <Box>
            <Box className="title-films-list">
                Confidence
            </Box>
            <Box sx={{paddingTop: '14px', marginBottom: '28px', width: '100%', display:'flex', justifyContent: 'center', textAlign: 'center'}}>
                <Slider
                    className="filter-condidence-level"
                    aria-label="Confidence"
                    sx={[{width: '100%', color: whiteColor}, (theme) => ({'.MuiSlider-track': {border: 'none', backgroundColor: 'unset'}, '.MuiSlider-markLabel': {color: '#8A8A8A'}, '.MuiSlider-thumb': {width: '8px', borderRadius: '20px'}})]}
                    value={confidenceValue}
                    onChange={(event: Event, newValue: number | number[]) => {
                        handleChangeConfidenceValue(newValue as number);
                        }}
                    getAriaValueText={(value:number) => `${value}`}
                    valueLabelDisplay="off"
                    step={10}
                    marks={confidenceSliderMarks}
                    min={10}
                    max={30}
                />
            </Box>
        </Box>

        {/* {showversion && 
        <Accordion expanded={accordionExpanded} onChange={() => setAccordionExpanded(val => !val)} className="version-box">
            <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{background: `${blackColor} linear-gradient(90deg, rgba(135, 201, 255, 0.15), rgba(152, 152, 152, 0.15))`, padding:0,}}
            >
                <Typography className="version-title">Version</Typography>
            </AccordionSummary>
            <AccordionDetails   
                sx={{background: `${blackColor} linear-gradient(90deg, rgba(135, 201, 255, 0.15), rgba(152, 152, 152, 0.15))`}}
            >
                <Box sx={{display: 'flex', justifyContent: 'center'}} >
                {showversion && 
                <FormControl size="small" sx={{width: '250px'}}>
                <Select
                  id="version-simple-select"
                  name="version"
                  value={version}
                //   onChange={(event: SelectChangeEvent) => handleChangeVersion(event.target.value as string)}
                onChange={handleChangeInputs}
                sx={[{borderRadius: '100px', color: whiteColor, border: '1px solid #474747'}, (theme) => ({'.MuiOutlinedInput-notchedOutline': {borderColor: '#303030!important'}, '.MuiSelect-icon': {color: whiteColor}})]}
                >
                    <MenuItem value="" disabled>Select</MenuItem>
                  {versionlist?.map((vItem:string) => <MenuItem key={vItem} value={vItem}>{vItem}</MenuItem>)}
                </Select>
              </FormControl>}                        
                </Box>
            </AccordionDetails>
        </Accordion>} */}

        {/* <Accordion expanded={accordionExpanded} onChange={() => setAccordionExpanded(val => !val)}>
            <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            sx={{background: '#E9F5FF'}}
            >
            <Typography>Unique</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}} >
                    <Typography>
                        {unique ? 'True' : 'False'}
                    </Typography>
                    <Switch
                        checked={unique}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeUnique(event.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        size="small"
                    />                
                </Box>
            </AccordionDetails>
        </Accordion>                
        <Accordion expanded={accordionExpanded} onChange={() => setAccordionExpanded(val => !val)}>
            <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel4a-content"
            id="panel4a-header"
            sx={{background: '#E9F5FF'}}
            >
            <Typography>Similarity</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}} >
                    <TextField
                        id="min-similarity"
                        sx={{ m: 1, width: '107px', background: '#FFF', borderRadius: '100px', border: 'none' }}
                        size="small"
                        variant="outlined"
                        placeholder="Min"
                        value={minSimilarity}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeMinSimilarity(event.target.value)
                        }
                        InputProps={{
                        style: {borderRadius: '100px'}
                        }}
                    />
                    <Typography>-
                    </Typography>
                    <TextField
                        id="max-similarity"
                        sx={{ m: 1, width: '107px', background: '#FFF', borderRadius: '100px', border: 'none' }}
                        size="small"
                        variant="outlined"
                        placeholder="Max"
                        value={maxSimilarity}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeMaxSimilarity(event.target.value)
                        }
                        InputProps={{
                        style: {borderRadius: '100px'}
                        }}
                    />
                </Box>
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={accordionExpanded} onChange={() => setAccordionExpanded(val => !val)}>
            <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel5a-content"
            id="panel5a-header"
            sx={{background: '#E9F5FF'}}
            >
            <Typography>Confidence</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{width: '100%', display:'flex', justifyContent: 'center', textAlign: 'center'}}>
                    <Slider
                        aria-label="Confidence"
                        sx={{width: '251px'}}
                        value={confidenceValue}
                        onChange={(event: Event, newValue: number | number[]) => {
                            handleChangeConfidenceValue(newValue as number);
                          }}
                        getAriaValueText={(value:number) => `${value}`}
                        valueLabelDisplay="off"
                        step={10}
                        marks={confidenceSliderMarks}
                        min={10}
                        max={30}
                    />
                </Box>
            </AccordionDetails>
        </Accordion> */}

        <Box>
            <Box className="title-films-list">
                Video Hierarchical
                <Switch
                    checked={videoHS}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeVideoHS(event.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={[{float: 'right'}, (theme) => ({'.MuiSwitch-thumb': {background: '#989898'}})]}
                    size="small"
                />                
            </Box>
            
            <Box className="version-box video-hierarchical" sx={{paddingTop: '14px'}} >
                <Box className="count-box" >
                    <Typography className='v-title'>
                        Take Group Count
                    </Typography>
                    <TextField
                        className="v-text-box"
                        id="topkHS"
                        type="text"
                        sx={[{ m: -1, width: '70px', color: whiteColor, borderRadius: '100px', border: 'none' }, (theme) => ({})]}
                        size="small"
                        variant="outlined"
                        placeholder=""
                        value={topKHS}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeTopKHS(event.target.value)
                        }
                        InputProps={{
                        style: {borderRadius: '100px', color: whiteColor}
                        }}
                    />
                </Box>
                <Box className="count-box" >
                    <Typography  className='v-title'>
                        Result Videos
                    </Typography>
                    <TextField
                        className="v-text-box"
                        id="topkresult"
                        type="text"
                        sx={[{ m: -1, width: '70px', color: whiteColor, borderRadius: '100px', border: 'none' }, (theme) => ({})]}
                        size="small"
                        variant="outlined"
                        placeholder=""
                        value={topKresult}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeTopKresult(event.target.value)
                        }
                        InputProps={{
                        style: {borderRadius: '100px', color: whiteColor}
                        }}
                    />
                </Box>

            </Box>
        </Box>


        {createPortal(<FsLightbox
            toggler={toggler}
            sources={searchpayload.moviesByVersion?.map((cItem) => cItem.url)}
            slide={productIndex}
            type={"video"}
        />, document.getElementById('root') || document.body)}
     </Box>
     <Box sx={{display:'flex', justifyContent: 'center', alignItems: 'center', mt: '12px', mx: '-32px', py: '20px', borderTop: `1px solid rgba(255, 255, 255, 0.26)`}} className='filter-button'>
     <Button 
         variant="outlined" 
         sx={{fontWeight:'300', color: whiteColor, borderColor: whiteColor, ':hover': {borderColor: whiteColor}, minWidth: '96px', textTransform: 'capitalize'}}
         onClick={() => handleApplyFilter(false)}
     >
         Reset
     </Button>
     <Button 
         variant="contained" 
         sx={{fontWeight:'300', minWidth: '96px', marginLeft: '30px', textTransform: 'capitalize'}}
         onClick={() => searchpayload.text.length ? handleApplyFilter(true) : handleDrawerOpenClose()}
     >
         {searchpayload.text.length ? 'Apply & Search' : 'Apply'}
     </Button>
 </Box>
 </>
);
}