import React, { useCallback, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import './index.css';
import { Autocomplete, Avatar, Box, Divider, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Snackbar, TextField } from "@mui/material";
import { Check, KeyboardArrowDown, LockOutlined } from "@mui/icons-material";
import { SHARED_SCOPE } from "../../types/interface";
import { getVideoSharedUsers, updateVideoSharedUsers } from "../../services/searchService";
import { toast } from "react-toastify";
import { errorToast, successToast } from "../../common/utils";

interface ShareDialogProps {
    openShareDialog: boolean;
    handleCloseShareDialog: (value: boolean) => void;
    userlist: any[];
    activeResource: any;
}

interface IAccessOption {
    key: string;
    label: string;
    description: string;
}

const accessOptions:IAccessOption[] = [
    {key: SHARED_SCOPE.RESTRICTED, label: 'Restricted', description: 'Only people added can open with this link'},
    {key: SHARED_SCOPE.ANYONE_HAVING_LINK, label: 'Anyone with the link', description: 'Anyone can open with this link'}
];
const ITEM_HEIGHT = 48;

export default function ShareDialog(props: ShareDialogProps) {
    const {
        openShareDialog,
        handleCloseShareDialog,
        userlist,
        activeResource
    } = props;

    const [isloading, setIsloading] = useState<boolean>(false);
    const [sharedUsers, setSharedUsers] = useState<string[]>([]);
    const [selectedAccess, setSelectedAccess] = useState<IAccessOption>(accessOptions[0]);
    
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

    const [accessAnchorEl, setAccessAnchorEl] = React.useState<null | HTMLElement>(null);
    const openAccessAnchorEl = Boolean(accessAnchorEl);

    //For getting video shared users list and shared scope
    const handleGetVideoSharedUsers = useCallback(() => {
        setIsloading(true);
        getVideoSharedUsers(activeResource?.id)
            .then((response) => {                
                setSharedUsers(response.shared_to || []);
                setSelectedAccess(accessOptions.find((aoItem:IAccessOption) => aoItem.key === response.shared_scope) || selectedAccess);
                setIsloading(false);
            })
            .catch((err) => {
                setIsloading(false);
            })
    }, [activeResource]);

    useEffect(() => {
        handleGetVideoSharedUsers();
    }, []);

    //For updating virtual video's shared users list and shared scope
    const handleSubmitVideoSharedUsers = useCallback((event:any) => {
        event.preventDefault();
        const reqBody = {
            shared_to: sharedUsers,    // list containing shared user ids
            resource_type: "VIRTUAL_VIDEO",
            shared_scope: selectedAccess.key      //Can be RESTRICTED or ANYONE_HAVING_LINK
        };
        setIsloading(true);
        updateVideoSharedUsers(activeResource?.id, reqBody)
            .then((response) => {
                setIsloading(false);
                successToast('Access updated successfully!');
                handleClose();
            })
            .catch((err) => {
                setIsloading(false);
                errorToast('Failed to update access');
            })
    }, [sharedUsers, selectedAccess, activeResource]);
    
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAccessAnchorEl(event.currentTarget);
    };
    const handleCloseAccessAnchorEl = () => {
      setAccessAnchorEl(null);
    };

    const handleSelectAccessOption = useCallback((option:IAccessOption) => {
        setSelectedAccess(option);
        handleCloseAccessAnchorEl();
    }, []);

    const handleClose = () => {
        handleCloseShareDialog(false);
    };

    const handleRemoveUser = useCallback((userId: string) => {
        setSharedUsers((prev) => prev.filter((fItem) => fItem !== userId));
    }, []);

    // writeText function to copy or write data to clipboard
    const handleClickCopyLink = useCallback(() => {
        setOpenSnackbar(true);
        navigator.clipboard.writeText(`${window.location.host}/generated-video/${activeResource?.id}`);
    }, []);

    return (
        <>
            <Dialog
                className="shareCard-dialog-box"
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openShareDialog}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Share with people
                </DialogTitle>
                <button onClick={() => handleClose()} className='close-dailog'></button>

                <DialogContent dividers>
                    <Box>
                        <Box className="share-people-container">
                            <Autocomplete 
                                id="share-people"
                                className="share-people"
                                disablePortal
                                filterSelectedOptions
                                size="small"
                                options={userlist?.filter((filterItem) => !sharedUsers.includes(filterItem.id)) || []}
                                value={null}
                                clearOnBlur
                                blurOnSelect
                                onChange={(event, value, reason) => { 
                                    if (reason === "selectOption") {
                                        setSharedUsers((prev) => ([...prev, value.id]));                                        
                                    } else if (reason === "removeOption" || reason === "clear") {
                                        
                                    }
                                }}
                                autoComplete
                                getOptionLabel={(option:any) => option?.email}
                                // renderOption={(props, option) => (<>{option?.name}</>)}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="Add People" />
                                )}
                            />
                        </Box>
                        <Box>
                            <Typography className="sub-heading">People with access</Typography>
                            {sharedUsers.length ?
                                <List className="people-list" dense={true}>
                                {sharedUsers.map((peopleItem:string, index: number) => {
                                    const person = userlist?.find((userItem) => userItem.id === peopleItem);
                                    return (
                                    <ListItem key={index} sx={{padding: '2px 0px'}}
                                        secondaryAction={
                                            <Button variant="text" onClick={() => handleRemoveUser(peopleItem)} >Remove</Button>
                                        }
                                    >
                                        <ListItemAvatar>
                                        <Avatar alt="Remy Sharp" src={person?.picture} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={person?.name}
                                            sx={{fontSize: '14px'}}
                                            secondary={
                                                <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                >
                                                    {person?.email}
                                                </Typography>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                )})}
                            </List>
                            : <Typography sx={{color: '#6E737D'}}>No people with access</Typography>
                            }
                        </Box>
                        <Box className="general-access-container">
                            <Typography className="sub-heading">General access</Typography>
                            <List className="people-list" dense={true}>
                                    <ListItem sx={{padding: '2px 0px'}}
                                        secondaryAction={
                                            <Button variant="contained" className="copy-link-button" onClick={handleClickCopyLink}>Copy Link</Button>
                                        }
                                    >
                                        <ListItemAvatar>
                                        <Avatar sx={{background: '#3A3A3A'}}>
                                            <LockOutlined sx={{fontSize: '14px'}} />
                                        </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <React.Fragment>
                                                    <Box>
                                                        <Button
                                                            variant="text"
                                                            size="small"
                                                            aria-label="more"
                                                            id="long-button"
                                                            aria-controls={openAccessAnchorEl ? 'long-menu' : undefined}
                                                            aria-expanded={openAccessAnchorEl ? 'true' : undefined}
                                                            aria-haspopup="true"
                                                            onClick={handleClick}                                
                                                            className="access-dropdown-button"
                                                            endIcon={<KeyboardArrowDown />}
                                                        >
                                                            {selectedAccess.label}
                                                        </Button>
                                                        <Menu
                                                            id="long-menu"
                                                            MenuListProps={{
                                                            'aria-labelledby': 'long-button',
                                                            }}
                                                            anchorEl={accessAnchorEl}
                                                            open={openAccessAnchorEl}
                                                            onClose={handleCloseAccessAnchorEl}
                                                            PaperProps={{
                                                                className: 'asset-card-long-menu',
                                                                style: {
                                                                    maxHeight: ITEM_HEIGHT * 4.5,
                                                                },
                                                            }}
                                                        >
                                                            {accessOptions.map((option:IAccessOption) => (
                                                            <MenuItem key={option.key} onClick={() => handleSelectAccessOption(option)}>
                                                                <ListItemIcon>
                                                                    {(selectedAccess.key === option.key) && <Check fontSize="small" sx={{color: 'rgba(255,255,255,0.7)'}} />}
                                                                </ListItemIcon>
                                                                {option.label}
                                                            </MenuItem>
                                                            ))}
                                                        </Menu>                                                        
                                                    </Box>
                                                </React.Fragment>
                                            }
                                            sx={{fontSize: '14px'}}
                                            secondary={
                                                <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                >
                                                    {selectedAccess.description}
                                                </Typography>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                            </List>
                        </Box>
                        
                    </Box>
                    <Snackbar
                        open={openSnackbar}
                        onClose={() => setOpenSnackbar(false)}
                        autoHideDuration={2000}
                        message="Link Copied"
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    />
                    
                </DialogContent>

                <DialogActions>
                    <Button 
                        variant="contained" 
                        className="done-button"
                        autoFocus 
                        onClick={handleSubmitVideoSharedUsers}
                    >
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}