import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getWatchlistItems } from "../services/searchService";

const WatchListContext = createContext<any[] | null>(null);

export const WatchListProvider = ({ children }: { children:any }) => {
  const [watchlistData, setWatchlistData] = useState<any[]>([]);
  const navigate = useNavigate();

  const getWatchList = () =>{
    getWatchlistItems()
            .then((response) => {
                setWatchlistData(response || []);              
            })
            .catch((error) => {
                console.log(error);                
            })
  }
  
  const updateWatchlist = () => {
    getWatchList();
  };

  useEffect(() => {
    getWatchList();
  }, []);

  const value = useMemo(
    () => ([
      watchlistData,
      updateWatchlist,
    ]),
    [watchlistData]
  );

  return <WatchListContext.Provider value={value}>{children}</WatchListContext.Provider>;
};

export const useWatchList = () => {
  return useContext(WatchListContext);
};
