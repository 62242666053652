import React, { useCallback, useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate, useOutletContext } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Switch,
  Tab,
  Tabs,
  Typography,
  Skeleton,
} from "@mui/material";
import { blackColor, whiteColor } from "../../common/constants";
import { ViewBy } from "../../types/interface";
import { getExploreMoreDataByVersion } from "../../services/searchService";
import { useSearchParams } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import CardDetailDialog from "../../components/CardDetailDialog";
import PlaylistDialog from "../../components/PlaylistDialog";
import VideoTabContent from "../../components/SearchResultVideoTab";

function otherProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ padding: "0px" }}>{children}</Box>
    </div>
  );
}

export default function ExploreMovie() {
  const [commonContext] = useOutletContext<any | null>();
  const {
    searchpayload,
    mediaCountResult,
    showMediaCountResult,
    selectedfilters,
  } = commonContext;

  const [searchParams, setSearchParams] = useSearchParams();
  let selected_movie = searchParams.get("movie");
  const movie_display_name = searchpayload.movies?.find((movieItem:any) => movieItem?.name?.toLowerCase() === selected_movie)?.display_name;

  const [isLoadedData, setIsLoadedData] = useState<boolean>(false);

  //For storing result data
  const [resultData, setResultData] = useState<any>([]);

  //For storing selected item for timeline
  const [selectedItemForTimeline, setSelectedItemForTimeline] = useState<
    null | any
  >(null);

  const [isloading, setIsloading] = useState(false);

  //To control between timeline and list view
  const [viewBy, setViewBy] = useState<ViewBy>(ViewBy.Clip);

  //For CardDetailDialog
  const [openCardDetailDialog, setOpenCardDetailDialog] =
    useState<boolean>(false);
  const [selectedCardDetailDialogData, setselectedCardDetailDialogData] =
    useState<{ type: string; value: any; autoplayVideo: boolean }>({
      type: "",
      value: {},
      autoplayVideo: false,
    });

  //For Lightbox
  const [toggler, setToggler] = useState<boolean>(false);
  const [productIndex, setProductIndex] = useState<number>(1);
  const [fullScreenMediaSource, setFullScreenMediaSource] =
    useState<string>("");

  const navigate = useNavigate();

  const [tabvalue, setTabvalue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedItemForTimeline(null);
    setTabvalue(newValue);
  };

  const handleGetExploreMoreDataByVersion = useCallback(
    (movie_name: string, params: { version: string }) => {
      setResultData({});
      setIsLoadedData(false);

      getExploreMoreDataByVersion(movie_name, params)
        .then((response) => {
          setResultData(response);
          setIsLoadedData(true);
        })
        .catch((err) => {});
    },
    []
  );

  useEffect(() => {
    if (selectedfilters.version.length) {
      selected_movie &&
        handleGetExploreMoreDataByVersion(selected_movie, {
          version: selectedfilters.version,
        });
    }
  }, [selectedfilters.version]);

  const handleOpenCloseCardDetailDialog = (val: boolean) => {
    setOpenCardDetailDialog(val);
  };

  const handleClickPlay = () => {
    setToggler((val) => !val);
    setFullScreenMediaSource(selectedCardDetailDialogData.value.url);
  };

  const handleClickMore = (dataObj: {
    type: string;
    value: any;
    autoplayVideo: boolean;
  }) => {
    setselectedCardDetailDialogData(dataObj);
    setOpenCardDetailDialog(true);
  };

  //For playlist dialog
  const [openPlaylistDialog, setOpenPlaylistDialog] = useState<boolean>(false);
  const [selectedPlaylistDialog, setSelectedPlaylistDialog] = useState<{
    type: string;
    value: any;
  }>({ type: "", value: {} });

  const handleOpenPlaylistDialog = (val: boolean) => {
    setOpenPlaylistDialog(val);
  };
  const handleAddToPlaylist = (dataObj: { type: string; value: any }) => {
    setSelectedPlaylistDialog(dataObj);
    setOpenPlaylistDialog(true);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }} className="">
      <CssBaseline />
      {isloading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            width: "100%",
            height: "100vh",
            zIndex: "9999",
            background: "rgba(0,0,0,0.75)",
          }}
        >
          <CircularProgress sx={{ color: whiteColor }} />
        </Box>
      )}

      <Box
        className="page-min-height-1"
        sx={{
          flexGrow: 1,
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
        }}
      >
        <Box className="playlist-tab-2">
          <Box
            className="container"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tabs
                value={tabvalue}
                onChange={handleTabChange}
                // variant="fullWidth"
                aria-label="explore-more-tabs"
                className=""
              >
                <Tab
                  label={
                    <span>
                      Videos <em>({resultData?.video?.total || "0"})</em>
                    </span>
                  }
                  {...otherProps(0)}
                />
              </Tabs>
              <Box sx={{ display: "flex", paddingLeft: "60px" }}>
                <Box
                  sx={{
                    fontSize: "16px",
                    color: "#989898",
                    display: "flex",
                    alignItems: "center",
                    columnGap: "12px",
                  }}
                >
                  <b>Timeline</b>
                  <Switch
                    checked={viewBy === ViewBy.Clip}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setViewBy(
                        event.target.checked ? ViewBy.Clip : ViewBy.List
                      );
                      if (!event.target.checked) {
                        setSelectedItemForTimeline(null);
                      }
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    className="mui-switch"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <CustomTabPanel value={tabvalue} index={0}>
          {isLoadedData ? (
            resultData.video?.videos?.length > 0 ? (
              <Box className="container" sx={{ marginBottom: 8 }}>
                <VideoTabContent
                  cardData={resultData.video.videos}
                  item={resultData.video}
                  tabType="video"
                  viewBy={viewBy === 0 ? "list" : "clip"}
                  setViewBy={(view) => setViewBy(view === "list" ? 0 : 1)}
                  searchpayload={searchpayload}
                  handleChangeInputs={() => {}}
                  showMediaCountResult={showMediaCountResult}
                  mediaCountResult={mediaCountResult}
                  searchedInputForResult=""
                  isActiveTab={tabvalue === 0}
                  showTimelineButtonInListViewCard={true}
                  showWatchlistButtonInListViewCard={true}
                  showPlaylistButtonInListViewCard={true}
                  showDebugButtonInListViewCard={false}
                  withoutSearch={true}
                  selectedItemForTimeline={selectedItemForTimeline}
                  handleClickTimeline={(card, index) => {
                    setSelectedItemForTimeline(card);
                    setViewBy(ViewBy.Clip);
                  }}
                  canAddToPlaylist={true}
                />
              </Box>
            ) : (
              <Box className="no-data-message">
                <Typography variant="h6">
                  There are currently no videos available for the movie <i>{movie_display_name}</i> in this version. <br />You can try with different movie and/or version.
                </Typography>
              </Box>
            )
          ) : (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                padding: "20px",
                gap: "40px",
                marginTop: 3,
              }}
              className="container"
            >
              <Skeleton
                variant="rounded"
                width={286}
                height={311}
                style={{
                  color: "#cfcfcf",
                  backgroundColor: "#4e474a",
                  borderRadius: "16px",
                }}
              />
              <Skeleton
                variant="rounded"
                width={286}
                height={311}
                style={{
                  color: "#cfcfcf",
                  backgroundColor: "#4e474a",
                  borderRadius: "16px",
                }}
              />
              <Skeleton
                variant="rounded"
                width={286}
                height={311}
                style={{
                  color: "#cfcfcf",
                  backgroundColor: "#4e474a",
                  borderRadius: "16px",
                }}
              />
              <Skeleton
                variant="rounded"
                width={286}
                height={311}
                style={{
                  color: "#cfcfcf",
                  backgroundColor: "#4e474a",
                  borderRadius: "16px",
                }}
              />
              <Skeleton
                variant="rounded"
                width={286}
                height={311}
                style={{
                  color: "#cfcfcf",
                  backgroundColor: "#4e474a",
                  borderRadius: "16px",
                }}
              />
            </Box>
          )}
        </CustomTabPanel>
      </Box>
      {openCardDetailDialog && (
        <CardDetailDialog
          openCardDetailDialog={openCardDetailDialog}
          handleOpenCloseCardDetailDialog={handleOpenCloseCardDetailDialog}
          selectedCardDetailDialogData={selectedCardDetailDialogData}
          handleClickPlay={handleClickPlay}
          searchpayload={searchpayload}
        />
      )}
      {openPlaylistDialog && (
        <PlaylistDialog
          openPlaylistDialog={openPlaylistDialog}
          handleOpenClosePlaylistDialog={handleOpenPlaylistDialog}
          selectedPlaylistDialogData={selectedPlaylistDialog}
          handleCreatePlaylist={() => {}}
        />
      )}
      <FsLightbox
        toggler={toggler}
        sources={[fullScreenMediaSource]}
        slide={productIndex}
        type={"video"}
      />
    </Box>
  );
}
