import type { FC, ReactNode } from 'react'
import { useDrop } from 'react-dnd'

export const DropContainer = ({ children }: {children:ReactNode}) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'box',
    drop: () => ({ item: 'Dustbin' }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  const isActive = canDrop && isOver
  let backgroundColor = '#222'
  if (isActive) {
    backgroundColor = 'darkgreen'
  } else if (canDrop) {
    backgroundColor = 'darkkhaki'
  }

  return (
    <div ref={drop} data-testid="dropcontainer">
      {isActive ? 
        <div style={{height: '100px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Release to drop</div> 
        : children
      }
    </div>
  )
}
