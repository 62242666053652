import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

export default function ProtectedLayout({redirectPath = '/login', children}: {redirectPath?:string , children?:any}) {
//   const { user } = useAuth();

//   if (!user) {
//     return <Navigate to={redirectPath} replace />;
//   }
if (!localStorage.getItem('token')) {
    return <Navigate to={redirectPath} replace />;    
}

  return children ? children : <Outlet />;
};
