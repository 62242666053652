import React, { useCallback, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import deleteIconOutlined from '../../assets/delete-icon-outlined.svg';

import './index.css';
import { Box } from "@mui/material";

interface DeleteDialogProps {
    openDeleteDialog: boolean;
    handleCloseDeleteDialog: (value: boolean) => void;
    handleSubmitDelete: () => void;
    primaryText: string;
    secondaryText: string;
    cancelButtonText?: string;
    deleteButtonText?: string;
}

export default function DeleteDialog(props: DeleteDialogProps) {
    const {
        openDeleteDialog,
        handleCloseDeleteDialog,
        handleSubmitDelete,
        primaryText,
        secondaryText,
        cancelButtonText = 'Cancel',
        deleteButtonText = 'Delete'
    } = props;

    //For deleting
    const handleDelete = useCallback((event:any) => {
        event.preventDefault();
        handleSubmitDelete();
    }, [handleSubmitDelete]);
    
    const handleClose = () => {
        handleCloseDeleteDialog(false);
    };

    return (
        <>
            <Dialog
                className="delete-dialog-box"
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openDeleteDialog}
            >
                <DialogContent>
                    <Box className="delete-icon-container">
                        <img src={deleteIconOutlined} alt="" />
                    </Box>
                    <Typography className="primary-text">
                        {primaryText}
                    </Typography>
                    <Typography className="secondary-text">
                        {secondaryText}
                    </Typography>

                    <Box className="delete-button-container">
                        <Button 
                            variant="outlined" 
                            className="delete-cancel-button"
                            size="small"
                            onClick={handleClose}
                        >
                            {cancelButtonText}
                        </Button>
                        <Button 
                            variant="contained" 
                            className="delete-delete-button"
                            onClick={handleDelete}
                        >
                            {deleteButtonText}
                        </Button>

                    </Box>
                    
                </DialogContent>

            </Dialog>
        </>
    );
}