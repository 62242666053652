import { ContentCopy, Settings as SettingsIcon } from "@mui/icons-material";
import {
  Box,
  Card,
  CssBaseline,
  FormControl,
  InputAdornment,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./index.css";

export default function Settings() {
  const [open, setOpen] = useState(false);

  // writeText function to copy or write data to clipboard
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(localStorage.getItem("token") ?? "");
  };

  return (
    <Box>
      <CssBaseline />
      <Box className="sub-container">
        <Box className="settings-tab">
          <Box className="container">
            <Box className="icon-container">
              <SettingsIcon className="settings-icon" />
              &nbsp;
              <Typography className="settings-text">Settings</Typography>
            </Box>
          </Box>
        </Box>

        <Box className="sub-container-body container">
          <Card className="settings-card">
            <Box className="card-row">
              <Typography>Access Token</Typography>
              <TextField
                variant="outlined"
                focused
                className="token-textfield"
                InputProps={{
                  style: { color: "#FFF" },
                  endAdornment: (
                    <InputAdornment position="end" className="input-adornment">
                      <Tooltip title="Copy" placement="right">
                        <FormControl
                          className="form-control"
                          onClick={handleClick}
                        >
                          <ContentCopy className="copy-icon" />
                        </FormControl>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                size="small"
                defaultValue={localStorage.getItem("token")}
              />
            </Box>
          </Card>
        </Box>
      </Box>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied to clipboard"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </Box>
  );
}
