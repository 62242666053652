import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import { Avatar, Box, Button, CssBaseline, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from "@mui/material";
import iconMovieOpenCogOutline from '../../assets/movie-open-cog-outline_1.svg';
import { Link, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import videoCameraGif from '../../assets/video_camera.gif';
import backArrow from '../../assets/backArrow.svg';
import iconShare from '../../assets/share_icon.svg';
import iconDelete from '../../assets/delete_icon.svg';
import iconEdit from '../../assets/edit-icon.svg';
import iconCheck from '../../assets/icon-check.svg';
import iconClose from '../../assets/icon-close.svg';
import iconPlayVideo from '../../assets/icon-video-play.svg';
import iconZoomVideo from '../../assets/icon-video-zoom.svg';
import iconFullVolume from '../../assets/iconFullVolume.svg';
import iconHalfVolume from '../../assets/iconHalfVolume.svg';
import iconMute from '../../assets/iconMute.svg';
import ShareDialog from "../../components/ShareDialog";
import { deleteVirtualVideo, getAllUsers, getVideo, updateVideoStatus } from "../../services/searchService";
import moment from "moment";
import FullPageLoader from "../../components/FullPageLoader";
import VirtualVideoPlayer from "../../components/VirtualVideoPlayer";
import { toast } from "react-toastify";
import DeleteDialog from "../../components/DeleteDialog";
import { VIRTUAL_VIDEO_STATUS } from "../../types/interface";
import { Edit } from "@mui/icons-material";
import { errorToast, successToast } from "../../common/utils";
import permissionDeniedIcon from "../../assets/permission_denied-icon.svg";
import logoutIcon from "../../assets/logout-icon.svg";
import ConfirmationDialog from "../../components/ConfirmationDialog";

export default function GeneratedVideo() {
    let { videoId } = useParams();
    const [commonContext] = useOutletContext<any | null>();
    const { searchpayload } = commonContext;
    const navigate = useNavigate();
    const location = useLocation();
    let from = location.state?.from?.pathname;

    const [genvidResponseList, setGenvidResponseList] = useState<any[]>([]);
    const [activeResource, setActiveResource] = useState<any>(null);
    const [permissionDenied, setPermissionDenied] = useState<boolean>(false);
    const [userlist, setUserlist] = useState<any[]>([]);
    const [renameText, setRenameText] = useState<string>('');
    const [showRenameField, setShowRenameField] = useState<boolean>(false);
    const [isloading, setIsloading] = useState<boolean>(false);

    const [volume, setVolume] = useState(1);
    const [muted, setMuted] = useState<boolean>(false);

    const [openShareDialog, setOpenShareDialog] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openLogoutDialog, setOpenLogoutDialog] = useState<boolean>(false);

    const handleClickFullscreen = () => {
    }

    const handleClickShare = () => {
        setOpenShareDialog(true);
    }

    const handleClickDelete = () => {
        setOpenDeleteDialog(true);
    }

    const handleClickLoginAsDifferentUser = () => {
        setOpenLogoutDialog(true);
    }

    const handleSubmitLogout = () => {
        localStorage.clear();
        navigate("/login");
    }

    const handleGetVideo = useCallback((id: string) => {
        setIsloading(true);
        getVideo(id)
            .then((response) => {
                // setGenvidResponseList((prev) => ([...prev, response]));
                setActiveResource(response);
                setIsloading(false);
            })
            .catch((err) => {
                if (err?.response?.status === 403) {
                    setPermissionDenied(true);
                } else if (err?.response?.status === 404) {
                    navigate('/page-not-found');
                } else {}
                setIsloading(false);
            })
    }, []);

    //For getting registered users list
    const handleGetUsers = useCallback(() => {
        setIsloading(true);
        getAllUsers()
            .then((response) => {
                setUserlist([...response]);
                setIsloading(false);
            })
            .catch((err) => {
                setIsloading(false);
            })
    }, []);

    //For updating viewed status of the video
    const handleUpdateViewedStatus = useCallback((assetId: string) => {
        setIsloading(true);
        updateVideoStatus(assetId, {viewed: true})
            .then((response) => {
                console.log('updating viewed successful');                
                setIsloading(false);
            })
            .catch((err) => {
                setIsloading(false);
            })
    }, []);

    useEffect(() => {
        videoId && handleGetVideo(videoId);
        handleGetUsers();
    }, []);

    const shared_by_user = userlist?.find((userItem) => userItem.id === activeResource?.shared_by);
    
    const handleClickRename = useCallback(() => {
        setRenameText(activeResource?.name);
        setShowRenameField(true);
    }, [activeResource]);

    const handleCancelRename = useCallback(() => {
        setRenameText('');
        setShowRenameField(false);
    }, []);

    const handleSubmitRename = useCallback(() => {
        if (!renameText.length) {
            errorToast('Name cannot be empty!');
            return;
        }
        setIsloading(true);
        const reqBody = {
            name: renameText
        };
        updateVideoStatus(videoId ?? '', reqBody)
            .then((response) => {
                successToast('Video renamed successfully!');
                handleCancelRename();
                videoId && handleGetVideo(videoId);
                // setIsloading(false);
            })
            .catch((err) => {
                errorToast('Failed to rename video');
                setIsloading(false);
            })
    }, [renameText, videoId]);

    const handleDeleteVideo = useCallback(() => {
        setIsloading(true);
        deleteVirtualVideo(videoId ?? '')
            .then((response) => {
                successToast('Video deleted successfully!');
                setOpenDeleteDialog(false);
                setIsloading(false);
                from ? navigate(from) : navigate('/assets');
            })
            .catch((err) => {
                errorToast('Failed to delete video');
                setIsloading(false);
            })
    }, [videoId]);
    
    return (
        <Box>
        <CssBaseline />
        {isloading && <FullPageLoader />}
        <Box className="sub-container-generated-video">
            <Box className="generated-video-tab">
                <Box className="container">
                    <Box className="icon-container">
                        <img src={iconMovieOpenCogOutline} alt="" className="" />
                        <Typography className="subheader-text">Generated Video</Typography>
                    </Box>
                </Box>
            </Box>

            <Box className="generated-video-body container">
                {!permissionDenied ?
                <Box className="gvb-inner">
                    <Box className="gvb-inner-top">
                        <Box className="first">
                            {from && <Box className="image-box">
                                <img src={backArrow} alt="" style={{paddingTop: showRenameField ? '16px' : '0px'}} onClick={() => { navigate(from) }} />
                            </Box>}
                            <Box sx={{marginLeft: '12px'}}>
                                {!showRenameField ? <Typography className="generated-video-name">
                                    {activeResource?.name}
                                    {activeResource?.owned && <Typography component="span" className="edit-icon-box">
                                        <img src={iconEdit} alt="" onClick={handleClickRename} />
                                    </Typography>}
                                </Typography>
                                :
                                <Box className="rename-field-box">
                                    <TextField 
                                        id="rename-textbox-field"
                                        className="rename-textbox-field"
                                        size="small"
                                        value={renameText}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setRenameText(event.target.value);
                                        }}
                                    />
                                    <Box className="rename-check-box" onClick={handleSubmitRename}>
                                        <img src={iconCheck} alt="" />
                                    </Box>
                                    <Box className="rename-cancel-box" onClick={handleCancelRename}>
                                        <img src={iconClose} alt="" />
                                    </Box>
                                </Box>}
                                <Typography className="generated-video-details">{moment.utc(activeResource?.created_on).local().format('DD MMMM YYYY | hh:mm A')}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                            {activeResource?.owned && <Box 
                                component={'span'} 
                                className="update-button-container"
                                onClick={() => { navigate(`/generate-video/${activeResource?.id}`, { state: { from: location } }) }}
                            >
                                <Edit 
                                    className="update-button"
                                />
                            </Box>}
                            {activeResource?.owned && (activeResource?.status === VIRTUAL_VIDEO_STATUS.COMPLETED || activeResource?.status === VIRTUAL_VIDEO_STATUS.FAILED) && <img 
                                src={iconDelete} alt="" 
                                style={{cursor: 'pointer', marginLeft: '11px'}}
                                onClick={handleClickDelete}
                            />}
                            {activeResource?.owned && activeResource?.status === VIRTUAL_VIDEO_STATUS.COMPLETED && <img 
                                src={iconShare} alt="" 
                                style={{marginLeft: '11px', cursor: 'pointer'}} 
                                onClick={handleClickShare}
                            />}
                        </Box>
                    </Box>
                    <Box>
                        <VirtualVideoPlayer 
                            videoData={activeResource}
                        />
                    </Box>
                    <Box className="genvid-metadata">
                        <Typography sx={{fontSize: '16px', color: '#C5C5C5', paddingBottom: '16px'}}>
                            Movie Name: &nbsp;
                            {searchpayload.movies?.find((m:any) => m.name.toLowerCase() === activeResource?.movie_name.toLowerCase())?.display_name}
                        </Typography>
                        <Typography sx={{fontSize: '16px', color: '#C5C5C5', borderTop: '1px solid rgba(58, 58, 58, 0.7)', paddingTop: '16px', paddingBottom: '22px'}}>
                            {activeResource?.query}
                        </Typography>
                    </Box>
                    {/* <Box className="genvid-textarea-box-readonly">
                        <TextField
                            id="outlined-genvid-textarea"
                            multiline
                            rows={3}
                            className="genvid-textarea-readonly"
                            InputProps={{readOnly: true}}
                            value={activeResource?.query}
                        />
                    </Box> */}
                    <Box className="bottom-section">
                        <Box>
                            {activeResource?.shared_by && 
                            <>
                                <Typography sx={{fontSize: '14px', color: '#6E737D'}}>Shared from</Typography>
                                <List className="share-user-list" dense={true}>
                                    <ListItem sx={{padding: '0px'}}
                                    >
                                        <ListItemAvatar>
                                        <Avatar alt="Remy Sharp" src={shared_by_user?.picture} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={shared_by_user?.name}
                                            sx={{fontSize: '14px'}}
                                            secondary={
                                                <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                >
                                                    {shared_by_user?.email}
                                                </Typography>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                </List>      
                            </>
                            }
                        </Box>
                        <Box>
                            <Link to={"/generate-video"} style={{fontSize: '14px', color: '#C5C5C5'}}>Generate more new video?</Link>
                        </Box>
                    </Box>
                </Box>
                : <>
                <Box className="gvb-inner permission-denied-container">
                    <Box>
                        <img src={permissionDeniedIcon} alt="" />
                    </Box>
                    <Box>
                        <Typography sx={{fontSize: '18px', color: '#FFFFFF', marginTop: '32px', paddingBottom: '6px'}}>
                            You do not have permission to view the requested page
                        </Typography>
                    </Box>
                    <Box>
                        <Typography sx={{fontSize: '14px', color: '#6E737D', paddingBottom: '6px'}}>
                            Either go to assets page to view your assets or log in as a different user.
                        </Typography>
                    </Box>
                    <Box sx={{marginTop: '24px', marginBottom: '16px'}}>
                        <Button 
                            variant="contained" 
                            className="perm-den-login-button" 
                            onClick={handleClickLoginAsDifferentUser}
                        >
                            Log in as different user
                        </Button>
                    </Box>
                    <Box>
                        <Link to={"/assets"} style={{fontSize: '14px', color: '#C5C5C5'}}>Go to assets</Link>
                    </Box>
                </Box>
                </>
                }
            </Box>
        </Box>
        {openShareDialog && <ShareDialog 
            openShareDialog={openShareDialog}
            handleCloseShareDialog={(val:boolean) => setOpenShareDialog(val)}
            userlist={userlist}
            activeResource={activeResource}
        />}
        {openDeleteDialog && <DeleteDialog 
            openDeleteDialog={openDeleteDialog}
            handleCloseDeleteDialog={(val:boolean) => setOpenDeleteDialog(val)}
            handleSubmitDelete={handleDeleteVideo}
            primaryText="Are you sure?"
            secondaryText="Do you really want to delete this video? This process cannot be undone."
        />}
        {openLogoutDialog && <ConfirmationDialog 
            openConfirmDialog={openLogoutDialog}
            handleCloseDialog={(val:boolean) => setOpenLogoutDialog(val)}
            handleSubmitConfirm={handleSubmitLogout}
            confirmIcon={logoutIcon}
            primaryText="Logout"
            secondaryText="Are you sure you want to logout? You will be returned to the login screen."
            cancelButtonText="Cancel"
            yesButtonText="Yes, Logout"
        />}
        </Box>
    );
}
