import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Search from "./pages/search";
import Login from "./pages/login";
import { Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements, defer } from 'react-router-dom';
import LoginCallback from "./pages/login/callback";
import Playlists from "./pages/Playlists";
import Home from "./pages/Home";
import Categories from "./pages/Categories";
import About from "./pages/About";
import Contact from "./pages/Contact";
import AdminUserList from "./pages/Admin/UserList";
import Index from "./pages/Index/Index";
import { getProfile } from "./services/searchService";
import CommonLayout from "./layouts/CommonLayout";
import ProtectedLayout from "./layouts/ProtectedLayouts";
import { AuthLayout } from "./layouts/AuthLayout";
import Settings from "./pages/Settings";
import GenerateVideo from "./pages/GenerateVideo";
import GeneratedVideo from "./pages/GeneratedVideo";
import Assets from "./pages/Assets";
import PageNotFound from "./pages/PageNotFound";
import ExploreMovie from "./pages/ExploreMovie";

const getUserProfile = () => {
  return getProfile();
};

function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route
          element={<AuthLayout />}
          loader={() => defer({ userPromise: getUserProfile() })}
        >
            <Route element={<ProtectedLayout />}>
              <Route path="/" element={<CommonLayout />} >
                <Route id="home" path="/" element={<Home />} >
                </Route>
                <Route id="playlists" path="/playlists" element={<Playlists />} >
                </Route>
                <Route id="categories" path="/categories" element={<Categories />} >
                </Route>
                <Route id="about" path="/about" element={<About />} >
                </Route>
                <Route id="contact" path="/contact" element={<Contact />} >
                </Route>          
                <Route id="search" path="/search" element={<Search></Search>} > {/* 👈 Renders at /app/ */}
                </Route>
                <Route id="settings" path="/settings" element={<Settings />} >
                </Route>          
                <Route id="vvg" path="/generate-video/:resourceId?" element={<GenerateVideo />} >
                </Route>          
                <Route id="vvgd" path="/generated-video/:videoId" element={<GeneratedVideo />} >
                </Route>          
                <Route id="assets" path="/assets" element={<Assets />} >
                </Route>          
                <Route id="explore-movie" path="/explore-movie" element={<ExploreMovie />} >
                </Route>          
                <Route path="*" element={<PageNotFound></PageNotFound>} ></Route>
              </Route>
            </Route>
            <Route id="indexPage" path="/index" element={<Index /> } >
            </Route>
            <Route id="adminuserlist" path="/admin/users" element={<AdminUserList />} >
            </Route>
        </Route>
        <Route path="/login" element={<Login />} ></Route>
        <Route id="callback" path="/login-callback" element={<LoginCallback></LoginCallback>} ></Route>
      </Route>
    )
  );

  return (

    <>
    <RouterProvider router={router} />
      <ToastContainer 
        hideProgressBar
        theme="dark"
        closeButton={false}
      />
    </>
  );
}

export default App;
