import { toast } from "react-toastify";
import { videoSliderColors } from "../constants";
import successIcon from "../../assets/success-icon.png";
import errorIcon from "../../assets/fail-icon.png";
import warnIcon from "../../assets/warn-icon.png";
import infoIcon from "../../assets/info-icon.svg";

export const getConfidence = (similarity:number) => {
    let confidence;
    if (similarity > 0.8) {
        confidence = "High"
    } else if (similarity > 0.7 && similarity <= 0.8) {
        confidence = "Medium"
    } else {
        confidence = "Low"
    }
    return confidence;
}

export const getConfidenceColor = (similarity:number) => {
    let color;
    if (similarity > 0.8) {
        color = videoSliderColors[0];
    } else if (similarity > 0.7 && similarity <= 0.8) {
        color = videoSliderColors[1];
    } else {
        color = videoSliderColors[2];
    }
    return color;
}

export const getConfidenceColoByConfidence = (confidence:string) => {
    if(!confidence){
        return videoSliderColors[2]
    }
    let color;
    if (confidence.toLowerCase() === 'high' ) {
        color = videoSliderColors[0];
    } else if (confidence.toLowerCase() === 'medium') {
        color = videoSliderColors[1];
    } else {
        color = videoSliderColors[2];
    }
    return color;
}

export const secondsToMMSS = (timeInSec:number) => {
    return new Date(timeInSec * 1000).toISOString().substring(14, 19).replace(/^[0:]+(?=\d[\d:]{3})/, '');
}

export const getFormatedTime = (timeInSec:number) => {
    if(!timeInSec){
        return ""
    }
    return new Date(timeInSec * 1000).toISOString().substring(11, 19).replace(/^[0:]+(?=\d[\d:]{3})/, '');
}

export const getFormatedDuration = (startTime: any, endTime: any) => {
    const getSeconds = Math.trunc(Number(endTime) - Number(startTime));
    return getFormatedTime(getSeconds)
};

export const successToast = (message: string | JSX.Element, properties?:any) => {
    return toast.success(message, {...properties, icon: ({theme, type}) => <img src={successIcon} alt="" />});
}

export const errorToast = (message: string | JSX.Element, properties?:any) => {
    return toast.error(message, {...properties, icon: ({theme, type}) => <img src={errorIcon} alt="" />});
}

export const warnToast = (message: string | JSX.Element, properties?:any) => {
    return toast.warn(message, {...properties, icon: ({theme, type}) => <img src={warnIcon} alt="" />});
}

export const infoToast = (message: string | JSX.Element, properties?:any) => {
    return toast.info(message, {...properties, icon: ({theme, type}) => <img src={infoIcon} alt="" />});
}
