export interface ISearchPayload {
    text: string; 
    search_for: string;
    movies: any[];
    moviesByVersion: any[];
    version: string;
    unique: boolean;
    similarity_min: number;
    similarity_max: number;
    confidence: number;
    is_dialog: boolean;
    apply_video_HS: boolean;
    topK_from_video_HS_set: string;
    topK_videos: string;
    categories: any[];
}

export interface ISelectedFiltersState {
    films: string[];
    version: string;
    unique: boolean;
    minSimilarity: number;
    maxSimilarity: number;
    confidence: number;
    videoHS: boolean;
    topKHS: string;
    topKresult: string;
    categories: string[];
};

export enum MediaType{
    IMAGE = 1,
    VIDEO = 2
} 

export enum PlayerPlayMode{
    FULL_MOVIE_PLAY = 0,
    SINGLE_CLIP_PLAY = 1,
    AUTO_PLAY_CLIP = 2
} 

export enum ViewBy {
    List = 0,
    Clip = 1
}

export interface ICurrentVideoState {
  url: string | null;
  movie: any;
  pip?: boolean;
  playing: boolean;
  controls: boolean;
  light: boolean;
  volume: number;
  muted: boolean;
  played: number;
  loaded: number;
  duration: number;
  playbackRate: number;
  loop: boolean;
  seeking?: boolean;
}

export interface ICurrentImageState {
    url: string | null;
    movie: any;
  }

export interface IGenerateVideoPayload {
    query: string;
    version: string;
    movie_name: string;
    config?: Record<string, unknown>;
    parent_id: string | null; 
    root_id: string | null;
}

export enum VIRTUAL_VIDEO_STATUS {
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    NEW = "NEW",
    FAILED = "FAILED"
}

export enum SHARED_SCOPE {
    RESTRICTED = "RESTRICTED",
    ANYONE_HAVING_LINK = "ANYONE_HAVING_LINK"
}

export enum Generate_Video_Mode {
    CREATE = "create",
    UPDATE = "update",
    EDIT = "edit"
}

export interface IBannersData {
    id: string;
    movie_name: string;
    url: string;
    label_url: string;
    description: string;
}
