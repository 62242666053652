export const confidenceSliderMarks = [
  {
    value: 10,
    label: 'Low',
  },
  {
    value: 20,
    label: 'Medium',
  },
  {
    value: 30,
    label: 'High',
  },
];

export const sortbyList = [
  {
    value: 0,
    label: 'Sort By Confidence: Low to High',
  },
  {
    value: 1,
    label: 'Sort By Confidence: High to Low',
  },
];

export const videoSliderColors = ["#A2E985", "#FEC868", "#EC6B62"];

export const whiteColor = '#FFFFFF';
export const blackColor = '#000000';
export const greyColor = '#989898';

export const apiBaseUrl = "http://localhost:3000";

export const resultFilterList = [{text: "Less than ~30 seconds", value:"Snip"},{text: "~30 seconds to ~5 minutes", value:"Play"}];

export const responsiveSettingForCarousel = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1366 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1366, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 579 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 579, min: 0 },
    items: 1
  }
};

export const drawerWidth = 311;
export const filterDrawerWidth = 331;

//search api url
export const searchUrl = `${apiBaseUrl}/...`;

export const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
export const videoTypes = ['mp4', 'avi', 'mov', 'mpg', 'm4v', 'webm', 'wmv', 'mkv', 'flv', 'swf'];
export const pdfTypes = ['pdf'];
export const jsonTypes = ['json'];
export const textTypes = ['txt', 'text'];

export const generateVideo_defaultConfigJson = {
  "max_rev_clips": 30,
  "max_clips_per_event": 27,
  "max_local_audio_moments": 4, 
  "max_local_visual_moments": 8,
  "clip_length_preference": "long",
  "character_confirmation_needed": true
};

export const clipLengthPreferenceList = ['short', 'medium', 'long'];

export const generatingVideoStatusMessage = {
  label: 'Result is being generated...',
  description: 'It will take some time to get the result. You can come back in two hours to check the result.'
}
