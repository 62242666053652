import React, { useCallback, useEffect, useState } from "react";
import {Link, useNavigate, useOutletContext} from 'react-router-dom';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import FsLightbox from "fslightbox-react";
import { Box, Button, Chip, CircularProgress, Drawer, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton, Switch, Tab, Tabs, TextField, Toolbar, Typography, styled } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { blackColor, responsiveSettingForCarousel, whiteColor } from "../../common/constants";
import { IBannersData, ISearchPayload } from "../../types/interface";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './index.css';
import { getHomeData, getHomepageBannersDataByVersion, getHomepageCategoriesByVersion } from "../../services/searchService";
import CardDetailDialog from "../../components/CardDetailDialog";
import PlaylistDialog from "../../components/PlaylistDialog";
import MediaCard from "../../components/MediaCard";
import { errorToast } from "../../common/utils";
import { useNavigateSearch } from "../../hooks/useNavigateSearch";

export default function Home() {
    const [commonContext] = useOutletContext<any | null>();
    const {
        searchpayload, 
        selectedfilters
    } = commonContext;
    const navigateSearch = useNavigateSearch();

    const [categoryRows, setCategoryRows] = useState<any[]>([]);
    const [isLoadedData, setIsLoadedData] = useState<boolean>(false);
    const [isloading, setIsloading] = useState(false);
    const [bannersData, setBannersData] = useState<IBannersData[]>([]);

    //For CardDetailDialog
    const [openCardDetailDialog, setOpenCardDetailDialog] = useState<boolean>(false);
    const [selectedCardDetailDialogData, setselectedCardDetailDialogData] = useState<{type:string; value:any; autoplayVideo: boolean;}>({type: '', value: {}, autoplayVideo: false});
  
    //For Lightbox
    const [toggler, setToggler] = useState<boolean>(false);
    const [productIndex, setProductIndex] = useState<number>(1);
    const [fullScreenMediaSource, setFullScreenMediaSource] = useState<string>('');

    //For playlist dialog
    const [openPlaylistDialog, setOpenPlaylistDialog] = useState<boolean>(false);
    const [selectedPlaylistDialog, setSelectedPlaylistDialog] = useState<{type:string; value:any}>({type: '', value: {}});
          
    const navigate = useNavigate();

    //For getting banners list from server based on version
    const handleGetBannersByVersion = useCallback((param: {version: string}) => {
        setBannersData([]);
        getHomepageBannersDataByVersion(param)
            .then((response) => {
                setBannersData(response);
            })
            .catch((err) => {
                // errorToast('Failed to fetch banners');
            })
    }, [])

    //For getting categories list with data from server based on version
    const handleGetCategoriesWithDataByVersion = useCallback((param: {version: string}) => {
        setCategoryRows([]);
        setIsLoadedData(false);
        getHomepageCategoriesByVersion(param)
            .then((response) => {
                setCategoryRows(response);
                setIsLoadedData(true)
            })
            .catch((err) => {
                // errorToast('Failed to fetch categories');
                setIsLoadedData(true)
            })
    }, [])

    //For updating moviesByVersion list on change in version
    useEffect(() => {
      if (selectedfilters.version.length) {
        handleGetBannersByVersion({version: selectedfilters.version});
        handleGetCategoriesWithDataByVersion({version: selectedfilters.version});
      }
    }, [selectedfilters.version]);

    const handleOpenPlaylistDialog = (val:boolean) => {
        setOpenPlaylistDialog(val);
    };
    const handleAddToPlaylist = (dataObj: {type:string; value:any}) => {
        setSelectedPlaylistDialog(dataObj);
        setOpenPlaylistDialog(true);
    }

    const handleOpenCloseCardDetailDialog = (val:boolean) => {
        setOpenCardDetailDialog(val);
    };

    const handleClickPlay = () => {
        setToggler(val => !val);
        setFullScreenMediaSource(selectedCardDetailDialogData.value.url);
    }

    const handleClickMore = (dataObj: {type:string; value:any; autoplayVideo: boolean;}) => {
        setselectedCardDetailDialogData(dataObj);
        setOpenCardDetailDialog(true);
    }
    
    return (

        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <CssBaseline />
          {isloading && (
            <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                width: "100%",
                height: "100vh",
                zIndex: "9999",
                background: "rgba(0,0,0,0.75)",
            }}
            >
            <CircularProgress sx={{color: whiteColor}} />
            </Box>
        )}

          <Box sx={{ flexGrow: 1, marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>
            <Box 
                sx={{
                    width: '100%', 
                    height: {md: '90vh', sm: '400px', xs: '400px'}, 
                    maxHeight:{md: '800px', sm: '400px', xs: '400px'},
                    minHeight:{md: '500px', sm: '400px', xs: '400px'}
                }}
            >
                <Box sx={{background: 'rgba(0,0,0,0.5)', width: '100%', height: '100%'}}>
                    <Swiper
                        direction={'horizontal'}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                        clickable: true,
                        }}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper homebanner-swiper"
                        observer={true}
                    >
                        {bannersData.map((bannerItem:IBannersData) => 
                            <SwiperSlide>
                            <img src={bannerItem.url} style={{width: '100%', height: '100%'}} />
                            <div className="banner-text">
                                <img src={bannerItem.label_url} alt="" />
                                <div className="banner-desc">
                                    {bannerItem.description}
                                </div>
                                <div>
                                    <Button 
                                        variant="outlined" 
                                        onClick={(event) => {
                                            // commonContext.setSearchpayload((prev:ISearchPayload) => ({...prev, text: ''})); 
                                            // commonContext.handleSubmit({page: 'homepage', argList: {event: event, tabValue: 0, viewBy: 'clip', option: 2, movies: [bannerItem.movie_name]}}) 
                                            navigateSearch('/explore-movie', { movie: bannerItem.movie_name });
                                        }} 
                                    >
                                        Explore More
                                    </Button>
                                </div>
                            </div>
                        </SwiperSlide>)
                        }
                    </Swiper>                    
                </Box>
            </Box>

            <Box sx={{ paddingBottom: '0px'}} className="homepage container">
                {
                isLoadedData?
                categoryRows?.map((row, rowIndex) =>{
                    return (
                        <Box key={rowIndex}>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} className="heading-bar">
                        <Typography className="h3">
                        {row.display_text}
                        </Typography>
                        <Typography sx={{fontSize: '14px', color: '#FFFFFF'}} className="view-all">
                            <Link to={`/categories?category=${row.category_id}`} style={{listStyle: 'none', textDecoration: 'none', color: 'inherit'}}>View All</Link>
                        </Typography>
                        </Box>
                        {
                        isLoadedData ?
                        <Box sx={{width: '100%', marginLeft: 'auto', marginRight: 'auto', justifyContent: 'center'}} className="slider-list">
                            <Box sx={[{ }, (theme) => ({})]} className="carousel-container">
                                <Carousel 
                                responsive={responsiveSettingForCarousel}
                                infinite={true}
                                draggable={false}
                                renderDotsOutside={true}
                                >
                                    {
                                    
                                    row?.data?.map((item: any, index: any)=>{
                                    const getSeconds =  Math.trunc(Number(item.end_time) - Number(item.start_time));
                                    const getTime =   new Date(getSeconds * 1000).toISOString().substring(14, 19);
                                    return(
                                        <MediaCard key={item.id} cardType="video" card={item} component={"video"} 
                                            showTimelineButton={true} 
                                            showWatchlistButton={true}
                                            showPlaylistButton={true}
                                            showDebugButton={false}
                                            handleMediaClick={() => {
                                            setToggler(val => !val);
                                            setFullScreenMediaSource(item.url);
                                    }} 
                                          handleDebugClick={() => {
                                          }} 
                                          handleClickMore={(dataObj) => handleClickMore({type: 'default', value: item, autoplayVideo: dataObj.autoplayVideo})}
                                          handleAddToPlaylistClick={() => {
                                            handleAddToPlaylist({type: 'default', value: item})
                                          }}
                                          handleClickTimeline={(event) => {
                                            // commonContext.setSearchpayload((prev:ISearchPayload) => ({...prev, text: ''})); 
                                            // commonContext.handleSubmit({page: 'homepage', argList: {event: event, tabValue: 0, viewBy: 'clip', option: 2, cardItem: item, categories: [row.category]}})
                                            navigateSearch('/categories', { category: row.category_id, selectedItem: item.id });
                                        }}
                                        searchpayload={commonContext.searchpayload}
                                          />
                            
                                    )

                                    })
                                    
                                    }
                                </Carousel>
                        </Box>
                        </Box>
                        :
                        <Grid container spacing={{xs: 0, sm: 1, md: 1, lg: 3, xl: 5.5}} sx={{marginBottom:10}}>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <Skeleton variant="rounded" height={326} style={{color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px'}} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <Skeleton variant="rounded" height={326} style={{color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px'}} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <Skeleton variant="rounded" height={326} style={{color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px'}} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <Skeleton variant="rounded" height={326} style={{color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px'}} />
                            </Grid> 
                        </Grid>
                        }
                        </Box>
                    )
                    
                })
                :
                                ["","",""].map((row, rowIndex) =>{
                                            return (
                                                <Box key={rowIndex}>
                                                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} className="heading-bar">
                                                <Typography className="h3">
                                                ...
                                                </Typography>
                                                <Typography sx={{fontSize: '14px', color: '#FFFFFF'}} className="view-all">
                                                    <Link to={`/categories`} style={{listStyle: 'none', textDecoration: 'none', color: 'inherit'}}>View All</Link>
                                                </Typography>
                                                </Box>
                                                {
                                                    <Grid container spacing={{xs: 0, sm: 1, md: 1, lg: 3, xl: 5.5}} sx={{marginBottom:10}}>
                                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                        <Skeleton variant="rounded" height={326} style={{color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px'}} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                        <Skeleton variant="rounded" height={326} style={{color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px'}} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                        <Skeleton variant="rounded" height={326} style={{color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px'}} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                        <Skeleton variant="rounded" height={326} style={{color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px'}} />
                                                    </Grid> 
                                                </Grid>
                                                }
                                                </Box>
                                                )
                                                }
                                                )
                                        }

            </Box>
          </Box>     
          {openCardDetailDialog && 
            <CardDetailDialog 
                openCardDetailDialog={openCardDetailDialog}
                handleOpenCloseCardDetailDialog={handleOpenCloseCardDetailDialog}
                selectedCardDetailDialogData={selectedCardDetailDialogData}
                handleClickPlay={handleClickPlay}
                searchpayload={commonContext.searchpayload}
            />
          }

        {openPlaylistDialog && 
            <PlaylistDialog 
                openPlaylistDialog={openPlaylistDialog}
                handleOpenClosePlaylistDialog={handleOpenPlaylistDialog}
                selectedPlaylistDialogData={selectedPlaylistDialog}
                handleCreatePlaylist = {()=>{}}
            />
          }
          <FsLightbox
            toggler={toggler}
            sources={[fullScreenMediaSource]}
            slide={productIndex}
            type={"video"}
        />
        </Box>
    );
}