import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { LinkedIn, Twitter } from "@mui/icons-material";
import { Link } from "react-router-dom";
import iconLinkedin from '../../assets/iconLinkedin.svg'
import iconTwitter from '../../assets/iconTwitter.svg'

export default function Footer() {
    return (
        <Box className="footer">
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', }} className="container footer-top">
                <Box sx={{flex:{lg:"0 0 160px", md:"0 0 160px", sm:"0 0 auto", xs:"0 0 auto", }}}>
                    <Box component="img" sx={{display:"block", width: {lg:'37px', md:'37px', sm: '24px', xs: '24px'}}} alt=".." src="/images/logo_only.png" />
                </Box>
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'flex-end', columnGap:'20px'}}>
                    <img src={iconLinkedin} alt="" />
                    <img src={iconTwitter} alt="" />
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: "flex-end", flex:{lg:"0 0 160px", md:"0 0 160px", sm:"0 0 auto", xs:"0 0 auto",}}}>
                    <Typography sx={{color: '#FFFFFF', fontSize: '16px'}}><Link style={{listStyle: 'none', textDecoration: 'none', color: 'inherit'}} to="/about">About</Link></Typography>
                    <Typography sx={{color: '#FFFFFF', fontSize: '16px', marginLeft: '24px'}}><Link style={{listStyle: 'none', textDecoration: 'none', color: 'inherit'}} to="/contact">Contact Us</Link></Typography>
                </Box>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', }}  className="container footer-bottom">
                <Typography sx={{color: '#989898', fontSize: '12px', lineHeight:'15px'}}>
                    &copy; 2023 All rights reserved
                </Typography>
            </Box>
        </Box>
    );
}
