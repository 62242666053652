import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  errorToast,
  getConfidence,
  getConfidenceColoByConfidence,
  getFormatedDuration,
  getFormatedTime,
} from "../../common/utils";
import {
  Favorite,
  PestControlOutlined,
  PlayArrow,
  PlayCircle,
  PlayCircleRounded,
} from "@mui/icons-material";
import { blackColor, greyColor, whiteColor } from "../../common/constants";
import { addWatchlistItem, isInWatchlist } from "../../services/searchService";
import iconPlay from "../../assets/card-icon-play.svg";
import iconWatchlist from "../../assets/card-icon-watchlist.svg";
import iconPlaylist from "../../assets/card-icon-playlist.svg";
import iconWatchlistBlack from "../../assets/card-icon-watchlist-black.svg";
import iconBug from "../../assets/card-icon-bug.svg";
import iconTimeline from "../../assets/card-icon-timeline.svg";
import { ISearchPayload, MediaType } from "../../types/interface";
import { toast } from "react-toastify";
import { useWatchList } from "../../hooks/useWatchList";
import {IconPlaySvg, IconFavSvg, IconPlayListSvg, IconBugSvg, IconTimeLineSvg} from "../../common/utils/icons";
 



interface ICommonMediaCardProps {
  cardType: string;
  card: any;
  component: string;
  handleMediaClick: () => void;
  handleDebugClick: () => void;
  handleClickMore: ({ type, value, autoplayVideo }: { type: string; value: any; autoplayVideo: boolean }) => void;
  handleAddToPlaylistClick: () => void;
  handleClickTimeline: (e: any) => void;
  showTimelineButton?: boolean;
  showWatchlistButton?: boolean;
  showPlaylistButton?: boolean;
  showDebugButton?: boolean;
  searchpayload: ISearchPayload;
}

export default function MediaCard({
  cardType,
  card,
  component,
  handleMediaClick,
  handleDebugClick,
  handleClickMore,
  handleAddToPlaylistClick,
  handleClickTimeline,
  showTimelineButton = false,
  showWatchlistButton = false,
  showPlaylistButton = false,
  showDebugButton = false,
  searchpayload
}: ICommonMediaCardProps) {

  const [isWatchlistAdded, setIsWatchlistAdded] = useState(false);
  const [watchlistData, updateWatchlist] = useWatchList() as any[];

  const addToWatchlist = (id: string) => {
    setIsWatchlistAdded(true);
    addWatchlistItem(id)
      .then((response) => {
        updateWatchlist();

      })
      .catch((error) => {
        setIsWatchlistAdded(false);
        errorToast(`Failed to update watchlist`);
      })
  }


  useEffect(() => {

  }, []);

  return (
    <>
      <Box className="card-item" onClick={() => handleClickMore({ type: cardType, value: card, autoplayVideo: true })}>
        <Box
          className="card-img-bg"
          sx={{
            background: `url('${card.thumbnail_url ?? card.thumbnail_uri
              }') center/cover no-repeat`,
          }}
        >
          <Box className="card-top-row">
            {card.confidence && (
              <Box
                className="confidence-level"
                sx={{
                  color: blackColor,
                  background: getConfidenceColoByConfidence(card.confidence),
                  textTransform: "capitalize",
                }}
              >
                {card.confidence}
              </Box>
            )}
            {card.media_type === MediaType.VIDEO && (
              <Box className="card-time">
                {card.end_time &&
                  card.start_time &&
                  getFormatedDuration(card.start_time, card.end_time)}
              </Box>
            )}
          </Box>
        </Box>
        <Box className="card-desc">
          <Box className="backdrop-up">
            <Box>
              <Box>
                <Typography className="h2">
                  {card.media_type === MediaType.IMAGE || card.type.toLowerCase() === "dialogue"
                    ? card.desc ?? card.content
                    : card.title}
                </Typography>
                <Typography className="h4 title-group">
                  {card.media_type === MediaType.VIDEO && <span>
                    {card.end_time &&
                      card.start_time &&
                      getFormatedDuration(card.start_time, card.end_time)}
                  </span>}
                  <span className={`movie-name ${card.media_type === MediaType.VIDEO && card.type.toLowerCase() !== "dialogue" && card.end_time ? 'pipe' : ''}`}>
                    {searchpayload.movies?.find((m) => m.name.toLowerCase() == card.movie_name.toLowerCase())?.display_name}
                  </span>
                </Typography>

                <Box className="buttons-group mb-16">
                  {!(card.media_type === MediaType.IMAGE) && (
                    <IconButton
                      aria-label="add to favorites"
                      className="btn-play"
                    >
                      <IconPlaySvg />
                    </IconButton>
                  )}
                  {showWatchlistButton && ((isWatchlistAdded || isInWatchlist(card.id, watchlistData)) ?

                    <IconButton
                      aria-label="add to favorites"
                      className="btn-fav selected"
                      sx={{ cursor: "unset" }}
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <IconFavSvg />
                    </IconButton> :
                    <IconButton
                      aria-label="add to favorites"
                      className="btn-fav"
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        addToWatchlist(card.id);
                      }}
                    >
                      <IconFavSvg />
                    </IconButton>)}

                  {showPlaylistButton && <IconButton
                    aria-label="add to Playlist"
                    className="btn-stroke"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleAddToPlaylistClick();
                    }}
                  >
                    <IconPlayListSvg />
                  </IconButton>}

                  {showTimelineButton && (
                    <IconButton
                      aria-label="timeline"
                      className="btn-timeline"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleClickTimeline(e);
                      }}
                    >
                      <IconTimeLineSvg />
                    </IconButton>
                  )}

                  {showDebugButton && (
                    <IconButton
                      aria-label="debug"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDebugClick();
                      }}
                      className="btn-bug"
                    >
                      <IconBugSvg />
                    </IconButton>
                  )}
                </Box>
                {card.confidence && (
                  <Box
                    className="confidence-level alternative"
                    sx={{
                      color: blackColor,
                      background: getConfidenceColoByConfidence(card.confidence),
                      textTransform: "capitalize",
                    }}
                  >
                    {card.confidence}
                  </Box>
                )}
                {!(card.media_type === MediaType.IMAGE) && (
                  <Box className="badges">
                    {card.categories?.map((t: string, i: any) => (
                      <Button key={t} variant="outlined" size="small">
                        {t}
                      </Button>
                    ))}
                  </Box>
                )}

                {/* {card.similarity ? (
                  <Typography className="h4 title-group mb-12">
                    Similarity:{" "}
                    <strong>{Number(card.similarity).toFixed(4)}</strong>
                  </Typography>
                ):""} */}
                {/* {card.confidence && (
                  <Box
                    className="confidence-level-card-inner"
                    sx={{
                      color: blackColor,
                      background: getConfidenceColoByConfidence(card.confidence),
                      textTransform: "capitalize",
                    }}
                  >
                    {card.confidence}
                  </Box>
                )} */}
                <Typography className="p">
                  <span className="description-text">
                    {card.desc ?? card.content}
                  </span>
                  {(card.desc ?? card.content)?.length > 250 && (
                    <span
                      className="read-more"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleClickMore({ type: cardType, value: card, autoplayVideo: false });
                      }}
                    >
                      {"more"}
                    </span>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
