import React, { Children, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Box, Breadcrumbs, CircularProgress, Divider, Grid, Icon, Link, Paper, Skeleton, Tab, Tabs, TextField, Tooltip } from '@mui/material';
import { DataObject, ExpandMore, NavigateNext, Photo, PictureAsPdf, PlayCircle, Reply, TextSnippet, ThumbsUpDown, Edit, Cancel } from '@mui/icons-material';
import TreeViewComp from "../TreeViewComp";
import { addComment, addVote, getAllUsers, getComments, getDebugById, getStage, getUserVote, getVoteStatics, updateComment } from "../../services/searchService";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import FsLightbox, { SourceType } from "fslightbox-react";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import SendIcon from '@mui/icons-material/Send';
import moment from "moment";
import { toast } from "react-toastify";
import { ISearchPayload } from "../../types/interface";
import { blackColor, imageTypes, jsonTypes, pdfTypes, textTypes, videoTypes, whiteColor } from "../../common/constants";
import { errorToast, getFormatedTime } from "../../common/utils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        background: `linear-gradient(180deg, #222226, #080809)`,
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, padding: '24px 32px', background: `transparent linear-gradient(90deg, rgba(152, 152, 152, .15), rgba(135, 201, 255, 0.15))` }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: '28px',
                        top: '24px',
                        color: (theme) => theme.palette.error.light,
                    }}
                >
                    <Cancel sx={{ color: whiteColor, fontSize: '34px' }} />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface IBreadCrumbItem {
    id: string;
    isActive: boolean;
    type: string;
    start_time: number;
    end_time: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: "0px" }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function DebugDialog({ openDialog, handleOpenDialog, mediaId, mediaType, searchpayload }: { openDialog: boolean; handleOpenDialog: (val: boolean) => void; mediaId: string; mediaType: string; searchpayload: ISearchPayload }) {
    const [isloading, setIsloading] = useState(false);
    const [activeMediaId, setActiveMediaId] = useState<string>(mediaId);
    const [accordionExpanded, setAccordionExpanded] = useState<boolean>(false);
    const [tabvalue, setTabvalue] = useState(0);
    const [debugdata, setDebugdata] = useState<any>();
    const [debugStageData, setDebugStageData] = useState<{ name: string; number: number; methodology: string }>({
        name: '',
        number: 0,
        methodology: ''
    });
    const [debugItemIds, setDebugItemIds] = useState<string[]>([]);
    const [breadcrumblist, setBreadcrumblist] = useState<IBreadCrumbItem[]>([]);
    const [uriType, setUriType] = useState<SourceType | undefined>();

    //For Lightbox
    const [toggler, setToggler] = useState(false);
    const [productIndex, setProductIndex] = useState(1);

    //For comment section
    const [commentText, setCommentText] = useState<string>("");
    const [commentData, setCommentData] = useState<any[]>();
    const [usersData, setusersData] = useState<any[]>();
    const [replyText, setReplyText] = useState<string>('');
    const [isloadingcomment, setIsloadingcomment] = useState<boolean>(false);
    const [editText, setEditText] = useState<string>('');
    const [likeCount, setlikeCount] = useState(0);
    const [dislikeCount, setDislikeCount] = useState(0);

    const [activeBtn, setActiveBtn] = useState("none");

    const handleSendComment = async (event: any) => {
        const reqBody = {
            "user_id": null,
            "parent_id": null,
            "content": commentText,
            "context": {
                "breadcrumb": breadcrumblist.map((v, i) => {
                    return {
                        "id": v.id,
                        "is_active": v.isActive,
                        "type": v.type,
                        "start_time": v.start_time,
                        "end_time": v.end_time
                    }
                }),
                "query": searchpayload.text
            }
        }
        setIsloadingcomment(true);
        addComment(activeMediaId, reqBody)
            .then((response) => {
                setCommentData(commentData?.concat(response));
                setCommentText("");
                setIsloadingcomment(false);
            })
            .catch((error) => {
                setIsloadingcomment(false);
                errorToast(`Cannot add comment`);
            })

    };

    const handleSendReply = async (event: any, comment: any) => {
        const reqBody = {
            "user_id": null,
            "parent_id": comment.id,
            "content": replyText,
            "context": {
                "breadcrumb": comment.context.breadcrumb,
                "query": searchpayload.text
            }
        }
        setIsloadingcomment(true);
        const result = await addComment(activeMediaId, reqBody);
        //setCommentData(commentData?.concat(result))
        await getCommetData(activeMediaId);
        setReplyText("");
        setIsloadingcomment(false);

    };

    const handleEdit = async (event: any, comment: any) => {
        const reqBody = {
            "content": editText
        }
        setIsloadingcomment(true);
        const result = await updateComment(comment.id, reqBody);
        //setCommentData(commentData?.concat(result))
        getCommetData(activeMediaId)
        setEditText("")
        setIsloadingcomment(false);

    };

    const handleLikeClick = async () => {
        const reqBody = {
            "user_id": null,
            "vote": 1,
            "context": {
                "breadcrumb": breadcrumblist.map((v, i) => {
                    return {
                        "id": v.id,
                        "is_active": v.isActive,
                        "type": v.type,
                        "start_time": v.start_time,
                        "end_time": v.end_time
                    }
                }),
                "query": searchpayload.text
            }
        }
        //   setActiveBtn('like');
        addVote(activeMediaId, reqBody)
            .then((response) => {
                setActiveBtn(response?.vote == 1 ? 'like' : response?.vote == 2 ? 'dislike' : 'none')
                getVoteStaticsDetail(activeMediaId)

            })
            .catch((error) => {
                // setActiveBtn('none');
            })
    };

    const handleDisikeClick = async () => {
        const reqBody = {
            "user_id": null,
            "vote": 2,
            "context": {
                "breadcrumb": breadcrumblist.map((v, i) => {
                    return {
                        "id": v.id,
                        "is_active": v.isActive,
                        "type": v.type,
                        "start_time": v.start_time,
                        "end_time": v.end_time
                    }
                }),
                "query": ""
            }
        }
        const result = await addVote(activeMediaId, reqBody);
        setActiveBtn(result?.vote == 1 ? 'like' : result?.vote == 2 ? 'dislike' : 'none')
        getVoteStaticsDetail(activeMediaId)

    };

    const getVoteStaticsDetail = async (id: string) => {
        const result = await getVoteStatics(id);
        setlikeCount(result.total_likes);
        setDislikeCount(result.total_dislikes);
    }
    const getUserVoteDetail = async (id: string) => {
        const result = await getUserVote(id);
        setActiveBtn(result?.vote == 1 ? 'like' : result?.vote == 2 ? 'dislike' : 'none')
    }

    const getCommetData = async (id: string) => {
        const commentsResult = await getComments(id);
        setCommentData(commentsResult)
    }

    const getUsersData = async () => {
        const users = await getAllUsers();
        setusersData(users)


        getCommetData(activeMediaId)
        getUserVoteDetail(activeMediaId)
        getVoteStaticsDetail(activeMediaId)
    }

    const findUserName = (id: any) => {
        const user = usersData?.find(u => u.id == id)
        return user ? user.name : "unknown"
    }


    useEffect(() => {
        //For reverting data in case of failed api call
        const debugdata_temp = { ...debugdata };
        //api call to get debug data
        setDebugdata({});
        setIsloading(true);
        const getDebug = async (id: string) => {
            const result = await getDebugById(id);
            setDebugdata(result ?? {});
            setIsloading(false);
        }
        getDebug(activeMediaId);

        getUsersData()

    }, [activeMediaId]);

    useEffect(() => {
        if (!debugdata || !Object.keys(debugdata).length) {
            return;
        } else if (breadcrumblist.some((blItm) => blItm.id === debugdata.id)) {
            const bl_temp = [...breadcrumblist];
            bl_temp.forEach((blItem: IBreadCrumbItem) => {
                if (blItem.id === debugdata.id) {
                    blItem.isActive = true;
                } else {
                    blItem.isActive = false;
                }
                return blItem;
            });
            setBreadcrumblist([...bl_temp]);
            return;
        }
        let breadcrumblist_temp = [...breadcrumblist];
        if (breadcrumblist_temp.length) {
            const indexOfIsActive = breadcrumblist_temp.findIndex((bltItm) => bltItm.isActive);
            if (indexOfIsActive !== -1 && indexOfIsActive !== breadcrumblist_temp.length - 1) {
                breadcrumblist_temp = breadcrumblist_temp.slice(0, indexOfIsActive + 1);
            }
            breadcrumblist_temp[breadcrumblist_temp.length - 1] = { ...breadcrumblist_temp[breadcrumblist_temp.length - 1], isActive: false };
        }
        const breadcrumb_temp: IBreadCrumbItem = {
            id: debugdata?.id,
            isActive: true,
            type: debugdata?.type,
            start_time: debugdata?.start_time,
            end_time: debugdata?.end_time
        };
        breadcrumblist_temp.push(breadcrumb_temp);
        setBreadcrumblist(breadcrumblist_temp);
    }, [debugdata]);

    // useEffect(() => {
    //     //api call for stage data
    //     const getStageData =async (stage: string) => {
    //         const response = await getStage(stage);
    //         setDebugStageData(response ?? {name: '', number: 0, methodology: ''});
    //     }
    //     // if (accordionExpanded) {
    //         getStageData(debugdata?.stage);
    //     // }
    // }, [debugdata]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabvalue(newValue);
    };

    const handleClose = () => {
        handleOpenDialog(false);
    };
    const geturiType = (uri: string) => {
        const format = uri.split(".")[uri.split(".").length - 1]

        if (imageTypes.some((iItem) => iItem === format.toLowerCase())) {
            return "image"
        } else if (videoTypes.some((vItem) => vItem === format.toLowerCase())) {
            return "video"
        }
    }

    const getIconBasedOnType = ({ uri, format, thumbnail_uri }: { uri: string; format: string; thumbnail_uri: string }) => {
        let iconElement;
        const iconContainer = (icon: any) => {
            iconElement = <>{icon}</>
        };
        if (imageTypes.some((iItem) => iItem === format.toLowerCase())) {
            // setUriType("image")
            iconContainer(
                <img src={thumbnail_uri} alt=' ' style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: "pointer", borderRadius: "10px" }} onClick={() => {
                    setProductIndex(1);
                    setToggler((val) => !val);
                }} />
            );
        } else if (videoTypes.some((vItem) => vItem === format.toLowerCase())) {
            // setUriType("video")
            iconContainer(
                <img src={thumbnail_uri} alt=' ' style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: "pointer", borderRadius: "10px" }} onClick={() => {
                    setProductIndex(1);
                    setToggler((val) => !val);
                }} />
            );
        } else if (pdfTypes.some((pItem) => pItem === format.toLowerCase())) {
            iconContainer(<PictureAsPdf sx={{ fontSize: '120px' }} />);
        } else if (jsonTypes.some((jItem) => jItem === format.toLowerCase())) {
            iconContainer(<DataObject sx={{ fontSize: '120px' }} />);
        } else if (textTypes.some((tItem) => tItem === format.toLowerCase())) {
            iconContainer(<TextSnippet sx={{ fontSize: '120px' }} />);
        } else {
            iconContainer(<></>);
        }
        return iconElement;
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                fullScreen
                sx={{ margin: '20px', zIndex: 1600 }}
                className="bug-dailog"
            >
                {isloading && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "absolute",
                            width: "100%",
                            height: "100vh",
                            zIndex: "9999",
                            background: "rgba(0,0,0,0.1)",
                        }}
                    >
                        <CircularProgress sx={{ color: "rgba(255, 255, 255, 0.7)" }} />
                    </Box>
                )}
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Breadcrumbs
                        separator={<NavigateNext fontSize="small" sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '14px' }} />}
                        aria-label="breadcrumb"
                        maxItems={10}
                    >
                        <Link
                            underline="none"
                            key="1"
                            color="inherit"
                            href="/"
                            onClick={(e) => e.preventDefault()}
                            sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '14px' }}
                        >
                            <span style={{ textTransform: 'capitalize' }}>{mediaType + (mediaType?.toLowerCase() == "stills"?'' : 's')}</span>
                        </Link>,
                        <Link
                            underline="none"
                            key="2"
                            color="inherit"
                            href="/"
                            onClick={(e) => e.preventDefault()}
                            sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '14px' }}
                        >
                            Debug
                        </Link>
                        {breadcrumblist.map((bItem: IBreadCrumbItem, index) => {
                            return <Tooltip key={index + 2} title={bItem?.id} placement="bottom-start" arrow>
                                <Link
                                    underline="none"
                                    // key={index+2}
                                    color="inherit"
                                    href="/"
                                    onClick={(e) => { e.preventDefault(); setActiveMediaId(bItem.id) }}
                                    sx={{ borderBottom: bItem.isActive ? '2px solid #87C9FF' : 'none', background: bItem.isActive ? `transparent linear-gradient(116deg, rgba(55, 63, 69, 1), rgba(70, 77, 83, 1))` : 'none', borderRadius: '2px', padding: '4px 15px', display: 'inline-block' }}
                                >
                                    <Typography sx={{ fontSize: '14px', color: "rgba(255, 255, 255, 0.7)" }}>{bItem?.type}</Typography>
                                    <Typography sx={{ fontSize: '10px', color: "rgba(255, 255, 255, 0.7)" }}>{getFormatedTime(Number(bItem?.start_time))} to {getFormatedTime(Number(bItem?.end_time))}</Typography>
                                </Link>
                            </Tooltip>
                        }
                        )}
                    </Breadcrumbs>
                </BootstrapDialogTitle>

                <DialogContent dividers sx={{ padding: '24px 32px !important' }}>

                    <Box>
                        <Box sx={{ display: 'flex', marginBottom: '32px' }}>
                            <Box sx={{ width: '70%', paddingRight: '2px' }}>
                                <Box >

                                    <Box sx={{ display: 'flex' }}>
                                        <Typography component="span" sx={{ color: '#6e737d', fontSize: '14px' }}>Stage</Typography>
                                        <Typography component="span" sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '14px', marginLeft: '10px' }}>{debugdata?.stage?.name}</Typography>

                                        <Typography component="span" sx={{ color: '#6e737d', fontSize: '14px', fontWeight: 'bold', marginLeft: '50px' }}>Number</Typography>
                                        <Typography component="span" sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '14px', fontWeight: 'bold', marginLeft: '10px' }}>{debugdata?.stage?.number}</Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex' }} >
                                        <Typography sx={{ color: '#989898', fontSize: '14px', marginTop: '8px' }}>Methodology</Typography>
                                        <Typography sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '14px', marginTop: '8px', marginLeft: '10px', }}>
                                            {debugdata?.stage?.methodology}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', marginTop: '28px' }}>
                                    <Box
                                        sx={{
                                            width: '30%',
                                            // background: '#E2E2E2', 
                                            display: 'flex', alignItems: 'flex-start', justifyContent: 'left'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                minWidth: '233px', minHeight: "172px",
                                                // background: '#E2E2E2', 
                                                display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: "4px"
                                            }}
                                        >
                                            {getIconBasedOnType({ uri: debugdata?.uri, format: debugdata?.uri?.split(".")[debugdata?.uri?.split(".").length - 1] || '', thumbnail_uri: debugdata?.thumbnail_uri })}
                                        </Box>
                                    </Box>
                                    <Box sx={{ marginLeft: '24px', width: '80%' }}>
                                        <Grid container sx={{ pt: 1 }}>
                                            <Grid item sm={8} sx={{ display: 'flex' }}>

                                                <Typography sx={{ color: '#6e737d', fontSize: '12px', marginRight: '10px' }}>ID</Typography>
                                                <Typography sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '12px' }}>{debugdata?.id}</Typography>

                                            </Grid>
                                            <Grid item sm={4} sx={{ display: 'flex' }}>
                                                <Typography sx={{ color: '#6e737d', fontSize: '12px', marginRight: '10px' }}>Version</Typography>
                                                <Typography sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '12px' }}>{debugdata?.version}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ pt: 1 }}>
                                            <Grid item sm={8} sx={{ display: 'flex' }}>
                                                <Typography sx={{ color: '#6e737d', fontSize: '12px', marginRight: '10px' }}>Type</Typography>
                                                <Typography sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '12px' }}>{debugdata?.type}</Typography>
                                            </Grid>

                                            <Grid item sm={4} sx={{ display: 'flex' }}>
                                                <Typography sx={{ color: '#6e737d', fontSize: '12px', marginRight: '10px' }}>Time</Typography>
                                                <Typography sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '12px' }}>{getFormatedTime(Number(debugdata?.start_time))} to {getFormatedTime(Number(debugdata?.end_time))}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ pt: 1 }}>
                                            <Grid item sm={12} sx={{ display: 'flex' }}>
                                                <Typography sx={{ color: '#6e737d', fontSize: '12px', marginRight: '10px' }}>URI</Typography>
                                                <Typography sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '12px' }}>{debugdata?.uri?.split("/")[debugdata?.uri?.split("/").length - 1]}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ pt: 1 }}>
                                            <Grid item sm={12} sx={{ display: 'flex' }}>
                                                <Typography sx={{ color: '#6e737d', fontSize: '12px', marginRight: '10px' }}>Movie Name</Typography>
                                                <Typography sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '12px' }}>{searchpayload.movies?.find((m) => m.name.toLowerCase() == debugdata?.movie_name?.toLowerCase())?.display_name}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ pt: 1 }}>
                                            <Grid item sm={12} sx={{ display: 'flex' }}>
                                                <Typography sx={{ color: '#6e737d', fontSize: '12px', marginRight: '10px' }}>Characters</Typography>
                                                <Typography sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '12px' }}>
                                                    Visual: {debugdata?.characters?.VISUAL ? debugdata?.characters?.VISUAL.join(", ") : "No Character"}
                                                    <br />
                                                    Speaker: {debugdata?.characters?.SPEAKER ? debugdata?.characters?.SPEAKER?.join(", ") : "No Character"}
                                                </Typography>
                                            </Grid>
                                        </Grid>


                                    </Box>
                                </Box>
                                <Box sx={{ marginTop: "16px" }}>
                                    <Typography variant='h5' sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '16px' }}>{debugdata?.title}</Typography>
                                    <Typography variant="body1" component="pre" sx={{ color: '#6e737d', fontSize: '14px', whiteSpace: 'pre-wrap', textAlign: "justify", marginRight: "8px", marginTop: '14px', height: '171px', overflow: 'auto', paddingRight: '15px', scrollbarWidth: 'thin' }}>{debugdata?.content}</Typography>
                                </Box>
                                {/* <Divider sx={{borderColor: '#9C9C9C', marginTop: '40px', marginBottom: '16px'}} /> */}
                                {/* <Box sx={{display: 'flex', justifyContent: 'center', borderRadius: '4px', background: 'rgba(55, 55, 55, 0.05)', py: 1, color: '#373737', fontSize: '16px', fontWeight: 'bold', marginTop: '24px'}}>URI: {debugdata?.uri?.split("/")[debugdata?.uri?.split("/").length - 1]}</Box> */}


                            </Box>
                            <Box sx={{ width: '30%' }}>
                                <Paper
                                    sx={{
                                        p: 0,
                                        m: 0,
                                        background: 'transparent',
                                        border: '0.5px solid #9C9C9C'
                                    }}
                                >
                                    <Box
                                        // maxWidth={"lg"}
                                        sx={{
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            padding: "0px",
                                            minHeight: '491px'
                                        }}
                                    >
                                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                            <Tabs
                                                value={tabvalue}
                                                onChange={handleTabChange}
                                                variant="fullWidth"
                                                aria-label="search-result-tabs"
                                                sx={[(theme) => ({ '.MuiTab-root': { color: "rgba(255, 255, 255, 0.7)", borderBottom: '0.5px solid #9C9C9C', '&.Mui-selected': { color: "rgba(255, 255, 255, 0.7)" } }, '.MuiTabs-indicator': { backgroundColor: 'white' }, '.MuiTabs-flexContainer': { alignItems: 'center' } })]}
                                            >
                                                <Tab label={<span><img src="images/input_oracle.svg" style={{ verticalAlign: 'middle' }} /> Input Oracle</span>} {...a11yProps(0)} />
                                                <Tab label={<span><img src="images/embeding.svg" style={{ verticalAlign: 'middle' }} /> Embeding</span>} {...a11yProps(1)} />
                                            </Tabs>
                                        </Box>
                                        <CustomTabPanel value={tabvalue} index={0}>
                                            <Box sx={{ p: 2 }}>
                                                {(debugdata?.input_oracles && Object.keys(debugdata.input_oracles).length) ? <TreeViewComp
                                                    data={debugdata?.input_oracles && Object.keys(debugdata.input_oracles).length && Object.keys(debugdata.input_oracles).map((key, index) => {
                                                        return {
                                                            id: `${index}`,
                                                            name: key,
                                                            children: (debugdata.input_oracles[key] || []).map((cItem: string, cid: number) => {
                                                                return {
                                                                    id: `${(Object.keys(debugdata.input_oracles).length * 1000) + (index * 100) + cid}`,
                                                                    name: cItem,
                                                                }
                                                            })
                                                        };
                                                    })}
                                                    handleClickTreeItem={(id) => setActiveMediaId(id)}
                                                /> :
                                                    <Typography sx={{ fontSize: '13px', color: '#9C9C9C' }}>No input oracle exists</Typography>
                                                }
                                            </Box>
                                        </CustomTabPanel>
                                        <CustomTabPanel value={tabvalue} index={1}>
                                            <Paper
                                                sx={{
                                                    p: 2,
                                                    m: 0,
                                                    background: 'transparent'
                                                }}
                                            >
                                                <Typography sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '14px', fontWeight: 'bold' }}>Content</Typography>
                                                <Typography sx={{ color: '#6e737d', fontSize: '14px', mt: 1 }}>{debugdata?.embedding && debugdata.embedding.content}</Typography>
                                            </Paper>
                                            <Paper
                                                sx={{
                                                    p: 2,
                                                    m: 0,
                                                    mt: 2,
                                                    background: 'transparent'
                                                }}
                                            >
                                                <Typography sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '14px', fontWeight: 'bold', mb: 2 }}>Embedding Input Oracle</Typography>
                                                {(debugdata?.embedding && debugdata.embedding?.input_oracles && Object.keys(debugdata.embedding.input_oracles).length) ? <TreeViewComp
                                                    data={debugdata?.embedding && debugdata.embedding?.input_oracles && Object.keys(debugdata.embedding.input_oracles).length && Object.keys(debugdata.embedding.input_oracles).filter((dItem) => debugdata.embedding.input_oracles[dItem].length).map((key, index) => {
                                                        return {
                                                            id: `${index}`,
                                                            name: key,
                                                            children: (debugdata.embedding.input_oracles[key] || []).map((cItem: string, cid: number) => {
                                                                return {
                                                                    id: `${(Object.keys(debugdata.embedding.input_oracles).length * 1000) + (index * 100) + cid}`,
                                                                    name: cItem,
                                                                }
                                                            })
                                                        };
                                                    })}
                                                    handleClickTreeItem={(id) => setActiveMediaId(id)}
                                                /> :
                                                    <Typography sx={{ fontSize: '13px', color: '#6e737d' }}>No embedding input oracle exists</Typography>
                                                }

                                            </Paper>

                                        </CustomTabPanel>
                                    </Box>
                                </Paper>
                            </Box>
                        </Box>

                        <Box>

                            <Paper
                                sx={{
                                    p: 0,
                                    m: 0,
                                    marginTop: '20px',
                                    background: 'transparent',
                                    border: '0.5px solid #9C9C9C'
                                }}

                            ><Paper
                                sx={{
                                    p: 0,
                                    m: 0,
                                    background: 'transparent',
                                    color: 'white',
                                    borderBottom: '0.5px solid #9C9C9C',
                                    padding: '20px',
                                    fontSize: '16px'
                                }}
                            >REVIEW</Paper>
                                <Box sx={{ padding: '20px' }}>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            marginBottom: '30px',
                                            alignItems: 'flex-start'
                                        }}
                                    >

                                        <Box sx={{
                                            display: 'flex', width: '15%', flexDirection: 'column'
                                        }}>
                                            <Box sx={{
                                                display: 'flex', width: '100%',
                                                gap: '14.5px',
                                                color: "rgba(255, 255, 255, 0.7)",
                                                marginBottom: '19px'
                                            }}>
                                                {activeBtn != 'like' ? <ThumbUpOffAltIcon sx={{ cursor: 'pointer', color: "rgba(255, 255, 255, 0.7)" }} color="primary" onClick={handleLikeClick}></ThumbUpOffAltIcon>
                                                    :
                                                    <ThumbUpIcon sx={{ cursor: 'pointer', color: "rgba(255, 255, 255, 0.7)" }} color="primary"></ThumbUpIcon>
                                                }
                                                {likeCount} Likes
                                            </Box >
                                            <Box sx={{
                                                display: 'flex', width: '100%',
                                                gap: '14.5px',
                                                color: "rgba(255, 255, 255, 0.7)"
                                            }}>
                                                {activeBtn != 'dislike' ? <ThumbDownOffAltIcon sx={{ cursor: 'pointer', color: "rgba(255, 255, 255, 0.7)", transform: 'scaleX(-1)' }} color="primary" onClick={handleDisikeClick}></ThumbDownOffAltIcon>
                                                    :
                                                    <ThumbDownIcon sx={{ cursor: 'pointer', color: "rgba(255, 255, 255, 0.7)", transform: 'scaleX(-1)' }} color="primary" ></ThumbDownIcon>
                                                }
                                                {dislikeCount} Dislikes
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', width: '85%', flexDirection: 'column', gap: '10px' }}>
                                            {!isloadingcomment ? <>
                                                <Typography component={'div'} sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '14px' }}>
                                                    Comment
                                                </Typography>
                                                <Box sx={{ display: 'flex', width: "100%", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >

                                                    <TextField
                                                        id="outlined-multiline-flexible"
                                                        variant="standard"
                                                        sx={{
                                                            width: '100%',
                                                            color: "rgba(255, 255, 255, 0.7)",
                                                            borderBottom: '0.5px solid #9C9C9C',
                                                            fontSize: '16px'
                                                        }}
                                                        InputProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
                                                        multiline
                                                        value={commentText}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                            setCommentText(event.target.value)
                                                        }
                                                        onKeyDown={(event) => {
                                                            if (event.key === 'Enter' && !event.shiftKey) {
                                                                event.preventDefault()
                                                            };
                                                            event.key === 'Enter' && !event.shiftKey && handleSendComment(event)
                                                        }}
                                                        placeholder="Enter you comment here ..."
                                                    />
                                                    <SendIcon
                                                        sx={{
                                                            height: '13px',
                                                            width: '13px',
                                                            color: "rgba(255, 255, 255, 0.7)",
                                                            cursor: 'pointer',
                                                            marginLeft: '-13px'
                                                        }}
                                                        onClick={handleSendComment}></SendIcon>

                                                </Box>


                                            </>
                                                : <>
                                                    <Skeleton variant="rounded" width={'100%'} height={60} />
                                                    <Skeleton variant="rounded" width={'100%'} height={30} />
                                                    <Skeleton variant="rounded" width={'calc(100% - 40px)'} height={15} sx={{ marginLeft: '40px' }} />
                                                    <Skeleton variant="rounded" width={'100%'} height={30} />
                                                    <Skeleton variant="rounded" width={'calc(100% - 40px)'} height={15} sx={{ marginLeft: '40px' }} />
                                                </>
                                            }</Box>


                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        {
                                            commentData?.map((v, i) => {
                                                return <Box key={v.id} sx={{
                                                    width: '100%'
                                                }}>
                                                    <div>
                                                        <Divider />
                                                        <span style={{
                                                            fontSize: '16px',
                                                            color: "rgba(255, 255, 255, 0.7)",
                                                            fontWeight: 'bold',
                                                            marginRight: '10px'
                                                        }}>

                                                            {findUserName(v.user_id)}

                                                        </span>
                                                        <span style={{
                                                            fontSize: '12px',
                                                            color: '#6e737d'
                                                        }}>({moment(v.created_on).fromNow()})</span>
                                                    </div>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{
                                                            fontSize: '14px', color: "rgba(255, 255, 255, 0.7)"
                                                        }}>
                                                            {v.content}
                                                        </div>

                                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                                            <Tooltip title={`Reply`} placement="bottom" arrow>
                                                                <Reply
                                                                    titleAccess="Reply"
                                                                    fontSize="small"
                                                                    sx={{ cursor: 'pointer', color: "rgba(255, 255, 255, 0.7)" }}
                                                                    onClick={() => {
                                                                        let cd = [...commentData];
                                                                        cd[i].showEdit = false;
                                                                        cd[i].showReply = true;
                                                                        setCommentData(cd);
                                                                    }} />
                                                            </Tooltip>

                                                            <Tooltip title={`Edit Comment`} placement="bottom" arrow>
                                                                <Edit
                                                                    titleAccess="Edit"
                                                                    fontSize="small"
                                                                    sx={{ cursor: 'pointer', color: "rgba(255, 255, 255, 0.7)" }}
                                                                    onClick={() => {
                                                                        let cd = [...commentData];
                                                                        cd[i].showReply = false;
                                                                        cd[i].showEdit = true;
                                                                        setCommentData(cd);
                                                                        setEditText(cd[i].content)
                                                                    }} />
                                                            </Tooltip>
                                                        </Box>

                                                    </Box>
                                                    <Box sx={{ fontSize: '12px', color: '#6e737d' }}>Context:  &nbsp;
                                                        <Box>
                                                            <Breadcrumbs
                                                                sx={[{ display: 'inline-block', color: '#6e737d' }, (theme) => ({ '& .css-1wuw8dw-MuiBreadcrumbs-separator': { marginLeft: 0, marginRight: 0 } })]}
                                                                separator={<NavigateNext fontSize="inherit" sx={{ color: '#6e737d' }} />}
                                                                aria-label="breadcrumb"
                                                                maxItems={10}
                                                            >
                                                                <Link
                                                                    underline="none"
                                                                    key="1"
                                                                    color="inherit"
                                                                    href="/"
                                                                    onClick={(e) => e.preventDefault()}
                                                                    sx={{ color: '#6e737d', fontSize: '12px' }}
                                                                >
                                                                    <span style={{ textTransform: 'capitalize' }}>{mediaType + (mediaType?.toLowerCase() == "stills"?'' : 's')}</span>
                                                                </Link>,
                                                                <Link
                                                                    underline="none"
                                                                    key="2"
                                                                    color="inherit"
                                                                    href="/"
                                                                    onClick={(e) => e.preventDefault()}
                                                                    sx={{ color: '#6e737d', fontSize: '12px' }}
                                                                >
                                                                    Debug
                                                                </Link>
                                                                {v.context.breadcrumb.map((bItem: any, index: number) => {
                                                                    return <Tooltip key={index + 2} title={bItem?.id} placement="bottom-start" arrow>
                                                                        <Link
                                                                            underline="none"
                                                                            // key={index+2}
                                                                            color="inherit"
                                                                            href="/"
                                                                            onClick={(e) => { e.preventDefault() }}
                                                                            sx={{ border: bItem.is_active ? 'none' : 'none', borderRadius: '5px', padding: 0, display: 'inline-block' }}
                                                                        >
                                                                            <Typography sx={{ fontSize: '12px', color: '#6e737d' }}>{bItem?.type}</Typography>
                                                                        </Link>
                                                                    </Tooltip>
                                                                }
                                                                )}
                                                            </Breadcrumbs>
                                                        </Box>
                                                        <Box sx={{ fontSize: '16px', color: '#6e737d' }}>
                                                            search text: {v.context.query}
                                                        </Box>
                                                    </Box>
                                                    {v.showReply && <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}> <TextField
                                                            id="outlined-singleline-reply"
                                                            variant="standard"
                                                            sx={{
                                                                width: '100%',
                                                                mt: 1,
                                                                borderBottom: '1px solid #9C9C9C'
                                                            }}
                                                            InputProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
                                                            size="small"
                                                            value={replyText}
                                                            multiline
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                                setReplyText(event.target.value)
                                                            }
                                                            onKeyDown={(event) => {
                                                                if (event.key === 'Enter' && !event.shiftKey) {
                                                                    event.preventDefault()
                                                                };
                                                                event.key === 'Enter' && !event.shiftKey && handleSendReply(event, v)
                                                            }}
                                                            placeholder="Reply..."
                                                        />
                                                        <CloseIcon sx={{
                                                            cursor: 'pointer',
                                                            color: "rgba(255, 255, 255, 0.7)"
                                                        }} onClick={() => {
                                                            v.showReply = false;
                                                            v.showEdit = false;
                                                            let cd = [...commentData];
                                                            setCommentData(cd)
                                                        }}></CloseIcon>
                                                    </Box>
                                                    }

                                                    {v.showEdit && <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}> <TextField
                                                            id="outlined-singleline-edit"
                                                            variant="standard"
                                                            sx={{
                                                                width: '100%',
                                                                mt: 1,
                                                                borderBottom: '1px solid #9C9C9C'
                                                            }}
                                                            size="small"
                                                            value={editText}
                                                            multiline
                                                            InputProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                                setEditText(event.target.value)
                                                            }
                                                            onKeyDown={(event) => {
                                                                if (event.key === 'Enter' && !event.shiftKey) {
                                                                    event.preventDefault()
                                                                };
                                                                event.key === 'Enter' && !event.shiftKey && handleEdit(event, v)
                                                            }}
                                                            placeholder="Edit..."
                                                        />
                                                        <CloseIcon sx={{
                                                            cursor: 'pointer',
                                                            color: "rgba(255, 255, 255, 0.7)"
                                                        }} onClick={() => {
                                                            console.log("clicked")
                                                            v.showReply = false;
                                                            v.showEdit = false;
                                                            let cd = [...commentData];
                                                            setCommentData(cd)
                                                        }}></CloseIcon>
                                                    </Box>
                                                    }

                                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                        <a style={{ marginRight: '10px', cursor: 'pointer', color: "rgba(255, 255, 255, 0.7)", fontSize: '14px' }}>
                                                            {v.total_replies == 0 ? 'No' : v.total_replies} replies
                                                        </a>
                                                    </Box>
                                                    {
                                                        (v.replies as any[])?.map((r, i) => {
                                                            return <Box key={r.id} sx={{
                                                                padding: '10px',
                                                                margin: '5px 0',
                                                                width: '100%',
                                                                border: '1px solid #cfcfcf',
                                                                borderRadius: '5px',
                                                                background: '#3A3A3A'
                                                            }}>

                                                                <div>
                                                                    <span style={{
                                                                        fontSize: '12px',
                                                                        color: '#6e737d'
                                                                    }}>{moment(r.created_on).fromNow()}</span>
                                                                </div>
                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <div style={{
                                                                        fontSize: '14px',
                                                                        color: "rgba(255, 255, 255, 0.7)"
                                                                    }}>
                                                                        {r.content}
                                                                    </div>
                                                                </Box>
                                                            </Box>
                                                        })
                                                    }


                                                </Box>
                                            })
                                        }
                                    </Box>
                                </Box>
                            </Paper>
                        </Box>
                    </Box>
                </DialogContent>
            </BootstrapDialog>

            <FsLightbox
                toggler={toggler}
                sources={[debugdata?.uri]}
                slide={productIndex}
                type={geturiType(debugdata?.uri || "")}
            />

        </div>
    );
}