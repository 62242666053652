import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import { Box, Button, Typography } from "@mui/material";
import pageNotFoundIcon from "../../assets/page_not_found-icon.svg";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <Box className="page-not-found-container">
      <Box className="page-not-found-inner">
        <Box>
          <img src={pageNotFoundIcon} alt="" />
        </Box>
        <Box>
          <Typography className="page-not-found-title">
            Page not found
          </Typography>
        </Box>
        <Box>
          <Typography className="page-not-found-subtitle">
            The page you are looking for either doesn't exist or an other error{" "}
            <br /> occurred. You can go back to home page
          </Typography>
        </Box>
        <Box className="page-not-found-back-button-container">
          <Button
            variant="contained"
            className="page-not-found-back-button"
            onClick={() => {
              navigate("/");
            }}
          >
            Go to Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
