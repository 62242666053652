import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {createSearchParams, useNavigate, useOutletContext} from 'react-router-dom';

import { toast } from "react-toastify";
import { Accordion, AccordionDetails, AccordionSummary, ListItem, Box, Button, Chip, CircularProgress, Drawer, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, Tab, Tabs, TextField, Toolbar, Typography, styled, Skeleton } from "@mui/material";
import { blackColor, whiteColor } from "../../common/constants";
import { Favorite, PlayArrow, QueueMusic, SearchOutlined } from "@mui/icons-material";
import { createUserPlaylist, getPlaylistItems, getPlaylistItemsForSearch, getPlaylistsForUser, get_media_count, search } from "../../services/searchService";
import FsLightbox from "fslightbox-react";
import CardDetailDialog from "../../components/CardDetailDialog";
import PlaylistDialog from "../../components/PlaylistDialog";
import MediaCard from "../../components/MediaCard";
import { ViewBy } from "../../types/interface";
import TabContent from "../../components/SearchResultVideoTab";
import ImageTabContent from "../../components/SearchResultImageTab";
import VideoTabContent from "../../components/SearchResultVideoTab";
import iconPlayList from '../../assets/iconPlayList.svg'
import iconPlayListSmall from '../../assets/iconPlayListSmall.svg'

function otherProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >

            <Box sx={{ padding: "0px" }}>
                {children}
            </Box>

        </div>
    );
}

  
export default function Playlists() {
    const [commonContext] = useOutletContext<any | null>();
    const {
        searchpayload, 
        mediaCountResult,
        showMediaCountResult,
    } = commonContext;

    const [isLoadedData, setIsLoadedData] = useState<boolean>(false);

    //For storing playlists
    const [playlists, setPlaylists] = useState<any[]>([]);

    //For storing active playlist data
    const [playlistData, setPlaylistData] = useState<any>({});
    //For storing active playlist
    const [selectedPlaylist, setSelectedPlaylist] = useState<any>({});
    //For storing selected item for timeline
    const [selectedItemForTimeline, setSelectedItemForTimeline] = useState<null | any>(null);

    const [isloading, setIsloading] = useState(false);

    //To control between timeline and list view
    const [viewBy, setViewBy] = useState<ViewBy>(ViewBy.List);
    
    //For CardDetailDialog
    const [openCardDetailDialog, setOpenCardDetailDialog] = useState<boolean>(false);
    const [selectedCardDetailDialogData, setselectedCardDetailDialogData] = useState<{ type: string; value: any; autoplayVideo: boolean; }>({ type: '', value: {}, autoplayVideo: false });

    //For Lightbox
    const [toggler, setToggler] = useState<boolean>(false);
    const [productIndex, setProductIndex] = useState<number>(1);
    const [fullScreenMediaSource, setFullScreenMediaSource] = useState<string>('');

    const navigate = useNavigate();

    //For fetching playlists
    const handleGetPlaylists = () => {
        getPlaylistsForUser()
            .then((data) => {
                if (data && data.length > 0) {
                    let defaultPlaylist = data.find((dItem:any) => dItem.name?.toLowerCase() === "watchlist");
                    setPlaylists(data);
                    //For making watchlist as default playlist 
                    if (defaultPlaylist) {
                        setSelectedPlaylist(defaultPlaylist)
                        handleUpdatePlaylistData(defaultPlaylist)                            
                    } else {
                        setSelectedPlaylist(data[0])
                        handleUpdatePlaylistData(data[0])    
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    //For updating active playlist data
    const handleUpdatePlaylistData = (item: any) => {
        setIsLoadedData(false);
        getPlaylistItemsForSearch(item.id)
            .then((response) => {
                setPlaylistData(response);
                setIsLoadedData(true);
            })
            .catch((err) => {
                console.log(err);
            })
            
    }

    useEffect(() => {
        handleGetPlaylists()
    }, []);

    const handlePlaylistSelect = (item: any) => {
        setSelectedPlaylist(item)
        handleUpdatePlaylistData(item)

    }

    const handleOpenCloseCardDetailDialog = (val: boolean) => {
        setOpenCardDetailDialog(val);
    };

    const handleClickPlay = () => {
        setToggler(val => !val);
        setFullScreenMediaSource(selectedCardDetailDialogData.value.url);
    }

    const handleClickMore = (dataObj: { type: string; value: any; autoplayVideo: boolean; }) => {
        setselectedCardDetailDialogData(dataObj);
        setOpenCardDetailDialog(true);
    }

    //For playlist dialog
    const [openPlaylistDialog, setOpenPlaylistDialog] = useState<boolean>(false);
    const [selectedPlaylistDialog, setSelectedPlaylistDialog] = useState<{ type: string; value: any }>({ type: '', value: {} });

    const handleOpenPlaylistDialog = (val: boolean) => {
        setOpenPlaylistDialog(val);
    };

    const handleCreatePlaylist = (val: string) => {
        getPlaylistsForUser().then((res) => {
            console.log(res)
            setPlaylists(res)
            setSelectedPlaylist({id: '', name: val})
            handleUpdatePlaylistData(val)

        })
    };

    const handleAddToPlaylist = (dataObj: { type: string; value: any }) => {
        setSelectedPlaylistDialog(dataObj);
        setOpenPlaylistDialog(true);
    }

    const [tabvalue, setTabvalue] = useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedItemForTimeline(null);
        setTabvalue(newValue);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }} className="">
            <CssBaseline />
            {isloading && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "fixed",
                        width: "100%",
                        height: "100vh",
                        zIndex: "9999",
                        background: "rgba(0,0,0,0.75)",
                    }}
                >
                    <CircularProgress sx={{ color: whiteColor }} />
                </Box>
            )}
            
            <Box className="page-min-height-1" sx={{ flexGrow: 1, marginLeft: 'auto', marginRight: 'auto', width: '100%'}}>
                <Box className="playlist-tab">
                    <Box className="container" sx={{ 
                        display: 'flex', 
                        alignItems: {lg:"center", md:"center", sm:"flex-start", xs:"flex-start"}, 
                        rowGap:{lg:0, md:0, sm:1.5, xs:1.5}, 
                        justifyContent: 'space-between', 
                        flexDirection:{lg:'row', md:'row', sm:'column', xs:'column'} 
                        }}>
                        <Box sx={{ display: 'flex', columnGap:"11px", alignItems:"center" }}>
                            <img     src={iconPlayList} alt="" />
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.7)", fontSize: '20px', fontWeight:"400" }}>Playlists</Typography>
                            <div className="playlist-add">
                                <Button
                                    sx={{ "& .MuiButton-startIcon": { marginRight: "5px", marginLeft: "0px" }}} 
                                    variant="outlined"
                                    startIcon={<img src={iconPlayListSmall} alt="" />}
                                    // startIcon={<img src="images/playlist_add.svg" />}
                                    onClick={(event) => handleAddToPlaylist({ type: 'default', value: {} })}
                                >
                                    Add New Play List
                                </Button>
                            </div>

                        </Box>
                        <Box sx={{}} className="playlist-tabs-1">
                            {
                                playlists?.map((item, index) => (
                                    <ListItem key={index}
                                    >
                                        <Button
                                            type="button"
                                            sx={item.name == selectedPlaylist?.name ? { borderRadius: "32px", color: `${blackColor} !important`, background: `${whiteColor} !important` } : { borderRadius: "32px", color: whiteColor, border: `1px solid ${whiteColor}`, background: 'transparent', ':hover': { color: 'black' } }}
                                            variant="contained"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setSelectedItemForTimeline(null);
                                                handlePlaylistSelect(item);
                                            }}
                                        >
                                            {item.name}
                                        </Button>
                                    </ListItem>
                                )
                                )
                            }

                        </Box>
                    </Box>
                </Box>

                <Box className="playlist-tab-2">
                <Box className="container"  sx={{ 
                            display: 'flex', 
                            alignItems:{lg:"center", md:"center", sm:"flex-start", xs:"flex-start"},
                            justifyContent: 'space-between', 
                            flexDirection:{lg:"row", md:"row", sm:"column", xs:"column"},
                            rowGap:{lg:"0px", md:"0", sm:"15px", xs:"15px"}
                            }}>
                    <Box sx={{
                                display: 'flex', 
                                alignItems:{lg:"center", md:"center", sm:"center", xs:"flex-start"},
                                justifyContent:{lg:"flex-start", md:"flex-start", sm:"space-between", xs:"space-between"},
                                width:{lg:"auto", md:"auto", sm:"100%", xs:"100%"},
                                flexDirection:{lg:"row", md:"row", sm:"row", xs:"column"},
                                rowGap:{lg:"0px", md:"0", sm:"0", xs:"15px"}
                        }}>
                            <Tabs
                                value={tabvalue}
                                onChange={handleTabChange}
                                // variant="fullWidth"
                                aria-label="search-result-tabs"

                                className=""
                            >
                                <Tab label={<span>Videos <em>({playlistData?.video?.total || '0'})</em></span>} {...otherProps(0)} />
                                <Tab label={<span>Dialogue <em>({playlistData?.dialogue?.total || '0'})</em></span>} {...otherProps(1)} />
                                <Tab label={<span>Stills <em>({playlistData?.image?.total || '0'})</em></span>} {...otherProps(2)} />
                            </Tabs>
                            <Box sx={{display: 'flex', paddingLeft: {lg:"60px", md:"60px", sm:"0px", xs:"0px"}}}>
                                <Box sx={{fontSize: '16px', color: '#989898', display:'flex', alignItems:'center', columnGap:'12px'}}><b>Timeline</b>
                                    <Switch
                                        checked={viewBy === ViewBy.Clip}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setViewBy(event.target.checked ? ViewBy.Clip : ViewBy.List);
                                            if (!event.target.checked) {
                                                setSelectedItemForTimeline(null);                                                
                                            }
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        className="mui-switch"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <CustomTabPanel value={tabvalue} index={0}>
                    {isLoadedData ? (
                        playlistData?.video?.videos?.length > 0 ? (
                            <Box className="container" sx={{ marginBottom: 8 }}>
                                <VideoTabContent 
                                    cardData={playlistData.video.videos}
                                    item={playlistData.video}
                                    tabType="video"
                                    viewBy={viewBy === 0 ? "list" : "clip"}
                                    setViewBy={(view) => setViewBy(view === "list" ? 0 : 1)}
                                    searchpayload={searchpayload}
                                    handleChangeInputs={() => {}}
                                    showMediaCountResult={showMediaCountResult}
                                    mediaCountResult={mediaCountResult}
                                    searchedInputForResult=""
                                    isActiveTab={tabvalue === 0}
                                    showTimelineButtonInListViewCard={true}
                                    showWatchlistButtonInListViewCard={false}
                                    showPlaylistButtonInListViewCard={false}
                                    showDebugButtonInListViewCard={false}
                                    withoutSearch={true}
                                    selectedItemForTimeline={selectedItemForTimeline}
                                    handleClickTimeline={(card, index) => {
                                        setSelectedItemForTimeline(card);
                                        setViewBy(ViewBy.Clip);
                                    }}
                                    canAddToPlaylist={true}
                                />
                            </Box>
                        ) : (
                            <Box className="no-data-message" >
                                <Typography variant="h6">There are currently no videos in playlist. </Typography>
                            </Box>)
                    ) : (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', padding: '20px', gap: '40px', marginTop: 3 }} className="container">
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                        </Box>
                    )}
                </CustomTabPanel>
                <CustomTabPanel value={tabvalue} index={1}>
                    {isLoadedData ? (
                        playlistData?.dialogue?.videos?.length > 0 ? (
                            <Box className="container" sx={{ marginBottom: 8}}>
                                <VideoTabContent 
                                    cardData={playlistData.dialogue.videos}
                                    item={playlistData.dialogue}
                                    tabType="dialogue"
                                    viewBy={viewBy === 0 ? "list" : "clip"}
                                    setViewBy={(view) => setViewBy(view === "list" ? 0 : 1)}
                                    searchpayload={searchpayload}
                                    handleChangeInputs={() => {}}
                                    showMediaCountResult={showMediaCountResult}
                                    mediaCountResult={mediaCountResult}
                                    searchedInputForResult=""
                                    isActiveTab={tabvalue === 1}
                                    showTimelineButtonInListViewCard={true}
                                    showWatchlistButtonInListViewCard={false}
                                    showPlaylistButtonInListViewCard={false}
                                    showDebugButtonInListViewCard={false}
                                    withoutSearch={true}
                                    selectedItemForTimeline={selectedItemForTimeline}
                                    handleClickTimeline={(card, index) => {
                                        setSelectedItemForTimeline(card);
                                        setViewBy(ViewBy.Clip);
                                    }}
                                    canAddToPlaylist={true}
                                />
                            </Box>
                        ) : (
                            <Box className="no-data-message" >
                                <Typography variant="h6">There are currently no dialogues in playlist. </Typography>
                            </Box>)
                    ) : (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', padding: '20px', gap: '40px', marginTop: 3 }} className="container">
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                        </Box>
                    )}
                </CustomTabPanel>

                <CustomTabPanel value={tabvalue} index={2}>
                    {isLoadedData ? (
                        playlistData?.image?.images?.length > 0 ? (
                            <Box className="container" sx={{ marginBottom: 8}}>
                                <ImageTabContent 
                                    cardData={playlistData.image.images}
                                    item={playlistData.image}
                                    tabType="image"
                                    viewBy={viewBy === 0 ? "list" : "clip"}
                                    setViewBy={(view) => setViewBy(view === "list" ? 0 : 1)}
                                    searchpayload={searchpayload}
                                    handleChangeInputs={() => {}}
                                    showMediaCountResult={showMediaCountResult}
                                    mediaCountResult={mediaCountResult}
                                    searchedInputForResult=""
                                    isActiveTab={tabvalue === 2}
                                    showTimelineButtonInListViewCard={true}
                                    showWatchlistButtonInListViewCard={false}
                                    showPlaylistButtonInListViewCard={false}
                                    showDebugButtonInListViewCard={false}
                                    withoutSearch={true}
                                    selectedItemForTimeline={selectedItemForTimeline}
                                    handleClickTimeline={(card, index) => {
                                        setSelectedItemForTimeline(card);
                                        setViewBy(ViewBy.Clip);
                                    }}
                                    canAddToPlaylist={true}
                                />
                                
                            </Box>
                        ) : (
                            <Box className="no-data-message" >
                                <Typography variant="h6">There are currently no stills in playlist. </Typography>
                            </Box>)
                    ) : (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', padding: '20px', gap: '40px', marginTop: 3 }} className="container">
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                        </Box>
                    )}
                </CustomTabPanel>

            </Box>
            
            {openCardDetailDialog && 
                <CardDetailDialog 
                    openCardDetailDialog={openCardDetailDialog}
                    handleOpenCloseCardDetailDialog={handleOpenCloseCardDetailDialog}
                    selectedCardDetailDialogData={selectedCardDetailDialogData}
                    handleClickPlay={handleClickPlay}
                    searchpayload={searchpayload}
                />
            }

            {openPlaylistDialog &&
                <PlaylistDialog
                    openPlaylistDialog={openPlaylistDialog}
                    handleOpenClosePlaylistDialog={handleOpenPlaylistDialog}
                    selectedPlaylistDialogData={selectedPlaylistDialog}
                    addPlaylistCase={true}
                    handleCreatePlaylist={handleCreatePlaylist}

                />
            }

            <FsLightbox
                toggler={toggler}
                sources={[fullScreenMediaSource]}
                slide={productIndex}
                type={"video"}
            />
        </Box>
    );
}
