import { Box, CircularProgress } from "@mui/material";
import "./index.css";

export default function FullPageLoader() {
    return (
        <>
            <Box className="fullpage-loader-container">
                <CircularProgress className="loader" />
            </Box>
        </>
    );
}