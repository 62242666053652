import { Box, Button, Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { login } from "../../services/searchService";
import { redirect } from "react-router-dom";
import GoogleIcon from '@mui/icons-material/Google';

export default function Login(){


    const handleLogin = async () => {
        const response = await login();
    };

    return (
        <Box sx={{ width:'100%', display: "flex", flexDirection: "column", alignItems:"center", justifyContent: "center", height: '100vh'}}>
            

            <Box>
            <Card sx={{ height: "400px", display: "flex", flexDirection: "column", borderRadius: "10px",  padding: "20px",
                        width: '400px',
                        justifyContent: "center",
                        alignItems: "center" }}>
                <Box sx={{
                        fontSize: '26px',
                        margin: '20px'
                }}>
                Login
                </Box>
                <Box>
                    <CardContent >
                        <GoogleIcon
                        sx={{
                            height: '40px',
                            width: '40px',
                            color: '#1976d2',
                            cursor: 'pointer',
                            backgroundColor: '#e0e0e0',
                            padding: '20px',
                            borderRadius: '10px'
                        }}
                        onClick={handleLogin}>
                            
                        </GoogleIcon>
                    </CardContent>
                
                </Box>
            </Card>
                
            </Box>
            
        </Box>

    )
}