import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { Box, DialogContent, IconButton } from '@mui/material';
import { blackColor, whiteColor } from '../../common/constants';
import { Cancel, Favorite, PestControlOutlined, PlayArrow, PlayCircleRounded } from '@mui/icons-material';
import { errorToast, getConfidenceColoByConfidence, getFormatedDuration } from '../../common/utils';
import './index.css';
import ReactPlayer from 'react-player/lazy';
import { ISearchPayload, MediaType } from '../../types/interface';
import { useContext, useEffect, useState } from 'react';
import { addWatchlistItem, isInWatchlist } from '../../services/searchService';
import { toast } from 'react-toastify';
import iconWatchlist from "../../assets/icon-fav-large.svg";
import iconWatchlistBlack from "../../assets/icon-fav-black-large.svg";
import { useWatchList } from '../../hooks/useWatchList';
import iconBug from '../../assets/icon-bug-large.svg';
import Grid from "@mui/material/Grid";
import {IconFavSvg, IconBugSvg} from "../../common/utils/icons";

export interface CardDetailDialogProps {
  openCardDetailDialog: boolean;
  handleOpenCloseCardDetailDialog: (value: boolean) => void;
  selectedCardDetailDialogData: { type: string; value: any; autoplayVideo: boolean; };
  showDebugButton?: boolean;
  showWatchlistButton?: boolean;
  handleClickPlay: () => void;
  handleClickDebug?: () => void;
  searchpayload: ISearchPayload;
}

export default function CardDetailDialog(props: CardDetailDialogProps) {
  const {
    openCardDetailDialog,
    handleOpenCloseCardDetailDialog,
    selectedCardDetailDialogData: { type, value: card, autoplayVideo },
    showDebugButton = false,
    showWatchlistButton = true,
    handleClickPlay,
    handleClickDebug,
    searchpayload
  } = props;

  const [isWatchlistAdded, setIsWatchlistAdded] = useState(false);
  const [watchlistData, updateWatchlist] = useWatchList() as any[];

  const handleClose = () => {
    handleOpenCloseCardDetailDialog(false);
  };

  const addToWatchlist = (id: string) => {
    setIsWatchlistAdded(true);
    addWatchlistItem(id)
      .then((response) => {
        updateWatchlist();

      })
      .catch((error) => {
        setIsWatchlistAdded(false);
        errorToast(`Failed to update watchlist`);
      })
  }
  useEffect(() => {

  }, []);



  return (
    <Dialog className='card-dailog-box' onClose={handleClose} open={openCardDetailDialog}>
      <button onClick={() => handleClose()} className='close-dailog'></button>
      <DialogContent sx={{ padding: '27px 19px 27px 29px' }}>

      <Grid container rowGap={{sm:3}} columnSpacing={{ xl: 4, lg: 3, md: 2 }} display={"flex"} alignItems={"center"} height={"100%"}>
        <Grid item xs={12} md={6}>
          <Box sx={{minWidth: '543px', minHeight: '469px', borderRadius: '16px', background: card.media_type === MediaType.IMAGE ? `url('${card.thumbnail_url}') center/cover no-repeat` : blackColor, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          {card.media_type === MediaType.VIDEO && 
            <div className="video-img-box" style={{width:"100%"}}>
              <Box>
                <ReactPlayer
                width={'100%'}
                height={'100%'}
                url={card.url || undefined}
                playing={autoplayVideo}
                controls={true}
                />
              </Box>
            </div>
          }
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ height:{lg:'100%', md:'100%'}, overflowY:"auto", paddingRight: '10px'}}>

          {card.similarity ? 
            <>
            <h4>
              <span>Similarity:</span> {Number(card.similarity).toFixed(4)}
            </h4>
            </> :  null }
            <h3>
              {card.title}
            </h3>

          <ul className="year-name">
            {card.media_type === MediaType.VIDEO && card.end_time && card.start_time &&
              <li>
                  {getFormatedDuration(card.start_time, card.end_time)}
              </li>
            }
            <li className='movie-name'>
              {searchpayload.movies?.find((m) => m.name.toLowerCase() == card.movie_name.toLowerCase())?.display_name}
            </li>
          </ul>
          <div className='icon-btns-group'>
            <div className="icon-btns buttons-group-svgicons">
              {showWatchlistButton && 
                (isWatchlistAdded || isInWatchlist(card.id, watchlistData) ?
                  <IconButton
                  aria-label="add to favorites"
                  className="btn-fav selected"
                  sx={{ cursor: "unset", padding:0 }}
                  onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  }}
                  >
                    <IconFavSvg />
                  </IconButton> 
                  :
                  <IconButton
                  aria-label="add to favorites"
                  className="btn-fav"
                  onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addToWatchlist(card.id);
                  }}
                  sx={{padding:0}}
                  >
                    <IconFavSvg />
                  </IconButton>
                )
              }
              {showDebugButton && 
                <IconButton aria-label="debug" sx={{padding:0}} onClick={(e) => {
                  e.preventDefault(); e.stopPropagation();
                  handleClickDebug && handleClickDebug()
                  }}
                  className="btn-bug">
                  <IconBugSvg />
                </IconButton>
              }
            </div>
            {card.confidence && 
              <div className="confidence-level">
                <Button variant="outlined" size="small" 
                    sx={{ borderRadius: '16px', border: 'none', color: blackColor, background: getConfidenceColoByConfidence(card.confidence), textTransform: 'capitalize', ':hover': { border: 'none', color: blackColor, background: getConfidenceColoByConfidence(card.confidence) } }}>
                    {card.confidence}
                </Button>
              </div>
            }
          </div>
          {card.tags && card.tags.length && 
            <Box className="badges" sx={{ mt: '18px' }}>
              {
              card.tags?.map((tag: string, i: number) => (
              <Button key={i} variant="outlined" size="small">{tag}</Button>
              ))}
            </Box>
          }
          <div className="description">
            <p>
              {card.desc}
            </p>
          </div>
        </Grid>
      </Grid>
              </DialogContent>
    </Dialog>
  );
}
