import React, { useEffect, useState } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { getToken } from "../../services/searchService";


export default function LoginCallback(){
    const navigate = useNavigate();

    const [isloading, setIsloading] = useState(false);
    useEffect(() => {
        const querystring = window.location.search;
        console.log(querystring)
        getToken(querystring).then((response)=>{
            // console.log(response)
            localStorage.setItem("token",response.access_token);
            navigate("/", { replace: true });
            // window.location.replace("/")
        })
        .catch((err) => {
            console.log(err);
            
        })
      },[]);
    return (
        <div>
            <h2>callback</h2>
        </div>
    )
}

function componentDidMount() {
    throw new Error("Function not implemented.");
}
