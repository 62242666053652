import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import deleteIconOutlined from "../../assets/delete-icon-outlined.svg";

import "./index.css";
import { Box } from "@mui/material";

interface ConfirmationDialogProps {
  openConfirmDialog: boolean;
  handleCloseDialog: (value: boolean) => void;
  handleSubmitConfirm: () => void;
  confirmIcon?: any;
  primaryText: string;
  secondaryText: string;
  cancelButtonText?: string;
  yesButtonText?: string;
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  const {
    openConfirmDialog,
    handleCloseDialog,
    handleSubmitConfirm,
    confirmIcon,
    primaryText,
    secondaryText,
    cancelButtonText = "Cancel",
    yesButtonText = "Yes",
  } = props;

  //For submitting
  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      handleSubmitConfirm();
    },
    [handleSubmitConfirm]
  );

  const handleClose = () => {
    handleCloseDialog(false);
  };

  return (
    <>
      <Dialog
        className="confirm-dialog-box"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openConfirmDialog}
      >
        <DialogContent>
          {confirmIcon && (
            <Box className="confirm-icon-container">
              <img src={confirmIcon} alt="" />
            </Box>
          )}
          <Typography className="primary-text">{primaryText}</Typography>
          <Typography className="secondary-text">{secondaryText}</Typography>

          <Box className="confirm-button-container">
            <Button
              variant="outlined"
              className="confirm-cancel-button"
              size="small"
              onClick={handleClose}
            >
              {cancelButtonText}
            </Button>
            <Button
              variant="contained"
              className="confirm-yes-button"
              onClick={handleSubmit}
            >
              {yesButtonText}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
