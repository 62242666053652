import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { blackColor, greyColor, whiteColor } from "../../common/constants";
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Slider, Tooltip, Typography } from "@mui/material";
import FsLightbox from "fslightbox-react";
import { getConfidence, getConfidenceColoByConfidence, getConfidenceColor, getFormatedTime, getFormatedDuration, errorToast } from "../../common/utils";
import DebugDialog from "../DebugDialog";
import { findDOMNode } from "react-dom";
import { ICurrentImageState, ISearchPayload } from "../../types/interface";
import ImageTimelineResultCard from "../ImageTimelineResultCard";
import CardDetailDialog from "../CardDetailDialog";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlaylistDialog from "../PlaylistDialog";
import ZommInIcon from '../../assets/icon-glass-zoomin.svg';
import ZoomOutIcon from '../../assets/icon-glass-zoomout.svg';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import "./index.css";
import { DropContainer } from "../Drag&Drop/DropContainer";
import { DragBox } from "../Drag&Drop/DragBox";
import { addWatchlistItem, isInWatchlist } from "../../services/searchService";
import { toast } from "react-toastify";
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useWatchList } from "../../hooks/useWatchList";
import CardsContainer from "../CardsContainer";
import iconArrowLeft from '../../assets/icon-arrow-left-01.svg';
import iconArrowRight from '../../assets/icon-arrow-right-01.svg';
import iconPlayVideo from '../../assets/icon-video-play.svg';
import iconPauseVideo from '../../assets/icon-video-pause.svg';
import iconCheckboxUnchecked from '../../assets/iconCheckboxUnchecked.svg'
import iconCheckboxChecked from '../../assets/iconCheckboxChecked.svg'
import iconBtnShare from '../../assets/icon-share.svg'
import iconWatchlist from "../../assets/icon-fav-red-wbg.svg";
import iconBug from "../../assets/icon-bug-white-wbg.svg";
import iconWatchlistBlack from "../../assets/icon-fav-white-wbg.svg";
import iconRefresh from '../../assets/refresh-page-arrow-button_icon.svg'

export default function ImageTabContent({
  cardData,
  item,
  viewBy,
  setViewBy,
  searchpayload,
  handleChangeInputs,
  showMediaCountResult,
  mediaCountResult,
  searchedInputForResult,
  tabType,
  isActiveTab,
  showTimelineButtonInListViewCard,
  showWatchlistButtonInListViewCard,
  showPlaylistButtonInListViewCard,
  showDebugButtonInListViewCard,
  withoutSearch,
  selectedItemForTimeline,
  handleClickTimeline,
  canAddToPlaylist,
}: {
  cardData: any[];
  item: any,
  viewBy: string;
  setViewBy: (view: string) => void;
  searchpayload: ISearchPayload;
  handleChangeInputs: (event: any) => void;
  showMediaCountResult: boolean;
  mediaCountResult: any;
  searchedInputForResult: string;
  tabType: string;
  isActiveTab: boolean;
  showTimelineButtonInListViewCard: boolean;
  showWatchlistButtonInListViewCard: boolean;
  showPlaylistButtonInListViewCard: boolean;
  showDebugButtonInListViewCard: boolean;
  withoutSearch: boolean;
  selectedItemForTimeline: any;
  handleClickTimeline: (card: any, index: number) => void;
  canAddToPlaylist: boolean;
}) {

  const [imageSliderDuration, setImageSliderDuration] = useState<number>(100);
  const imageTimelineRef = useRef<SwiperRef | null>(null);
  const sideListRef = useRef<any>(null);
  const [isPauseIcon, setIsPauseIcon] = useState(true);
  const [cbHighConfidence, setCbHighConfidence] = useState<boolean>(true)
  const [cbMediumConfidence, setCbMediumConfidence] = useState<boolean>(true)
  const [cbLowConfidence, setCbLowConfidence] = useState<boolean>(true)
  const [toggler, setToggler] = useState(false);
  const [cards, setCards] = useState(cardData);
  const [imageFilteredCards, setImageFilteredCards] = useState<any[]>(cards);
  const [movieCards, setMovieCards] = useState<any[]>(cards.filter((card) => card.movie_name.toLowerCase() === cards[0].movie_name.toLowerCase()))
  const [activeImage, setActiveImage] = useState<any>();
  const [listDesc, setListDesc] = useState<any[]>(cardData && [...cardData]);
  const [currentImageState, setCurrentImageState] = useState<ICurrentImageState>({
    url: null,
    movie: {},
  });

  //For debug dialog
  const [openDebugDialog, setOpenDebugDialog] = useState<boolean>(false);
  const [debugMediaType, setDebugMediaType] = useState<string>('');
  const [debugMediaId, setDebugMediaId] = useState<string>('');

  //For CardDetailDialog
  const [openCardDetailDialog, setOpenCardDetailDialog] = useState<boolean>(false);
  const [selectedCardDetailDialogData, setselectedCardDetailDialogData] = useState<{ type: string; value: any; autoplayVideo: boolean; index: number }>({ type: '', value: {}, autoplayVideo: false, index: 1 });

  //For playlist dialog
  const [openPlaylistDialog, setOpenPlaylistDialog] = useState<boolean>(false);
  const [selectedPlaylistDialog, setSelectedPlaylistDialog] = useState<{ type: string; value: any }>({ type: '', value: {} });
  const [bulkInsertToPlaylist, setBulkInsertToPlaylist] = useState<boolean>(false);

  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const handleSelectImageCardonRight = (card: any, index: number) => {
    if (imageTimelineRef.current != null) {
      imageTimelineRef.current.swiper.autoplay.stop();
    }
    setIsPauseIcon(true);
    setActiveImage(card);
    handleShowDesc(card.id, true);
  }

  const handleOpenCloseCardDetailDialog = (val: boolean) => {
    setOpenCardDetailDialog(val);
  };

  const handleClickPlay = () => {
    setToggler(val => !val);
  }

  const handleClickMore = (dataObj: { type: string; value: any; autoplayVideo: boolean; index: number }) => {
    setselectedCardDetailDialogData(dataObj);
    setOpenCardDetailDialog(true);
  }

  const handleClickDebug = () => {
    setDebugMediaType(selectedCardDetailDialogData.type = "stills");
    setDebugMediaId(selectedCardDetailDialogData.value.id);
    setOpenDebugDialog(true);
  };

  const handleShowDesc = (id: string, show: boolean) => {
    setListDesc(prev => prev.map((listItem) => {
      if (!show) {
        listItem.showDesc = false;
      } else {
        if (listItem.id === id) {
          listItem.showDesc = true;

        } else {
          listItem.showDesc = false;
        }
      }
      return listItem;
    }));
  }

  const handleOpenPlaylistDialog = (val: boolean) => {
    setOpenPlaylistDialog(val);
  };

  const handleAddToPlaylist = (dataObj: { type: string; value: any }) => {
    setSelectedPlaylistDialog(dataObj);
    setBulkInsertToPlaylist(false);
    setOpenPlaylistDialog(true);
  }
  const handleBulkAddToPlaylist = (dataObj: {type:string; value:any[]}) => {
    setSelectedPlaylistDialog(dataObj);
    setBulkInsertToPlaylist(true);
    setOpenPlaylistDialog(true);
  }

  const [watchlistData, updateWatchlist] = useWatchList() as any[];
  const [ItemsAddedInWatchlist, setItemsAddedInWatchlist] = useState<{ [key: string]: boolean }[]>(cards.map((item) => {
    return ({ [item.id]: false })
  }
  ));

  const addToWatchlist = (id: string) => {
    setItemsAddedInWatchlist((prevState) => {
      return prevState.map((wlItem) => {
        return { ...wlItem, [id]: true };
      });
    });
    addWatchlistItem(id)
      .then((response) => {
        updateWatchlist();
      })
      .catch((error) => {
        setItemsAddedInWatchlist((prevState) => {
          return prevState.map((wlItem) => {
            return { ...wlItem, [id]: false };
          });
        });
        errorToast(`Failed to update watchlist`);
      })
  }

  const handleZoomIconClick = () => {

    const newValue = imageSliderDuration + 50;
    if (newValue <= 9584) {
      setImageSliderDuration(newValue);
    }
  };

  const handleZoomOutIconClick = () => {

    const newValue = imageSliderDuration - 50;
    if (newValue >= 100) {
      setImageSliderDuration(newValue);
    }
  };

  //For updating movie, movie url 
  useEffect(() => {
    if (activeImage) {
      const movie = searchpayload.movies.find((movieItem) => movieItem.name.toLowerCase() === activeImage?.movie_name.toLowerCase());
      if (movie) {
        if (movie.url !== currentImageState.url) {
          setCurrentImageState(val => ({ ...val, url: movie.url, movie: movie }));
          const filterCards = imageFilteredCards.filter((card) => card.movie_name.toLowerCase() === activeImage?.movie_name.toLowerCase());
          setMovieCards(filterCards.sort((a, b) => Number(a.start_time) - Number(b.start_time)))
        }
        else {
          if ((imageTimelineRef).current !== null) {
            const index = movieCards.findIndex(card => card.id === activeImage?.id);
            imageTimelineRef.current.swiper.slideTo(index)
          }
        }
      }
      const node = sideListRef.current as HTMLDivElement;
      const childDiv = node && node.querySelector(`#Z${activeImage.id}`);
      if (!childDiv) {
        return;
      }
      childDiv.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
      handleShowDesc(activeImage.id, true);

    }
  }, [activeImage]);

  useEffect(() => {

    if (!withoutSearch) {
      let targetConfidences: string[] = [];
      if (cbLowConfidence) {
        targetConfidences.push("low");
      }
      if (cbMediumConfidence) {
        targetConfidences.push("medium");
      }
      if (cbHighConfidence) {
        targetConfidences.push("high");
      }

      let filteredCards = cards.filter((c) => targetConfidences.some(tc => tc.toLowerCase() === c.confidence.toLowerCase()))

      if (filteredCards.length) {
        setImageFilteredCards(filteredCards)
        let currentItem = filteredCards?.find((c) => c.id === activeImage?.id)
        if (!currentItem) {
          handleSelectImageCardonRight(filteredCards[0], 0)
        }
      } else {
        setImageFilteredCards([]);
        setActiveImage(null);
      }
      if (imageTimelineRef.current != null) {
        imageTimelineRef.current.swiper.autoplay.stop();
      }
      setIsPauseIcon(true);
    }
  }, [cbLowConfidence, cbMediumConfidence, cbHighConfidence]);

  useEffect(() => {

    const filteredCards = imageFilteredCards.filter((card) => card.movie_name.toLowerCase() === activeImage?.movie_name.toLowerCase());
    setMovieCards(filteredCards.sort((a, b) => Number(a.start_time) - Number(b.start_time)))

  }, [imageFilteredCards]);

  useEffect(() => {
    const index = movieCards.findIndex(card => card.id === activeImage?.id);
    if ((imageTimelineRef).current !== null) {
      imageTimelineRef.current.swiper.slideTo(index)
    }

  }, [movieCards]);

  useEffect(() => {
    if (viewBy === "clip") {
      if (selectedItemForTimeline) {
        setActiveImage(selectedItemForTimeline)
      }
    }
  }, [viewBy]);

  const handleRemoveCards = (itemId: string) => {
    const updatedCards = imageFilteredCards?.filter((item) => item?.id !== itemId);
    setImageFilteredCards(updatedCards);

    //For selecting new card on right for removing selected card
    let currentItem = updatedCards?.find((c) => c.id === activeImage?.id)
    if (!currentItem) {
      handleSelectImageCardonRight(updatedCards[0], 0)
    }

    setUnsavedChanges(true);
  }

  return (
    <>
      {!(viewBy === 'clip') && <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '32px', marginBottom: '12px' }}>
        <Box>
          <Typography sx={{ fontSize: '20px', color: whiteColor, textTransform: 'capitalize' }}>
            {searchedInputForResult}
          </Typography>
          {(withoutSearch) || <Typography sx={{ fontSize: '14px', color: greyColor }}>
            {item?.total} result found out of {showMediaCountResult ? mediaCountResult.image_count : ""} records
          </Typography>}

        </Box>
      </Box>
      }
      <Grid container spacing={4} sx={{ mt: 0, justifyContent: 'center', display: viewBy === "clip" ? 'flex' : 'none' }}>
        <Grid
          item
          xs={12}
          sm={6}
          md={12}
        >
          <Grid container rowSpacing={3} columnSpacing={3} marginBottom={2} className="image-page">
            <Grid item xs={12} sm={12} md={6} lg={8} xl={8} sx={{paddingTop: '0px !important'}}>
                <Box className="query-text-section">
                  {searchedInputForResult}
                </Box>
                <Box className="image-box-header">
                  <Box className="left-section">
                    <Box className="movie-meta">
                      <Typography>
                        {(activeImage) ? currentImageState.movie?.display_name : ""}
                      </Typography>
                      <Box className="rating-box">
                        <img src="images/imdb.png" />
                        <Box className="rating-box-content">
                          <Typography className="rating-value"> {(activeImage) ? currentImageState.movie?.rating : ""}/</Typography>
                          <Typography className="rating-max-value">{(activeImage) ? currentImageState.movie?.rating_from : ""}</Typography>
                        </Box>
                      </Box>
                    </Box>
                    {/* {withoutSearch || <Box className="result-section">
                      {movieCards.length} result found out of {item?.total} records
                    </Box>} */}
                  </Box>
                </Box>
                <Box className="left-side">
                  {movieCards.length === 0 && (
                    <Box className="no-image">
                      No image matching the selected filter.
                    </Box>
                  )}
                  <Swiper
                    direction={'horizontal'}
                    slidesPerView={1}
                    allowTouchMove={false}
                    ref={imageTimelineRef}
                    onSlideChange={(swiper) => {
                      if (!isPauseIcon && swiper && swiper.activeIndex > -1) {
                        setActiveImage(movieCards[swiper.activeIndex])
                      }
                    }}
                    onInit={(swiper) => {
                      if (swiper && swiper.activeIndex > -1) {
                        setActiveImage(imageFilteredCards[0])
                      }
                      if (imageTimelineRef.current != null) {
                        imageTimelineRef.current.swiper.autoplay.stop()
                      }
                    }}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    className="my-swiper"
                  >
                    {movieCards.map((image, index) => (
                      <SwiperSlide key={image.id} className="swiper-slide-custom">
                        <ImageTimelineResultCard
                          card={image}
                          handleMediaClick={() => {
                            setToggler((val) => !val);
                          }}
                          handleDebugClick={() => {
                            setDebugMediaType("stills");
                            setDebugMediaId(image.id);
                            setOpenDebugDialog(true);
                          }}
                          cards={cards}
                          showConfidence={!withoutSearch}
                          showDebugButton={showDebugButtonInListViewCard}
                          showWatchlistButton={showWatchlistButtonInListViewCard}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>

                  <Grid container spacing={0} sx={{ mt: 0, ml: 0, justifyContent: 'center' }}>
                    <Box className="image-buttons-group">
                      <Box className="play-pause-button">
                        <button onClick={() => {
                          if (imageTimelineRef.current != null) {
                            imageTimelineRef.current.swiper.slidePrev();
                            setActiveImage(movieCards[imageTimelineRef.current.swiper.activeIndex]);
                          }
                        }}
                          disabled={activeImage?.id === movieCards[0]?.id}
                          style={{ background: 'none', color: (activeImage?.id === movieCards[0]?.id) ? 'grey' : 'white', border: 'none', marginRight: '-4px', cursor: 'pointer', padding: '0px' }}>
                          <img src={iconArrowLeft} alt="" />
                          {/* <KeyboardArrowLeftIcon /> */}
                        </button>
                        {isPauseIcon ? (
                            <img src={iconPlayVideo} alt=""
                              className="cursor-pointer"
                              onClick={() => {
                                setIsPauseIcon(false);
                                if (imageTimelineRef.current != null) {
                                  imageTimelineRef.current.swiper.autoplay.start()
                                }
                              }}
                            />                           
                        ) : (
                          <img src={iconPauseVideo} alt=""
                            className="cursor-pointer"
                            onClick={() => {
                              setIsPauseIcon(true);

                              if (imageTimelineRef.current != null) {
                                imageTimelineRef.current.swiper.autoplay.stop()
                              }
                            }}
                          />
                        )}
                        <button onClick={() => {
                          if (imageTimelineRef.current != null) {
                            imageTimelineRef.current.swiper.slideNext()
                            setActiveImage(movieCards[imageTimelineRef.current.swiper.activeIndex])
                          }
                        }}
                          disabled={activeImage?.id === movieCards[movieCards.length - 1]?.id}
                          style={{ background: 'none', color: (activeImage?.id === movieCards[movieCards.length - 1]?.id) ? 'grey' : 'white', border: 'none', marginLeft: '-2px', cursor: 'pointer', padding: '0px' }}>
                            <img src={iconArrowRight} alt="" />
                        </button>
                      </Box>

                      <Box className="zoom-icon-group">
                        <img src={ZoomOutIcon} className="cursor-pointer zoom-out-icon" alt="Icon Low" onClick={handleZoomOutIconClick} />
                        <Slider
                          sx={{
                            mt: 0, width: '100px', ml: 0, color: whiteColor, '& .MuiSlider-thumb': {
                              backgroundColor: '#ACACAC',
                              width: '8px',
                              height: '8px',
                              ':hover': { boxShadow: '0px 0px 0px 4px rgba(25, 118, 210, 0.16)', zIndex: '1' }
                            },
                          }}
                          aria-labelledby="track-true-zoom-slider"
                          size="small"
                          getAriaValueText={(valuetext) => `${valuetext}`}
                          value={imageSliderDuration}
                          onChange={(event, value, activeThumb) => {
                            event.preventDefault();
                            setImageSliderDuration(value as number)
                          }}
                          min={100}
                          max={9584}
                          valueLabelDisplay="auto"
                          valueLabelFormat={(x) => `${x}`}
                        />
                        <img src={ZommInIcon} className="cursor-pointer zoom-in-icon" alt="Icon High" onClick={handleZoomIconClick} />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        width: '100%', display: 'flex', justifyContent: 'center', overflow: 'auto',
                        borderTop: '1px solid #3E3E3E'
                      }}
                      onMouseEnter={(e) => {
                        let style = document.body.style.overflow
                        document.body.style.overflow = (style === 'hidden') ? 'auto' : 'hidden'
                      }}
                      onMouseLeave={(e) => {
                        let style = document.body.style.overflow
                        document.body.style.overflow = (style === 'hidden') ? 'auto' : 'hidden'
                      }}
                      className="still-actions"
                    >
                      <div
                        style={{
                          display: 'inline-block', position: 'relative',
                          cursor: 'pointer', touchAction: 'none', color: '#1976d2',
                          minHeight: '104px', height: 'auto', width: `98%`, padding: '35px 0 13px 0',
                          borderRadius: '12px'
                        }}
                        title="Scroll to zoom in / zoom out"
                        onWheel={(e) => {
                          if (e.deltaY < 0) {
                            setImageSliderDuration(prev => prev === 9584 ? prev : prev + 10);
                          } else {
                            setImageSliderDuration(prev => prev === 100 ? prev : prev - 10);
                          }
                          const node = findDOMNode(e.currentTarget) as HTMLDivElement;
                          const childDiv = node.querySelector(`#Z${activeImage?.id}`) as HTMLDivElement;
                          if (!childDiv) {
                            return;
                          }
                          childDiv.scrollIntoView();
                        }}
                      >
                        <div style={{ position: 'absolute', borderRadius: 'inherit', backgroundColor: '#555758', width: `${imageSliderDuration}%`, height: '6px', top: '50%', transform: 'translateY(-50%)' }}></div>
                        {movieCards.map((cItem, index) => {
                          return <React.Fragment key={cItem.id}>
                            <Tooltip key={cItem.id} open={true} PopperProps={{ disablePortal: true }} disableFocusListener disableHoverListener disableTouchListener title={getFormatedTime(cItem.start_time)} placement="top" arrow >
                              <div
                                style={{
                                  position: 'absolute', borderRadius: '50%',
                                  backgroundColor: `${getConfidenceColoByConfidence(cItem.confidence)}`, width: '13px',
                                  boxShadow: (cItem.id === activeImage?.id) ? '0px 0px 0px 8px rgba(25, 118, 210, 0.26)' : '',
                                  height: '13px', outline: 0, display: 'flex',
                                  alignItems: 'center', justifyContent: 'center', top: '50%',
                                  transform: 'translate(-50%, -50%)', left: (`${cItem.start_time * imageSliderDuration / 9584}%`)
                                }}
                                onClick={(event) => {
                                  event.preventDefault(); setActiveImage(movieCards[index]); handleShowDesc(cItem.id, true);
                                  if (imageTimelineRef.current != null) {
                                    imageTimelineRef.current.swiper.slideTo(index)
                                  }
                                }}
                                key={cItem.id}
                                id={`Z${cItem.id}`}
                              >
                                <input
                                  data-index="0" aria-valuenow={20}
                                  aria-labelledby="track-false-range-slider"
                                  aria-valuetext="20s" aria-orientation="horizontal"
                                  aria-valuemax={9584} aria-valuemin={0}
                                  type="range" min="0" max={"9584"}
                                  style={{
                                    border: '0px', clip: 'rect(0px, 0px, 0px, 0px)',
                                    height: '100%', margin: '-1px', overflow: 'hidden',
                                    padding: '0px', position: 'absolute',
                                    whiteSpace: 'nowrap', width: '100%',
                                    direction: 'ltr'
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </React.Fragment>
                        })}
                        <div style={{ position: 'absolute', top: '67%', color: greyColor, transform: 'translate(25%,-70%)', fontSize: '10px' }}>00:00</div>
                        <div style={{ position: 'absolute', top: '67%', color: greyColor, transform: 'translate(-96%,-50%)', left: `${imageSliderDuration}%`, fontSize: '10px' }}>{getFormatedTime(currentImageState.movie?.duration)}</div>
                      </div>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4} sx={{paddingTop: '0px !important'}}>
                <Box className="image-description-box" ref={sideListRef}>
                  {withoutSearch || <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <h4>
                      Result {item.total}/{showMediaCountResult ? mediaCountResult.image_count : ""}
                    </h4>
                    {!withoutSearch && 
                      <img src={iconRefresh} style={{marginLeft: '-60px'}} className="refresh-icon" onClick={()=>{setImageFilteredCards(cardData)}} />
                    }
                    <Box className="confidence-filter-area">
                      <FormGroup>
                        <FormControlLabel control={
                          <Checkbox
                            icon={<img src={iconCheckboxUnchecked} alt="" />}
                            checkedIcon={<img src={iconCheckboxChecked} alt="" />}
                            checked={cbLowConfidence}
                            size="small"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setCbLowConfidence(event.target.checked);
                            }}
                          />
                        } label={<Typography sx={{
                          color: getConfidenceColoByConfidence("low"),
                        }} variant="body2" color="textSecondary">Low</Typography>} />
                        <FormControlLabel control={
                          <Checkbox
                            icon={<img src={iconCheckboxUnchecked} alt="" />}
                            checkedIcon={<img src={iconCheckboxChecked} alt="" />}
                            checked={cbMediumConfidence}
                            size="small"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setCbMediumConfidence(event.target.checked);
                            }}
                          />
                        } label={<Typography sx={{
                          color: getConfidenceColoByConfidence("medium"),
                          
                        }} variant="body2" color="textSecondary">Medium</Typography>} />
                        <FormControlLabel control={
                          <Checkbox
                            icon={<img src={iconCheckboxUnchecked} alt="" />}
                            checkedIcon={<img src={iconCheckboxChecked} alt="" />}
                            checked={cbHighConfidence}
                            size="small"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setCbHighConfidence(event.target.checked);
                            }}
                          />
                        } label={<Typography sx={{
                          color: getConfidenceColoByConfidence("high"),
                          
                        }} variant="body2" color="textSecondary">High</Typography>} />
                      </FormGroup>
                    </Box>
                  </Box>}
                  {canAddToPlaylist && <div className="playlist-container">
                    <DropContainer>
                    <div className="add-to-playlist" style={imageFilteredCards.length < 1 ? {pointerEvents: 'none'} : {}}>
                      <label onClick={(event) => { handleAddToPlaylist({ type: 'default', value: activeImage }) }} className="custom-file-upload">
                        <span>
                          <strong>
                            Add to Playlist
                          </strong>
                          <em>
                            (Drag and Drop)
                          </em>
                        </span>
                      </label>
                    </div>
                  </DropContainer>
                  {!withoutSearch &&
                  <button 
                    className="create-playlist" 
                    onClick={(event) => handleBulkAddToPlaylist({ type: 'default', value: imageFilteredCards })}
                    disabled={imageFilteredCards.length < 1}
                  >
                    <div className="icon-plus">
                    </div>
                    <div className="create-text">
                      Create Playlist
                    </div>
                  </button>}
                  {/* <button className="btn-share">
                    <img src={iconBtnShare} alt="" />
                  </button>  */}
                 
                  </div>}
                  {imageFilteredCards.length < 1 ?
                    <Box sx={{minHeight: '605px'}}>
                      <Typography sx={{ fontSize: '16px', color: whiteColor, marginLeft: '3px' }}>
                        No images found.
                      </Typography>
                    </Box>
                    :
                    <ol className="image-desc-list">
                      {
                        imageFilteredCards.map((item, index) => {
                          const movie = searchpayload.movies.find(
                            (movieItem) =>
                              movieItem.name.toLowerCase() === item.movie_name.toLowerCase()
                          );
                          const movieDisplayName = movie ? movie.display_name : "Movie not found";
                          return (
                            <DragBox key={item.id} itemObj={item} onDragEnd={(itemObj) => handleAddToPlaylist({ type: 'default', value: itemObj })}>
                              <li key={item.id} id={`Z${item.id}`} className={(item.id === activeImage?.id) ? "timeline-right-list selected" : "timeline-right-list"}
                                onClick={() => {
                                  handleSelectImageCardonRight(item, index);
                                }
                                }
                              >
                                {/* need to make it functional
                                <div className="icon-card-close">
                                </div> */}
                                <div className="icon-full-desc" onClick={(event) => { event.preventDefault(); event.stopPropagation(); handleShowDesc(item.id, listDesc.find((lItem) => lItem.id === item.id).showDesc ? false : true); }}>
                                  {listDesc.find((lItem) => lItem.id === item.id).showDesc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                </div>
                                <div className="small-list">
                                  <div className="image-thumbnail">
                                    <img src={item.thumbnail_url} />
                                  </div>
                                  <div className="image-desc">
                                      <h3>
                                          {movieDisplayName}
                                      </h3>
                                    <h4 style={{position: 'relative'}}>
                                      <div className="image-desc-inner-content"> 
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}>
                                          <Box sx={{ display: 'inline-block' }}>{getFormatedTime(item?.start_time)}</Box>
                                          {withoutSearch ||
                                            <Box
                                              className="confidence-level"
                                              sx={{
                                                color: getConfidenceColoByConfidence(item.confidence),
                                              }}
                                            >
                                              {item.confidence}
                                            </Box>
                                          }
                                          <Box>
                                            {showWatchlistButtonInListViewCard ? ItemsAddedInWatchlist.find((wlItem) => wlItem.hasOwnProperty(item.id))?.[item.id] || isInWatchlist(item.id, watchlistData) ?
                                              <IconButton
                                                aria-label="add to favorites"
                                                className={withoutSearch ? "btn-fav-playlist" : "btn-fav"}
                                                sx={{ cursor: "unset" }}
                                                onClick={async (e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                }}
                                              >
                                                <img src={iconWatchlist} alt="Red Watchlist Icon" />
                                              </IconButton> :
                                              <IconButton
                                                aria-label="add to favorites"
                                                className="btn-fav"
                                                onClick={async (e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  addToWatchlist(item.id);
                                                }}
                                              >
                                                <img src={iconWatchlistBlack} alt="Black Watchlist Icon" />
                                              </IconButton> : ''
                                            }

                                            {showDebugButtonInListViewCard && <IconButton
                                              aria-label="debug"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setDebugMediaType("stills");
                                                setDebugMediaId(item.id);
                                                setOpenDebugDialog(true);
                                              }}
                                              className="btn-bug"
                                            >
                                              <img src={iconBug} alt="" />
                                            </IconButton>}
                                          </Box>
                                          {!withoutSearch && imageFilteredCards.length > 1 && <div className="icon-card-close" onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  handleRemoveCards(item.id);
                                                }}></div>  
                                          }   
                                        </Box>

                                        <h4 id="mini-desc" style={{ display: !item.showDesc ? 'block' : 'none' }}><span>{item.desc}</span></h4>
                                      </div>

                                    </h4>
                                  </div>
                                </div>
                                <div className="full-desc" style={{ display: item.showDesc ? 'block' : 'none' }}>
                                  {item.desc}
                                </div>
                              </li>
                            </DragBox>
                          )
                        })
                      }
                    </ol>
                  }
                </Box>
              </Grid></Grid>
        </Grid>
      </Grid>

      <div style={{ display: (viewBy === "list") ? 'block' : 'none' }}>
        <CardsContainer
          cards={cards}
          type={tabType}
          component="img"
          showTimelineButton={showTimelineButtonInListViewCard}
          showWatchlistButton={showWatchlistButtonInListViewCard}
          showPlaylistButton={showPlaylistButtonInListViewCard}
          showDebugButton={showDebugButtonInListViewCard}
          handleMediaClick={(card, index) => {
            setToggler((val) => !val);
          }}
          handleDebugClick={(card, index) => {
            setDebugMediaType("stills");
            setDebugMediaId(card.id);
            setOpenDebugDialog(true);
          }}
          handleClickMore={(card, index, dataObj) => handleClickMore({ ...dataObj, "index": index })}
          handleAddToPlaylistClick={(card, index) => {
            handleAddToPlaylist({ type: 'default', value: card })
          }}
          handleClickTimeline={(card, index) => handleClickTimeline(card, index)}
          searchpayload={searchpayload}
        />
      </div>
      <FsLightbox
        toggler={toggler}
        sources={movieCards?.map((cItem) => cItem.url)}
        slide={movieCards.findIndex((image) => image.id === activeImage?.id) + 1}
        type={"image"}
      />

      {openCardDetailDialog &&
        <CardDetailDialog
          openCardDetailDialog={openCardDetailDialog}
          handleOpenCloseCardDetailDialog={handleOpenCloseCardDetailDialog}
          selectedCardDetailDialogData={selectedCardDetailDialogData}
          showDebugButton={showDebugButtonInListViewCard}
          showWatchlistButton={showWatchlistButtonInListViewCard}
          handleClickPlay={handleClickPlay}
          handleClickDebug={handleClickDebug}
          searchpayload={searchpayload}
        />
      }

      {openDebugDialog && <DebugDialog
        openDialog={openDebugDialog}
        handleOpenDialog={(val) => setOpenDebugDialog(val)}
        mediaId={debugMediaId}
        mediaType={debugMediaType}
        searchpayload={searchpayload}
      />}

      {openPlaylistDialog &&
        <PlaylistDialog
          openPlaylistDialog={openPlaylistDialog}
          handleOpenClosePlaylistDialog={handleOpenPlaylistDialog}
          selectedPlaylistDialogData={selectedPlaylistDialog}
          addPlaylistCase={bulkInsertToPlaylist}
          bulkInsert={bulkInsertToPlaylist}
          handleCreatePlaylist={() => { }}
        />
      }
    </>
  );
}


