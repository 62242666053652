import React, { useContext, useEffect, useRef, useState } from "react";
import { blackColor, greyColor,  responsiveSettingForCarousel, resultFilterList, sortbyList, whiteColor } from "../../common/constants";
import { Box, Button, Card, CardContent, Checkbox, CircularProgress, Container, FormControlLabel, FormGroup, Grid, IconButton, ListItem, MenuItem, Paper, Select, SelectChangeEvent, Slider, Switch, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import FsLightbox from "fslightbox-react";
import { getConfidence, getConfidenceColoByConfidence, getConfidenceColor, getFormatedTime, getFormatedDuration, errorToast, infoToast } from "../../common/utils";
import DebugDialog from "../DebugDialog";
import { KeyboardArrowUp, PauseCircle, PauseCircleRounded, PlayCircle, PlayCircleRounded } from "@mui/icons-material";
import ReactPlayer from 'react-player/lazy'
import { findDOMNode } from "react-dom";
import { ICurrentVideoState, ISearchPayload, PlayerPlayMode } from "../../types/interface";
import CardDetailDialog from "../CardDetailDialog";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import iconBug from "../../assets/icon-bug-white-wbg.svg";
import PlaylistDialog from "../PlaylistDialog";
import iconPlayVideoOnHover from '../../assets/icon-play-video-white-hover.svg';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeOff from '@mui/icons-material/VolumeOff';
import Fullscreen from '@mui/icons-material/Fullscreen';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import "./index.css";
import { useLocation } from "react-router-dom";
import { DropContainer } from "../Drag&Drop/DropContainer";
import { DragBox } from "../Drag&Drop/DragBox";
import iconWatchlist from "../../assets/icon-fav-red-wbg.svg";
import { addWatchlistItem, isInWatchlist } from "../../services/searchService";
import { toast } from "react-toastify";
import iconWatchlistBlack from "../../assets/icon-fav-white-wbg.svg";
// Import Swiper React components
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import screenfull from 'screenfull'
import { useWatchList } from "../../hooks/useWatchList";
import CardsContainer from "../CardsContainer";
import iconBtnShare from '../../assets/icon-share.svg'
import iconPauseVideo from '../../assets/icon-video-pause.svg'
import iconPlayVideo from '../../assets/icon-video-play.svg'
import iconZoomVideo from '../../assets/icon-video-zoom.svg'
import iconVolume from '../../assets/icon-volume.svg'
import iconRefresh from '../../assets/refresh-page-arrow-button_icon.svg'
import iconFullVolume from '../../assets/iconFullVolume.svg'
import iconHalfVolume from '../../assets/iconHalfVolume.svg'
import iconMute from '../../assets/iconMute.svg'
import iconCheckboxUnchecked from '../../assets/iconCheckboxUnchecked.svg'
import iconCheckboxChecked from '../../assets/iconCheckboxChecked.svg'

interface ChipData {
  key: number;
  label: string;
  value: string;
  maxDuration?: number;
}

export default function VideoTabContent({
  cardData,
  item,
  viewBy,
  setViewBy,
  searchpayload,
  handleChangeInputs,
  showMediaCountResult,
  mediaCountResult,
  searchedInputForResult,
  tabType,
  isActiveTab,
  showTimelineButtonInListViewCard,
  showWatchlistButtonInListViewCard,
  showPlaylistButtonInListViewCard,
  showDebugButtonInListViewCard,
  withoutSearch,
  selectedItemForTimeline,
  handleClickTimeline,
  canAddToPlaylist
}: {
  cardData: any[];
  item:any;
  viewBy: string;
  setViewBy: (view:string) => void;
  searchpayload: ISearchPayload;
  handleChangeInputs: (event:any) => void; 
  showMediaCountResult: boolean;
  mediaCountResult: any;
  searchedInputForResult: string;
  tabType: string;
  isActiveTab: boolean;
  showTimelineButtonInListViewCard:boolean;
  showWatchlistButtonInListViewCard:boolean;
  showPlaylistButtonInListViewCard:boolean;
  showDebugButtonInListViewCard:boolean;
  withoutSearch:boolean;
  selectedItemForTimeline:any;
  handleClickTimeline: (card:any, index:number) => void;
  canAddToPlaylist: boolean;
}) {
  const location = useLocation();

  const singleSelect = true;
  const [videoSliderDuration, setVideoSliderDuration] = useState<number>(searchpayload.movies[0]?.duration || 10800);
  const [secondaryVideoSliderDuration, setSecondaryVideoSliderDuration] = useState<number>(10800);
  const playerRef = useRef<ReactPlayer | null>(null);
  const secondaryPlayerRef = useRef<ReactPlayer | null>(null);
  const histogramSliderRef = useRef<SwiperRef | null>(null);
  const sideListRef = useRef<any>(null);
  const [sortby, setSortby] = useState<number>(1);
  const [volume, setVolume] = useState(1)
  const [muted, setMuted] = useState(isActiveTab?false:true)
  const [cbHighConfidence, setCbHighConfidence] = useState<boolean>(true)
  const [cbMediumConfidence, setCbMediumConfidence] = useState<boolean>(true)
  const [cbLowConfidence, setCbLowConfidence] = useState<boolean>(true)
  const [openCreatePlaylistDialog, setOpenCreatePlaylistDialog] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);


  //For Lightbox
  const [toggler, setToggler] = useState(false);
  // const [productIndex, setProductIndex] = useState(1);
  const [productIndex, setProductIndex] = useState(0);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const [sourceCards, setSourceCards] = useState(cardData);
  const [cards, setCards] = useState(sourceCards);
  const [chipData, setChipData] = React.useState<readonly ChipData[]>(
    resultFilterList.map((rflItem, index) => {
      return {
        key: index,
        label: rflItem.text,
        value: rflItem.value
      };
    })
  );
  const [activeDuration, setActiveDuration] = useState<any[]>([]);
  const [activecategory, setActivecategory] = useState<number>(-1);

  //For slider
  // const [activeImage, setActiveImage] = useState(cards?.length && cards.reduce((finalCard, iteratingCard) => finalCard.similarity > iteratingCard.similarity ? finalCard : iteratingCard));
  const [activeImage, setActiveImage] = useState<any>();

  //For video player
  const [currentVideoState, setCurrentVideoState] = useState<ICurrentVideoState>({
    url: null,
    // url: searchpayload.movies[0]?.url,
    movie: {},
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: activeImage?.start_time/videoSliderDuration || 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false
  });
  //For secondary video player
  const [secondaryVideoState, setSecondaryVideoState] = useState<ICurrentVideoState>({
    url: null,
    movie: {},
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false
  });
  const [playerReady, setPlayerReady] = useState<boolean>(false);
  const [secondaryPlayerReady, setSecondaryPlayerReady] = useState<boolean>(false);
  const [loadingVideo, setLoadingVideo] = useState<boolean>(true);
  const [showDescription, setShowDescription] = useState<boolean>(false);
  const [playMode, setPlayMode] = useState<PlayerPlayMode>(PlayerPlayMode.FULL_MOVIE_PLAY);
  const [manuallyChanged, setManuallyChanged] = useState<boolean>(false);
  const [movedhistogramProgress, setMovedhistogramProgress] = useState<boolean>(false);
  const [sortedArrayOnTime, setSortedArrayOnTime] = useState(cards.slice().sort((a,b) => Number(a.start_time) - Number(b.start_time)));
  const [sortedMovieClips, setSortedMovieClips] = useState(cards);

  //For debug dialog
  const [openDebugDialog, setOpenDebugDialog] = useState<boolean>(false);
  const [debugMediaType, setDebugMediaType] = useState<string>('');
  const [debugMediaId, setDebugMediaId] = useState<string>('');

  const [listDesc, setListDesc] = useState<any[]>(cardData && [...cardData]);

  //For CardDetailDialog
  const [openCardDetailDialog, setOpenCardDetailDialog] = useState<boolean>(false);
  const [selectedCardDetailDialogData, setselectedCardDetailDialogData] = useState<{type:string; value:any; autoplayVideo: boolean; index:number}>({type: '', value: {}, autoplayVideo: false, index: 1});
  
  const [highConfidenceCards, setHighConfidenceCards] = useState<any[]>(cards?.filter((cardItem) => cardItem.confidence.toLowerCase() === "high"));
  const [mediumConfidenceCards, setMediumConfidenceCards] = useState<any[]>(cards?.filter((cardItem) => cardItem.confidence.toLowerCase() === "medium"));
  const [lowConfidenceCards, setLowConfidenceCards] = useState<any[]>(cards?.filter((cardItem) => cardItem.confidence.toLowerCase() === "low"));

  const [highConfidenceColorMultiplier, setHighConfidenceColorMultiplier] = useState<number>(1/highConfidenceCards?.length); 
  const [mediumConfidenceColorMultiplier, setMediumConfidenceColorMultiplier] = useState<number>(1/mediumConfidenceCards?.length); 
  const [lowConfidenceColorMultiplier, setLowConfidenceColorMultiplier] = useState<number>(1/lowConfidenceCards?.length); 

  const [highConfidenceColors, setHighConfidenceColors] = useState(highConfidenceCards?.sort((a,b) => a.similarity - b.similarity).map((hcItem, index) => {
    return {
      similarity: hcItem.similarity,
      color: `rgba(162, 233, 133, ${highConfidenceColorMultiplier*(index+1)})`
    }
  }));
  const [mediumConfidenceColors, setMediumConfidenceColors] = useState(mediumConfidenceCards?.sort((a,b) => a.similarity - b.similarity).map((mcItem, index) => {
    return {
      similarity: mcItem.similarity,
      color: `rgba(254, 200, 104, ${mediumConfidenceColorMultiplier*(index+1)})`
    }
  }));
  const [lowConfidenceColors, setLowConfidenceColors] = useState(lowConfidenceCards?.sort((a,b) => a.similarity - b.similarity).map((lcItem, index) => {
    return {
      similarity: lcItem.similarity,
      color: `rgba(236, 107, 98, ${lowConfidenceColorMultiplier*(index+1)})`
    }
  }));

  useEffect(() => {
    if (viewBy === "clip") {
      if (selectedItemForTimeline) {
        setSelectedLocationItem(false);
        setFirstLoad(true);          
        setActiveImage(null);
        setProductIndex(0);
        if (playerRef.current !== null) {
          if (videoSliderDuration) {
            if (isActiveTab) {
              playerRef.current.seekTo(selectedItemForTimeline?.start_time/videoSliderDuration || 0);
              setCurrentVideoState(val => ({...val, playing: true}));     
              // if (histogramSliderRef.current !== null) {              
              //   histogramSliderRef.current.swiper.slideTo(sortedMovieClips.findIndex((cItem:any) => cItem.id === activeImage?.id));
              // }
        
              }
          }
      }
      }        
    } else if (viewBy === "list") {
      setCurrentVideoState(val => ({...val, playing: false}));
    }
  }, [viewBy]);

  useEffect(() => {
    if (screenfull.isEnabled) {
      
      screenfull.on('change', () => {
        
        if(screenfull.isFullscreen)
        {
          setCurrentVideoState(prev => ({...prev, controls:true}))
  
        }else{
          setCurrentVideoState(prev => ({...prev, controls:false}))
        }
      });
    }
  },[])

  useEffect(() => {
    setSortedMovieClips(sortedArrayOnTime.filter((csItem) => csItem.movie_name.toLowerCase() === currentVideoState.movie.name?.toLowerCase()));
  }, [sortedArrayOnTime, currentVideoState.movie]);

  const handlePlayModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newVal: PlayerPlayMode,
  ) => {
    if (newVal !== null) {
    setPlayMode(newVal);
    }
  };

  const handleClickFullscreen = () => {
    if (playerRef.current !== null) {
        screenfull.request(playerRef.current.getInternalPlayer() as unknown as Element)   
    }
  }

  //For sorting
  useEffect(() => {
    let new_cards = [...cards];
    if (sortby === sortbyList[0].value) {
        new_cards = cards.sort((a,b) => Number(a.similarity) - Number(b.similarity));        
    } else if (sortby === sortbyList[1].value) {
        new_cards = cards.sort((a,b) => Number(b.similarity) - Number(a.similarity));        
    }
    setCards([...new_cards]);
  }, [sortby]);

  useEffect(() => {
    let filteredCards = sourceCards.filter((cItem) => {
        return (
          (activecategory === -1) || chipData.find((chipItem) => chipItem.key === activecategory)?.value.toLowerCase() === cItem.type.toLowerCase());
      });
      setCards(filteredCards);  
  }, [activecategory]);

  useEffect(() => {
    let filteredCards = sourceCards.filter((cItem) => {
      return (
        !activeDuration.length ||
        activeDuration.some((adItem) => adItem.label === cItem.type)
      );
    });
    setCards(filteredCards);
  }, [singleSelect ? activeDuration[0]?.key : activeDuration.length]);

  useEffect(() => {
    if (playerReady) {
      // if (location.state && location.state.cardItem && Object.keys(location.state?.cardItem).length && firstLoad) {                                                        
      //   if (playerRef.current !== null) {
      //     if (videoSliderDuration) {            
      //       // console.log(location.state.cardItem.start_time/videoSliderDuration);
      //       playerRef.current.seekTo(location.state.cardItem.start_time/videoSliderDuration);
      //       setCurrentVideoState(val => ({...val, playing: true}));                                
      //       // setLoadingVideo(false);
      //     }
      //     }    
      // } else {
        if (playerRef.current !== null) {
          if (videoSliderDuration) {
            if (isActiveTab) {
              playerRef.current.seekTo(activeImage?.start_time/videoSliderDuration || 0);
              setCurrentVideoState(val => ({...val, playing: viewBy === "list" ? false : true}));     
              if (histogramSliderRef.current !== null) {              
                histogramSliderRef.current.swiper.slideTo(sortedMovieClips.findIndex((cItem:any) => cItem.id === activeImage?.id));
              }
        
              }
            // else{
            //   setLoadingVideo(false);
            // }
            // setLoadingVideo(false);                                           
          }
        // }    
      }
    }
  }, [playerReady]);

  useEffect(() => {
    const unloadCallback = (event:BeforeUnloadEvent) => {
      if (unsavedChanges) {
        const message = "You have unsaved changes. Are you sure you want to leave?";
        event.preventDefault();
        event.returnValue = message; 
        return message; 
      }
    };
  
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [unsavedChanges]);

  useEffect(() => {
    if (secondaryPlayerReady) {
        if (secondaryPlayerRef.current !== null) {
          if (secondaryVideoSliderDuration) {
            if (isActiveTab) {
              let activeImageIndex = cards.findIndex((cardEl) => cardEl.id === activeImage?.id);
              let nextClip = cards[activeImageIndex+1];        
              secondaryPlayerRef.current.seekTo(nextClip?.start_time/secondaryVideoSliderDuration || 0);
              // setSecondaryVideoState(val => ({...val, playing: true}));     
              }
          }
      }
    }
  }, [secondaryPlayerReady]);

  const handleRemoveCards = (itemId: any) => {
    const updatedCards = cards?.filter((item) => item?.id !== itemId);
    if(playMode === PlayerPlayMode.AUTO_PLAY_CLIP) {
      let currentItem = updatedCards.find((item) => item?.id === activeImage?.id);
      if(!currentItem){
        const selectedIndex = cards.findIndex((item) => item?.id === activeImage?.id);
        if(selectedIndex < cards.length-1){
          currentItem = updatedCards.find((item) => item?.id === cards[selectedIndex +1].id);
          setActiveImage(currentItem);
          if (playerRef.current !== null) {
            playerRef.current.seekTo(currentItem.start_time/videoSliderDuration);      
            setCurrentVideoState(val => ({...val, playing: true}));            
          }
        }
      }
    }
    const remainingCards = sourceCards?.filter((item) => item?.id !== itemId);
    setSourceCards(remainingCards);
    setCards(updatedCards);
    setUnsavedChanges(true);
  };

  const [selectedLocationItem, setSelectedLocationItem] = useState<boolean>(false);

  useEffect(() => {
    setSortedMovieClips(cards)
 
   }, [cards]);

  //For pausing movie on non result areas when switched to Single Clip Play mode or Auto Play Clip mode
  useEffect(() => {
    if((playMode === PlayerPlayMode.SINGLE_CLIP_PLAY || playMode === PlayerPlayMode.AUTO_PLAY_CLIP) && !activeImage && currentVideoState.playing){
      setCurrentVideoState(val => ({...val, playing: false}));     
      infoToast("Switch to Full Movie Play for continue watching the movie or select a video clip to play.")
    }

  }, [playMode]);

  useEffect(() => {
    let targetCards = [...cards];
    let currentItemIndex:number;
    let currentItem:any;
    
    //Evaluated when redirected from other pages on click of timeline button
    if (!selectedLocationItem && location.state && location.state.cardItem && Object.keys(location.state?.cardItem).length) {            
      currentItemIndex = targetCards.findIndex((cii) => {
          return cii.id === location.state.cardItem.id
      });
      currentItem = targetCards[currentItemIndex];

      setFirstLoad(false);

    }
    
    //Evaluated when clicked on timeline button without search
    else if (!selectedLocationItem && withoutSearch) {
      if (selectedItemForTimeline) {
        currentItemIndex = targetCards.findIndex((cii) => {
          return cii.id === selectedItemForTimeline.id
        });        
      } else {
        currentItemIndex = 0;
      }
      currentItem = targetCards[currentItemIndex];

      setFirstLoad(false);
    }

    //Evaluated when renders first time on this page
    else if (firstLoad) {      
      if (selectedItemForTimeline) {
        currentItemIndex = targetCards.findIndex((cii) => {
          return cii.id === selectedItemForTimeline.id
        });        
        currentItem = targetCards[currentItemIndex];
      }
      else if (!targetCards.reduce((total, tcItem) => (total + tcItem.similarity), 0)) {
        currentItemIndex = 0;
        currentItem = targetCards[currentItemIndex];
      } else {
        currentItemIndex = targetCards.findIndex((cii) => {
          return cii.id === (cards?.length && cards.reduce((finalCard, iteratingCard) => finalCard.similarity > iteratingCard.similarity ? finalCard : iteratingCard).id);
        });
        currentItem = targetCards[currentItemIndex];      
      }

      setFirstLoad(false);
    } 
    
    //Evalated 
    else {
      if(playMode !== PlayerPlayMode.AUTO_PLAY_CLIP ){
        targetCards = [...sortedMovieClips];
      }
      currentItemIndex = sortedMovieClips.findIndex((cii) => {
          return (Number((currentVideoState.played*videoSliderDuration).toFixed(4)) >= cii.start_time) && (Number((currentVideoState.played * videoSliderDuration).toFixed(4)) < cii.end_time)            
      });
      currentItem = sortedMovieClips[currentItemIndex];
    }

    // currentItem = targetCards[currentItemIndex];
    setSelectedLocationItem(true)

    if (currentItem?.id !== activeImage?.id) {
        if (    currentItem    ) {

          //For checking Auto Play clip mode
          if(playMode == PlayerPlayMode.AUTO_PLAY_CLIP ){
              if (playerRef.current !== null && !manuallyChanged) {
                 //When player reached end clip
                  // if(currentVideoState.played > (cards[cards.length - 1].start_time/videoSliderDuration))
                  // {
                  //   setCurrentVideoState(val => ({...val, playing: false}));    

                  // }
                  //When activeImage is not null
                  if (activeImage) {
                  let activeImageIndex = cards.findIndex((cardEl) => cardEl.id === activeImage.id);
                  let nextClip = cards[activeImageIndex+1];

                  //This will prevent going beyond last card item
                  if (nextClip) {
                    currentItem = nextClip;
                    currentItemIndex = activeImageIndex+1;
                      playerRef.current.seekTo(nextClip.start_time/videoSliderDuration);  
                      setCurrentVideoState(val => ({...val, playing: true}));                        
                  }
      
                }
              } else {}
          } else {
            if(playMode == PlayerPlayMode.SINGLE_CLIP_PLAY && !manuallyChanged){
              setCurrentVideoState(val => ({...val, playing: false}));
            }
          }

          setActiveImage(currentItem);
          setProductIndex(currentItemIndex + 1); 
            if (histogramSliderRef.current !== null) {
              
              histogramSliderRef.current.swiper.slideTo(sortedMovieClips.findIndex((cItem:any) => cItem.id === currentItem.id));
            }
      
            // //For expading side cards description
            handleShowDesc(currentItem.id, true);      
      
            //For scroll into view in sidecards list
            const node = sideListRef.current as HTMLDivElement;
            const childDiv = node && node.querySelector(`#Z${currentItem.id}`);
            if (!childDiv) {
              return;
            }
            childDiv.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });  
      
            setShowDescription(true);
              
        } else {
          if(playMode == PlayerPlayMode.AUTO_PLAY_CLIP ){
              if (playerRef.current !== null && !manuallyChanged)
              {
                 //When player reached end clip
                  if((activeImage?.id === cards[cards.length-1].id) && (currentVideoState.played > (cards[cards.length - 1].start_time/videoSliderDuration)))
                  {
                    setCurrentVideoState(val => ({...val, playing: false}));    
                    setActiveImage(null);
                    setProductIndex(0); 
                    setShowDescription(false); 
                    setMovedhistogramProgress(false);    
                  }
                  //When activeImage is not null
                  else if (activeImage) {       
                  let activeImageIndex = cards.findIndex((cardEl) => cardEl.id === activeImage.id);
                  let nextClip = cards[activeImageIndex+1];

                  //This will prevent going beyond last card item
                  if (nextClip) {
                      
                    setActiveImage(nextClip);
                    if (histogramSliderRef.current !== null) {
            
                      histogramSliderRef.current.swiper.slideTo(sortedMovieClips.findIndex((cItem:any) => cItem.id === nextClip.id));
                    }
              
                    // //For expading side cards description
                    handleShowDesc(nextClip.id, true);      
              
                    //For scroll into view in sidecards list
                    const node = sideListRef.current as HTMLDivElement;
                    const childDiv = node && node.querySelector(`#Z${nextClip.id}`);
                    if (!childDiv) {
                      return;
                    }
                    childDiv.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });  
                    setShowDescription(true);
                    
                    playerRef.current.seekTo(nextClip.start_time/videoSliderDuration);  
                    setCurrentVideoState(val => ({...val, playing: true}));                        
                  }
      
                }
              }else{
                setActiveImage(null);
                setProductIndex(0); 
                setShowDescription(false); 
                setMovedhistogramProgress(false)
              }
          }else{

              setActiveImage(null);
              setProductIndex(0); 
              setShowDescription(false); 
              setMovedhistogramProgress(false)
              if(playMode == PlayerPlayMode.SINGLE_CLIP_PLAY && !manuallyChanged){
                setCurrentVideoState(val => ({...val, playing: false}));
              }
          
          }

        }
    }
    if(manuallyChanged)
    {
      setManuallyChanged(false)
    }
  }, [currentVideoState.played, playMode]);

  //For updating movie, movie url and videoSliderDuration on change in activeImage
  useEffect(() => {
    if (activeImage) {
      const movie = searchpayload.movies.find((movieItem) => movieItem.name.toLowerCase() === activeImage.movie_name.toLowerCase());
      if (movie) {
        if (movie.url !== currentVideoState.url) {
          setVideoSliderDuration(movie.duration);
          setPlayerReady(false);
          setCurrentVideoState(val => ({...val, url: movie.url, movie: movie}));
        }
      }

      //For secondary player
      let activeImageIndex = cards.findIndex((cardEl) => cardEl.id === activeImage.id);
      let nextClip = cards[activeImageIndex+1];
      if (nextClip) {
        const movie = searchpayload.movies.find((movieItem) => movieItem.name.toLowerCase() === nextClip.movie_name.toLowerCase());
        if (movie) {
          if (movie.url !== secondaryVideoState.url) {
            setSecondaryVideoSliderDuration(movie.duration);
            setSecondaryPlayerReady(false);
            setSecondaryVideoState(val => ({...val, url: movie.url, movie: movie}));
          } else {
            secondaryPlayerRef.current?.seekTo(nextClip?.start_time/secondaryVideoSliderDuration || 0);
          }
        }
          
      }

    }
  }, [activeImage]);

  const handleSelectCardonRight = (card:any, index: number) => {
    setManuallyChanged(true);
    const movie = searchpayload.movies.find((movieItem) => movieItem.name.toLowerCase() === card.movie_name.toLowerCase());
    if (movie) {
      if (movie.url !== currentVideoState.url) {
        setActiveImage(card);
        setProductIndex(index + 1); 
            //For expading side cards description
            handleShowDesc(card.id, true);      
      } else {
          if (playerRef.current !== null) {
            playerRef.current.seekTo(card.start_time/videoSliderDuration);      
            setCurrentVideoState(val => ({...val, playing: true}));            
          }
      }
    }
  }

  useEffect(() => {
    if (!isActiveTab) {
      setCurrentVideoState(val => ({...val, playing: false}));  
      setLoadingVideo(false)   
    } 
  }, [isActiveTab, currentVideoState.playing]);

  useEffect(() => {
   setSortedArrayOnTime(cards.slice().sort((a,b) => Number(a.start_time) - Number(b.start_time)));
  }, [cards]);

  useEffect(() => {
    if(!(location.state?.option === 2 || withoutSearch)){
      let targetConfidences: string[] = [];
      if(cbLowConfidence){
        targetConfidences.push("low");
      }
      if(cbMediumConfidence){
        targetConfidences.push("medium");
      }
      if(cbHighConfidence){
        targetConfidences.push("high");
      }

      let filteredCards = sourceCards.filter((c)=> targetConfidences.some(tc => tc.toLowerCase() === c.confidence.toLowerCase()))
      
      if(filteredCards.length){
        setCards(filteredCards)
        let currentItem = filteredCards?.find((c)=> c.id === activeImage?.id)
        if(!currentItem){
          // setActiveImage(filteredCards[0])
          handleSelectCardonRight(filteredCards[0], 0)
        }
      }else{
        setCards([]);
        setActiveImage(null);
        setProductIndex(0); 
        setShowDescription(false); 
        setMovedhistogramProgress(false)
        setCurrentVideoState(val => ({...val, playing: false}))
      }
    }
  }, [cbLowConfidence,cbMediumConfidence,cbHighConfidence]);

  const getVariedConfidenceColor = ({confidence, similarity}: {confidence: string; similarity: number}) => {
    let desiredColor = '';
    if(!confidence || (similarity==0))
    {
      return "#F88C07"
    }
    switch (confidence.toLowerCase()) {
      case "high":
        desiredColor = highConfidenceColors.find((ccItem) => ccItem.similarity === similarity)?.color ?? '';
        break;
      case "medium":
        desiredColor = mediumConfidenceColors.find((ccItem) => ccItem.similarity === similarity)?.color ?? '';
        break;
      case "low":
        desiredColor = lowConfidenceColors.find((ccItem) => ccItem.similarity === similarity)?.color ?? '';
        break;    
      default:
        break;
    }
    return desiredColor;
  }

  const handleOpenCloseCardDetailDialog = (val:boolean) => {
      setOpenCardDetailDialog(val);
  };

  const handleClickPlay = () => {
      setToggler(val => !val);
      setProductIndex(selectedCardDetailDialogData.index + 1);
  }

  const handleClickMore = (dataObj: {type:string; value:any; autoplayVideo: boolean; index:number}) => {
      setselectedCardDetailDialogData(dataObj);
      setOpenCardDetailDialog(true);
  }

  const handleClickDebug = () => {
    setDebugMediaType(selectedCardDetailDialogData.type = "video");
    setDebugMediaId(selectedCardDetailDialogData.value.id);
    setOpenDebugDialog(true);
  };
  const handleShowDesc = (id:string, show:boolean)=>{
    setListDesc(prev => prev.map((listItem) => {
      if (!show) {
        listItem.showDesc = false;
      } else {
        if (listItem.id === id) {
          listItem.showDesc = true;
        } else {
          listItem.showDesc = false;
        }
      }
      return listItem;
    }));

  }

  //For playlist dialog
  const [openPlaylistDialog, setOpenPlaylistDialog] = useState<boolean>(false);
  const [selectedPlaylistDialog, setSelectedPlaylistDialog] = useState<{type:string; value:any}>({type: '', value: {}});

  const handleOpenPlaylistDialog = (val:boolean) => {
    setOpenPlaylistDialog(val);
  };
  const handleOpenCreatePlaylistDialog = (val:boolean) => {
    setOpenCreatePlaylistDialog(val);
  };
  const handleAddToPlaylist = (dataObj: {type:string; value:any}) => {
      setSelectedPlaylistDialog(dataObj);
      setOpenPlaylistDialog(true);
  }
  const handleBulkAddToPlaylist = (dataObj: {type:string; value:any}) => {
    setSelectedPlaylistDialog(dataObj);
    setOpenCreatePlaylistDialog(true);
  }
    
  function calculateHeight(cItem: any, cards: any[]){
    if(!cards.length || !cItem){
      return 0
    }
    let top10 = cards?.sort((a,b) => Number(a.start_time) - Number(b.start_time))
    let cArray = top10?.sort((a,b) => Number(b.similarity) - Number(a.similarity))
    
    return (cItem.similarity - cArray.sort((a,b) => b.similarity - a.similarity)[cArray.length-1].similarity)/((cArray.sort((a,b) => b.similarity - a.similarity)[0].similarity - cArray.sort((a,b) => b.similarity - a.similarity)[cArray.length-1].similarity)/37) + 5
  }

  const [watchlistData, updateWatchlist] = useWatchList() as any[];
  const [ItemsAddedInWatchlist, setItemsAddedInWatchlist] = useState<{ [key: string]: boolean }[]>(cards.map((item)=>{
    return ({[item.id]:false})
  }
  ));
  
  const addToWatchlist = (id:string) =>{
    setItemsAddedInWatchlist((prevState) => {
      return prevState.map((wlItem) => {
        return { ...wlItem, [id]: true };
      });
    });
    addWatchlistItem(id)
            .then((response) => {
              updateWatchlist();       
            })
            .catch((error) => {   
              setItemsAddedInWatchlist((prevState) => {
                  return prevState.map((wlItem) => {
                    return { ...wlItem, [id]: false };
                  });
                });
                errorToast(`Failed to update watchlist`);               
            })
  }

  return (
    <>
        {!(viewBy === 'clip') &&  <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: '32px', marginBottom: '12px'}}>
            <Box>
              <Typography sx={{fontSize: '20px', color: whiteColor, textTransform: 'capitalize'}}>
                {searchedInputForResult}
              </Typography>
              {!(location.state?.option === 2 || withoutSearch) && <Typography sx={{fontSize: '14px', color: greyColor}}>
                {item?.total} result found out of {showMediaCountResult? (tabType === "video" ? mediaCountResult.video_count : tabType === "dialogue" ? mediaCountResult.dialogue_count : "") : ""} records
              </Typography>}
            
            </Box>
          
          </Box>
        }
      <Grid container spacing={4} sx={{ mt: 0, justifyContent: 'center', display: viewBy === "clip" ? 'flex' : 'none' }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
          >
            
              <>
                <Grid container rowGap={{lg:0, md:0, sm:3, xs:3}} columnSpacing={{lg:3, md:3, sm:3, xs:3}} marginBottom={2} className="video-page">
                  <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
                    <Box className="query-text-section">
                      {searchedInputForResult}
                    </Box>
                    <Box className="video-box-header">
                      <Box className="left-section">
                        <Box className="movie-meta">
                          <Typography>
                            {currentVideoState.movie?.display_name}
                          </Typography> 
                          
                          <Box className="rating-box">
                            <img src="images/imdb.png" />
                            <Box className="rating-box-content">
                              <Typography className="rating-value"> {currentVideoState.movie?.rating}/</Typography>
                              <Typography className="rating-max-value">{currentVideoState.movie?.rating_from}</Typography>
                            </Box>

                          </Box>
                        </Box>
                        {/* {!(location.state?.option === 2 || withoutSearch) && <Box className="result-section">
                        {sortedMovieClips.length} result found out of {item?.total} records
                        </Box>} */}
                      </Box>
                      <Box className="right-section">
                        <ToggleButtonGroup
                          className="play-mode-toggle-group"
                          color="primary"
                          value={playMode}
                          exclusive
                          onChange={handlePlayModeChange}
                          aria-label="Platform"
                        >
                          <ToggleButton value={PlayerPlayMode.FULL_MOVIE_PLAY} className="first"> 
                            <Tooltip title={'Play any clip and allows to play full movie'} placement="top" arrow>
                              <span>
                                Full Movie Play
                              </span>
                            </Tooltip>
                          </ToggleButton>
                          <ToggleButton value={PlayerPlayMode.SINGLE_CLIP_PLAY}>
                            <Tooltip title={'Play only one clip at a time'} placement="top" arrow>
                                <span>
                                  Single Clip Play
                                </span>
                            </Tooltip>
                          </ToggleButton>
                          <ToggleButton value={PlayerPlayMode.AUTO_PLAY_CLIP} className="last">
                            <Tooltip title={'Automatically play all clips one by one'} placement="top" arrow>
                                <span>
                                  Clip Auto Play
                                </span>
                            </Tooltip>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </Box>
                    <Box className="video-box">
                      <Box sx={{
                        width: '100%', 
                        minHeight: '400px', 
                        background: '#000000',
                        position: 'relative'
                      }}
                      >
                        <ReactPlayer 
                        className="video-player"
                        ref={playerRef}
                        url={currentVideoState.url || undefined}
                        width={"100%"}
                        height={"100%"}
                        playing={currentVideoState.playing}
                        controls={currentVideoState.controls}
                        volume={volume}
                        muted = {muted}
                        onReady={() => {
                          setPlayerReady(true);
                        }}
                        onPlay={() => setCurrentVideoState(val => ({...val, playing: true}))}
                        onPause={() => {
                          setCurrentVideoState(val => ({...val, playing: false}));
                          setLoadingVideo(false);
                        }}
                        onEnded={() => {
                          setCurrentVideoState(val => ({...val, playing: false}));
                          setLoadingVideo(false);
                        }}
                        onProgress={(state) => setCurrentVideoState(val => ({...val, played: state.played}))}
                        onDuration={(duration) => setCurrentVideoState(val => ({...val, duration: duration}))}
                        onBuffer={()=> {
                          setLoadingVideo(true)
                        }}
                        onBufferEnd={()=> {
                          setLoadingVideo(false)
                        }}
                        />
                      {sourceCards.length && (loadingVideo || !playerReady) && <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "absolute",
                            top: '0px',
                            left: '0px',
                            right: '0px',
                            bottom: '0px',
                            width: "100%",
                            height: "100%",
                            zIndex: "1",
                            background: "rgba(0,0,0,1)",
                        }}
                        >
                        <CircularProgress sx={{color: whiteColor}} />
                      </Box>}
                        
                      </Box>
                      <Grid container 
                      className="video-player-controls"
                      spacing={4}>
                        <Box className="cotrol-line"></Box>
                        <Box className="video-player-controls-upper">
                          <IconButton 
                          className="play-btn"
                          aria-label="play-video" 
                          onClick={(event) => { 
                            if((playMode === PlayerPlayMode.SINGLE_CLIP_PLAY || playMode === PlayerPlayMode.AUTO_PLAY_CLIP) && !activeImage){
                              infoToast("Switch to Full Movie Play for continue playing the movie or select a video clip to play.")
                              event.preventDefault();
                              return;
                            }
                            if (!currentVideoState.url) {
                              // handleLoadMovie();
                            } else {
                              setCurrentVideoState(val => ({...val, playing: !val.playing}))                
                            }
                          }} 
                          title={!currentVideoState.playing ? 'Play' : 'Pause'}
                          >
                          {!currentVideoState.playing ? 
                            <img src={iconPlayVideo} alt="" />
                            : 
                            <img src={iconPauseVideo} alt="" />
                          }
                          </IconButton>
                          <Box className="volume-box"> 
                            {
                            muted?
                            <img src={iconMute} className="cursor-pointer" alt="" 
                            onClick={()=>{setMuted(false)}} />
                            :
                            (
                            volume < 0.5 ? 
                            <img src={iconHalfVolume} className="cursor-pointer" alt="" 
                            onClick={()=>{setMuted(true)}}  />
                            :
                            <img src={iconFullVolume} className="cursor-pointer" alt="" 
                            onClick={()=>{setMuted(true)}}  />
                            )
                            }
                            <input
                                className="volume-progress"
                                type="range"
                                min={0}
                                max={1}
                                step={0.02}
                                value={volume}
                                onChange={event => {
                                  setVolume(event.target.valueAsNumber)
                                  if(event.target.valueAsNumber == 0){
                                    setMuted(true)
                                  }else{
                                    setMuted(false)
                                  }
                                  if (playerRef.current !== null) {
                                   
                                  }
                                }}
                              />
                          </Box>
                          <Box className="controls-time">
                            {`${getFormatedTime(currentVideoState.played * currentVideoState.duration)} / ${getFormatedTime(currentVideoState.duration)}`}
                          </Box>
                          <Box className="right-controls">
                            <img className="full-screen" onClick={handleClickFullscreen} src={iconZoomVideo} alt="" />
                          </Box>
                        </Box>
                        <Box className="video-seeker-bar">
                          <div style={{height: '19px', background: 'rgba(255,255,255,0.12)', position: 'relative'}}>
                          {sortedMovieClips.map((cItem, index) => {
                          return <Tooltip key={cItem.id} title={`Confidence: ${cItem.confidence}`} placement="top" arrow>
                          <div 
                          style={{
                          position: 'absolute', 
                          top: '0px',
                          bottom: '0px', 
                          cursor: 'pointer', 
                          left: `${(100/videoSliderDuration)*cItem.start_time}%`, 
                          width: `${(100/videoSliderDuration)*(cItem.end_time - cItem.start_time)}%`, 
                          background: getVariedConfidenceColor({confidence: cItem.confidence, similarity: cItem.similarity}),
                          border: (cItem.id === activeImage?.id) ? '1px solid black' : '',
                          }} 
                          onClick={() => {
                            setManuallyChanged(true);
                            if (playerRef.current !== null) {
                              playerRef.current.seekTo(cItem.start_time/videoSliderDuration);  
                              setCurrentVideoState(val => ({...val, playing: true}));            
                            }
                          }}
                          key={index}
                          >
                          </div>
                          </Tooltip>
                          })}
                          </div>
                          <Slider
                            className="video-slider-seeker"
                            sx={[{height: '6px', padding: '0', display: 'flex'}, (theme) => ({'& .MuiSlider-track': {border: 'none'}, '& .MuiSlider-thumb': {width: '12px', height: '12px', color: whiteColor}, '& .MuiSlider-rail': {height: '6px', borderRadius: 0, opacity: 1}})]}
                          aria-labelledby="video-play-range-slider"
                          getAriaValueText={(valuetext) => `${valuetext}s`}
                          value={currentVideoState.played}
                          onChange={(event, value, activeThumb) => {
                          event.preventDefault(); 
                          if (playMode === PlayerPlayMode.AUTO_PLAY_CLIP || playMode === PlayerPlayMode.SINGLE_CLIP_PLAY) {
                            // toast.info(`Switch to Full Movie Play for seeking the movie`);
                            return;
                          }
                          if (event.type === "mousedown") {
                            setManuallyChanged(true)
                            setCurrentVideoState(val => ({...val, seeking: true})); 
                            if (playerRef.current !== null) {
                            playerRef.current.seekTo(value as number);                  
                            setCurrentVideoState(val => ({...val, playing: true}));            
                          }
                            return;         
                          }              
                          if (event.type === "mousemove") {
                            setManuallyChanged(true)
                            setCurrentVideoState(val => ({...val, seeking: false}));   
                            if (playerRef.current !== null) {
                              playerRef.current.seekTo(value as number);                  
                            }
                            
                          }
                          setCurrentVideoState(val => ({...val, played: value as number}));                
                          }}
                          onChangeCommitted={(event, value) => {
                            if (playMode === PlayerPlayMode.AUTO_PLAY_CLIP || playMode === PlayerPlayMode.SINGLE_CLIP_PLAY) {
                              infoToast(`Switch to Full Movie Play for seeking the movie or click on any video clip to play.`);
                            }  
                          }}
                          min={0}
                          max={0.999999}
                          step={0.000001}
                          valueLabelDisplay="auto"
                          valueLabelFormat={(x) => getFormatedTime(x * currentVideoState.duration)}
                          />
                        </Box>
                      </Grid>
                      <Box 
                        sx={{width: 'calc(100% - 46px)', display: 'flex', justifyContent: 'center', columnGap: '24px', margin: '6px 21px 0px', 
                        position: 'relative',
                        }}
                      >
                        <div 
                          style={{
                            cursor: 'pointer', touchAction: 'none', color: '#1976d2', 
                            padding: '0px', 
                            overflow: 'hidden', 
                            width: `100%`
                          }}
                        >
                          <div style={{height: location.state?.option === 2 ? '100px' : '100px', display: 'flex', alignItems: 'flex-end', justifyContent: 'center', width: '100%'}}>
                              <Swiper
                                  direction={'horizontal'}
                                  slidesPerView={7}
                                  navigation={true}
                                  modules={[Navigation]}
                                  className="histogram-swiper"
                                  centeredSlides={true}
                                  centeredSlidesBounds={true}
                                  ref={histogramSliderRef}
                                  allowTouchMove = {false}
                              >
                                  {sortedMovieClips.map((cItem, index) => {
                                  return (
                                  <Tooltip key={cItem.id} title={`Confidence: ${cItem.confidence}`} placement="top" arrow>
                                    <SwiperSlide key={cItem.id}>
                                  <div 
                                  className={`timeline-box ${(cItem.id === activeImage?.id) ? 'active' : ''}`}
                                  style={{
                                  position: 'relative', 
                                  cursor: 'pointer', display: 'flex',
                                  width: `103px`, 
                                  maxWidth: '103px',
                                  }} 
                                  onClick={(event) => {
                                    if(movedhistogramProgress)
                                    {
                                      setMovedhistogramProgress(false)
                                      return 
                                    }
                                    event.preventDefault()
                                    event.stopPropagation()
                                    setManuallyChanged(true);
                                  if (playerRef.current !== null) {
                                      playerRef.current?.seekTo(cItem.start_time/videoSliderDuration);                  
                                      setCurrentVideoState(val => ({...val, playing: true}));            
                                    }
                                  }}
                                  key={index}
                                  >
                                    <div className="upper-box">
                                      <div className="duration-box" style={{display: (cItem.id === activeImage?.id) ? 'none' : 'block'}}>
                                        {getFormatedDuration(cItem.start_time, cItem.end_time)}
                                      </div>
                                      <div className="histogram-bg-parent">    
                                        <img src={cItem.thumbnail_url} style={{}} />                                  
                                        <div className="histogram-bg" style={{
                                          height: `${50}px`,
                                          display: (location.state?.option === 2 || withoutSearch) ? 'none' : 'flex',
                                          alignItems : "flex-end",
                                        }}>
                                          <div
                                            style={{
                                              width: '100%',
                                              height: (location.state?.option === 2 || withoutSearch) ? '0px' : `${calculateHeight(cItem, [...cardData])}px`,
                                              background: getVariedConfidenceColor({confidence: cItem.confidence, similarity: cItem.similarity}),
                                            }}
                                          ></div>
                                        </div>
                                      </div>

                                    </div>

                                    <div className={`${cItem.confidence ? cItem.confidence?.toLowerCase() === 'low' ? 'bottomBox-confidence-low' : cItem.confidence?.toLowerCase() === 'medium' ? 'bottomBox-confidence-medium' : 'bottomBox-confidence-high' : 'bottomBox-noConfidence' }`} style={{
                                      fontSize: '10px', padding: '4px 0px', 
                                      color: (cItem.id === activeImage?.id) ? cItem.confidence ? getConfidenceColoByConfidence(cItem.confidence) : '#F88C07' : '#FFF',
                                      textAlign: 'center'}}>
                                      {getFormatedTime(cItem.start_time)}{' - '}
                                      {getFormatedTime(cItem.end_time)}
                                    </div>
                                    {(cItem.id === activeImage?.id) && <div style={{position: 'absolute', left: "-5px", right: 0, top: '0px', width: 'calc(100% + 3px)', display: 'flex', zIndex:"10"}}>
                                      <Slider
                                          className= {(location.state?.option === 2 || withoutSearch) ? 'histogram-slider smaller-view': 'histogram-slider'}
                                          aria-labelledby="video-playing-range-slider"
                                          getAriaValueText={(valuetext) => `${valuetext}s`}
                                          value={currentVideoState.played}
                                          min={cItem.start_time/videoSliderDuration}
                                          max={cItem.end_time/videoSliderDuration}
                                          step={0.000001}
                                          onChangeCommitted={(event, value)=>{
                                            setCurrentVideoState(val => ({...val, playing: true})); 
                                            
                                          }}
                                          onChange={(event, value)=>{
                                            if(value as number < (cItem.end_time/videoSliderDuration)){
                                              if (event.type === "mousedown") {
                                                setManuallyChanged(true)
                                                if (playerRef.current !== null) {
                                                    setCurrentVideoState(val => ({...val, seeking: true, playing: false, played: value as number})); 
                                                    playerRef.current.seekTo(value as number);                          
                                                  }
                                              }              
                                              if (event.type === "mousemove") {
                                                  setManuallyChanged(true)
                                                  if (playerRef.current !== null) {
                                                    setCurrentVideoState(val => ({...val, seeking: false, playing: false, played: value as number})); 
                                                    playerRef.current.seekTo(value as number);                  
                                                  }
                                              }
                                              setMovedhistogramProgress(true)
                                              event.preventDefault();
                                              event.stopPropagation();
                                            }
                                          }}
                                          valueLabelFormat={(x) => getFormatedTime(x * currentVideoState.duration * 1000)}
                                      />
                                  </div>}                                                           
                                  </div>
                                  <div style={{display: index === cards.length-1 ? 'none' : 'inline-block', width: '12px', minWidth: '12px'}}></div>
                                  </SwiperSlide>
                                  </Tooltip>
                                  )
                                  })}
                        </Swiper>
                        </div>  
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                  <div className="video-desciption-box" ref={sideListRef}>
                    {
                      !(location.state?.option === 2 || withoutSearch) && <Box className="result-list-header-section">
                        <h4>
                        Result {item.total}/{showMediaCountResult? (tabType === "video" ? mediaCountResult.video_count : tabType === "dialogue" ? mediaCountResult.dialogue_count : "") : ""}
                        </h4>        
                        {!(location.state?.option === 2 || withoutSearch) && 
                        <img src={iconRefresh} className="refresh-icon" onClick={()=>{setCards(cardData)}} />
                        }
                        <Box className="confidence-filter-area">
                        <FormGroup>
                          <FormControlLabel 
                          control={
                            <Checkbox
                            icon={<img src={iconCheckboxUnchecked} alt="" />}
                            checkedIcon={<img src={iconCheckboxChecked} alt="" />}
                            checked={cbLowConfidence}
                            size="small"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>)=>{
                              setCbLowConfidence(event.target.checked);
                            }}
                            />
                          } 
                          label={<Typography sx={{
                            color: getConfidenceColoByConfidence("low"),
                          }} variant="body2" color="textSecondary">Low</Typography>}
                          />
                          <FormControlLabel control={
                            <Checkbox
                            icon={<img src={iconCheckboxUnchecked} alt="" />}
                            checkedIcon={<img src={iconCheckboxChecked} alt="" />}
                            checked={cbMediumConfidence}
                            size="small"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>)=>{
                              setCbMediumConfidence(event.target.checked);
                            }}
                            />
                          }   
                          label={<Typography sx={{
                            color: getConfidenceColoByConfidence("medium"),
                            
                          }} variant="body2" color="textSecondary">Medium</Typography>}
                          />
                          <FormControlLabel control={
                            <Checkbox
                            icon={<img src={iconCheckboxUnchecked} alt="" />}
                            checkedIcon={<img src={iconCheckboxChecked} alt="" />}
                            checked={cbHighConfidence}
                            size="small"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>)=>{
                              setCbHighConfidence(event.target.checked);
                            }}
                            />
                          } 
                          label={<Typography sx={{
                            color: getConfidenceColoByConfidence("high"),
                            
                          }} variant="body2" color="textSecondary">High</Typography>}
                           />
                        </FormGroup>
                        </Box>
                      </Box>
                    }
                    {canAddToPlaylist && 
                    <div className="playlist-container">
                    <DropContainer>
                    <div className="add-to-playlist" style={cards.length < 1 ? {pointerEvents: 'none'} : {}}>
                      <label onClick={(event) => {handleAddToPlaylist({type: 'default', value: activeImage})}} className="custom-file-upload">
                        <span>
                          <strong>
                            Add to Playlist
                          </strong>
                          <em>
                            (Drag and Drop)
                          </em>
                        </span>
                      </label>
                  </div>
                  </DropContainer>
                  {!(location.state?.option === 2 || withoutSearch) &&
                  <button 
                    className="create-playlist" 
                    onClick={(event) => handleBulkAddToPlaylist({ type: 'default', value: cards })}
                    disabled={cards.length < 1}
                    >
                    <div className="icon-plus">
                    </div>
                    <div className="create-text">
                    Create Playlist
                    </div>
                  </button>}
                  
                  {/* <button className="btn-share">
                    <img src={iconBtnShare} alt="" />
                  </button>  */}
                  {/* </div>
                  }
                  {!(location.state?.option === 2 || withoutSearch) && <div className="refresh">
                  <button className="btn-refresh" onClick={(event) => setCards(cardData)}>
                    Refresh
                  </button>  */}
                  </div>}
                  {cards.length < 1 ?
                    <>
                      <CardContent sx={{flexGrow: 1, display: showDescription ? 'none' : 'flex', flexDirection: 'column', padding: '32px', paddingTop: 0, opacity: !showDescription ? 1 : 0, transition: 'opacity 1s ease', minHeight: '515px' }} >
                        <Typography sx={{fontSize: '16px', color: whiteColor, marginLeft: '-30px'}}>
                          No video clip found.
                        </Typography>
                        <Typography sx={{fontSize: '14px', mt: 5}}>
                        </Typography>
                      </CardContent>
                    </>
                  :
                  <ol className="video-desc-list">
                  {
                  cards.map((item, index)=>{
                  const getSeconds =  Math.trunc(Number(item.end_time) - Number(item.start_time));
                  const getTime =   getFormatedTime(getSeconds);
                  const getStartTime = getFormatedTime(item.end_time);
                  const getEndTime = getFormatedTime(item.start_time);
                  return(
                    <DragBox key={item.id} itemObj={item} onDragEnd={(itemObj) => handleAddToPlaylist({type: 'default', value: itemObj}) }>
                      <li key={item.id} id={`Z${item.id}`} className={(item.id === activeImage?.id) ? "timeline-right-list selected":"timeline-right-list"}
                      onClick={() => {
                            handleSelectCardonRight(item, index);
                          }
                        }
                      >     
                        <div className="icon-full-desc" onClick={(event)=> {event.preventDefault();event.stopPropagation(); handleShowDesc(item.id, listDesc.find((lItem) => lItem.id === item.id).showDesc ? false : true);} }>
                          {listDesc.find((lItem) => lItem.id === item.id).showDesc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </div>
                        <div className="small-list">
                          <div className="video-icon">
                            <div className="video-time">
                              {getTime}
                            </div>
                            <div className="icon-play-video">
                              <img src={iconPlayVideoOnHover} alt="" />
                            </div>
                            <img src={item.thumbnail_url} />
                            {(activeImage?.id === item.id) && <div style={{position: 'absolute', left: 0, right: 0, bottom: '0px', width: '100px', display: 'flex'}}>
                              <Slider
                                  sx={[{padding: '0px', color: '#989898'}, (theme) => ({'&.MuiSlider-colorPrimary': {padding:'0'}, '& .MuiSlider-track': {height: '3px', color: '#FD0101', borderRadius: '0px 0px 0px 3px', border: 'none'}, '& .MuiSlider-thumb': {display: 'none'}, '& .MuiSlider-rail': {height: '3px', borderRadius: '0px 0px 3px 3px'}})]}
                                  aria-labelledby="video-playing-range-slider"
                                  getAriaValueText={(valuetext) => `${valuetext}s`}
                                  value={currentVideoState.played}
                                  min={item.start_time/videoSliderDuration}
                                  max={item.end_time/videoSliderDuration}
                                  step={0.000001}
                                  valueLabelDisplay="auto"
                                  valueLabelFormat={(x) => getFormatedTime(x * currentVideoState.duration * 1000)}
                              />
                            </div>}                          
                          </div>
                          <div className="video-desc">
                            <h3>{tabType === "dialogue"? (item.desc?.length > 45 ? (item.desc.substring(0, 42) + "...") : item.desc):item.title}</h3>
                            <h4>
                              <div className="video-desc-inner-content">
                                <div className="first-line">
                                  <Tooltip title={searchpayload.movies?.find((m) => m.name.toLowerCase() == item.movie_name.toLowerCase())?.display_name} placement="top" arrow>
                                    <span className="movie" style={{width: !(location.state?.option === 2 || withoutSearch)? '100%': '40%' }}>
                                      {searchpayload.movies?.find((m) => m.name.toLowerCase() == item.movie_name.toLowerCase())?.display_name}
                                    </span>
                                  </Tooltip>
                                      <span className="cateogry-name">
                                        {
                                        item.categories.map((c:string,ci:number)=>{
                                            return (
                                              <Tooltip key={ci} title={c} placement="top" arrow>
                                              <span key={ci} style={{color: item.confidence ? getConfidenceColoByConfidence(item.confidence) : '#F88C07'}}> {c} </span>
                                              </Tooltip>
                                            )}
                                            ) 
                                        }
                                      </span>
                                    
                                </div>
                                <div className="second-line">
                                  <span style={item.confidence ? {} : {width: '40%', marginRight: '10px'}}>
                                    <span className="time">{getEndTime} - {getStartTime}</span>
                                  </span>
                                  {
                                  !(location.state?.option === 2 || withoutSearch)?
                                    item.confidence && (
                                      <Box
                                        className="confidence-level"
                                        sx={{
                                          color: getConfidenceColoByConfidence(item.confidence),
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {item.confidence}
                                      </Box>
                                    )
                                    : ""                                
                                  }
                                  {showWatchlistButtonInListViewCard ? ItemsAddedInWatchlist.find((wlItem) => wlItem.hasOwnProperty(item.id))?.[item.id] || isInWatchlist(item.id,watchlistData)?
                                    <IconButton
                                      aria-label="add to favorites"
                                      className="btn-fav"
                                      sx={{cursor:"unset"}}
                                      onClick={async (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                      }}
                                      >
                                        <img src={iconWatchlist} alt="Red Watchlist Icon" />
                                      </IconButton>:
                                      <IconButton
                                      aria-label="add to favorites"
                                      className="btn-fav"
                                      onClick={async (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        addToWatchlist(item.id);
                                      }}
                                      >             
                                        <img src={iconWatchlistBlack} alt="Black Watchlist Icon" />
                                      </IconButton> : ''
                                  }

                                  {showDebugButtonInListViewCard && <IconButton
                                    aria-label="debug"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setDebugMediaType((tabType === "dialogue")? "dialogue":"video");
                                      setDebugMediaId(item.id);
                                      setOpenDebugDialog(true);
                                    }}
                                    className="btn-bug"
                                  >
                                    <img src={iconBug} alt="" />
                                  </IconButton>}

                                  {!(location.state?.option === 2 || withoutSearch) && cards.length > 1 && <div className="icon-card-close" onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          handleRemoveCards(item.id);
                                        }}></div>  
                                  }   
                                </div>
                              </div>
                              
                            </h4>
                          </div>
                        </div>
                        <div className="full-desc" style={{display:listDesc.find((lItem) => lItem.id === item.id).showDesc ? 'block' : ''}}>
                          {item.desc}
                        </div>
                      </li>
                    </DragBox>
                  )
                  })
                  }
                  </ol>
                  }
                  </div>
                  </Grid>
                </Grid>

              </>          
          </Grid>
      </Grid>
       <div style={{display: (viewBy === "list") ? 'block' : 'none'}}>
       <CardsContainer 
         cards={cards}
         type={tabType}
         component={"video"}
         showTimelineButton={showTimelineButtonInListViewCard}
         showWatchlistButton={showWatchlistButtonInListViewCard}
         showPlaylistButton={showPlaylistButtonInListViewCard}
         showDebugButton={showDebugButtonInListViewCard}
         handleMediaClick={(card, index) => {
          setProductIndex(index + 1);
          setToggler((val) => !val);  
         }}
         handleDebugClick={(card, index) => {
          setDebugMediaType(tabType);
          setDebugMediaId(card.id);
          setOpenDebugDialog(true);
         }}
         handleClickMore={(card, index, dataObj) => handleClickMore({...dataObj, "index": index})}
         handleAddToPlaylistClick={(card, index) => {
          handleAddToPlaylist({type: 'default', value: card})
         }}
         handleClickTimeline={(card, index) => handleClickTimeline(card, index)}
         searchpayload={searchpayload}
       />
       </div>
      <FsLightbox
        toggler={toggler}
        sources={cards?.map((cItem) => cItem.url)}
        slide={productIndex}
        type={"video"}
      />

      {openCardDetailDialog && 
        <CardDetailDialog 
            openCardDetailDialog={openCardDetailDialog}
            handleOpenCloseCardDetailDialog={handleOpenCloseCardDetailDialog}
            selectedCardDetailDialogData={selectedCardDetailDialogData}
            showDebugButton={showDebugButtonInListViewCard}
            showWatchlistButton={showWatchlistButtonInListViewCard}
            handleClickPlay={handleClickPlay}
            handleClickDebug={handleClickDebug}
            searchpayload={searchpayload}
        />
      }
      
      {openDebugDialog && <DebugDialog 
        openDialog={openDebugDialog}
        handleOpenDialog={(val) => setOpenDebugDialog(val)}
        mediaId={debugMediaId}
        mediaType={debugMediaType}
        searchpayload={searchpayload}
      />}

      {openPlaylistDialog && 
            <PlaylistDialog 
                openPlaylistDialog={openPlaylistDialog}
                handleOpenClosePlaylistDialog={handleOpenPlaylistDialog}
                selectedPlaylistDialogData={selectedPlaylistDialog}
                handleCreatePlaylist = {()=>{}}
            />
          }

      {openCreatePlaylistDialog &&
                <PlaylistDialog
                    openPlaylistDialog={openCreatePlaylistDialog}
                    handleOpenClosePlaylistDialog={handleOpenCreatePlaylistDialog}
                    selectedPlaylistDialogData={selectedPlaylistDialog}
                    addPlaylistCase={true}
                    bulkInsert={true}
                    handleCreatePlaylist={()=>{}}
                />
            }

          {(playMode === PlayerPlayMode.AUTO_PLAY_CLIP) && <ReactPlayer 
            ref={secondaryPlayerRef}
            url={secondaryVideoState.url || undefined}
            width={"300px"}
            height={"300px"}
            playing={true}
            controls={true}
            volume={0}
            muted = {true}
            style={{display: 'none'}}
            onReady={() => {
              setSecondaryPlayerReady(true);
            }}
            // onPlay={() => setCurrentVideoState(val => ({...val, playing: true}))}
            // onPause={() => {
            //   setCurrentVideoState(val => ({...val, playing: false}));
            //   setLoadingVideo(false);
            // }}
            // onEnded={() => {
            //   setCurrentVideoState(val => ({...val, playing: false}));
            //   setLoadingVideo(false);
            // }}
            onProgress={(state) => setSecondaryVideoState(val => ({...val, played: state.played}))}
            onDuration={(duration) => setSecondaryVideoState(val => ({...val, duration: duration}))}
            // onBuffer={()=> {
            //   setLoadingVideo(true)
            // }}
            // onBufferEnd={()=> {
            //   setLoadingVideo(false)
            // }}
          />}

    </>
  );
}

