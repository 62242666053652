import React, { useContext, useEffect, useRef, useState } from "react";

import MediaCard from "../MediaCard";
import { Grid } from "@mui/material";
import { ISearchPayload } from "../../types/interface";

interface ICardsContainerProps {
    cards:any;
    type: string;
    component: string;
    showTimelineButton?: boolean;
    showWatchlistButton?: boolean;
    showPlaylistButton?: boolean;
    showDebugButton?: boolean;
    handleMediaClick: (card:any, index:number) => void;
    handleDebugClick: (card:any, index:number) => void;
    handleClickMore: (card:any, index:number, dataObj:any) => void;
    handleAddToPlaylistClick: (card:any, index:number) => void;
    handleClickTimeline: (card:any, index:number) => void;
    searchpayload: ISearchPayload;
  }
  
export default function CardsContainer({ 
    cards, 
    type, 
    component, 
    showTimelineButton, 
    showWatchlistButton, 
    showPlaylistButton, 
    showDebugButton, 
    handleMediaClick, 
    handleDebugClick, 
    handleClickMore, 
    handleAddToPlaylistClick,
    handleClickTimeline,
    searchpayload
}: ICardsContainerProps) {
    return (
        <Grid container spacing={{xs: 0, sm: 1, md: 1, lg: 3, xl: 5.5}} sx={{ mt: 0 }} className="">
            {cards?.map((card:any, index:number) => (
                <Grid
                    item
                    key={index}
                    xs={12}
                    sm={6}
                    md={type === "video" ? 4 : 4}
                    lg={type === "video" ? 3 : 3}
                    xl={type === "video" ? 3 : 3}
                >
                    <MediaCard cardType={type} card={card} component={component} 
                        showTimelineButton={showTimelineButton} 
                        showWatchlistButton={showWatchlistButton}
                        showPlaylistButton={showPlaylistButton}
                        showDebugButton={showDebugButton}
                        handleMediaClick={() => handleMediaClick(card, index)} 
                        handleDebugClick={() => handleDebugClick(card, index)} 
                        handleClickMore={(dataObj) => handleClickMore(card, index, dataObj)}
                        handleAddToPlaylistClick={() => handleAddToPlaylistClick(card, index)}
                        handleClickTimeline={() => handleClickTimeline(card, index)}
                        searchpayload={searchpayload}
                    />                    
                </Grid>
            ))}
        </Grid>
    );
}