import { Avatar, Box, Button, Card, CardContent, CardMedia, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { errorToast, getConfidence, getConfidenceColoByConfidence, getConfidenceColor } from "../../common/utils";
import { PestControlOutlined } from "@mui/icons-material";
import { blackColor, whiteColor } from "../../common/constants";
import iconWatchlistBlack from "../../assets/card-icon-watchlist-black.svg";
import iconWatchlist from "../../assets/card-icon-watchlist.svg";
import { addWatchlistItem, isInWatchlist } from "../../services/searchService";
import { toast } from "react-toastify";
import { useWatchList } from "../../hooks/useWatchList";
import {IconFavSvg, IconBugSvg} from "../../common/utils/icons";

export default function ImageCard({ 
  card, 
  handleMediaClick, 
  handleDebugClick, 
  cards, 
  showConfidence,
  showDebugButton,
  showWatchlistButton
}: { 
  card: any; 
  handleMediaClick: () => void; 
  handleDebugClick: () => void; 
  cards: any[]; 
  showConfidence:boolean;
  showDebugButton: boolean;
  showWatchlistButton: boolean;
}) {
  const [watchlistData, updateWatchlist] = useWatchList() as any[];
  const [ItemsAddedInWatchlist, setItemsAddedInWatchlist] = useState<{ [key: string]: boolean }[]>(cards.map((item) => {
    return ({ [item.id]: false })
  }
  ));

  const addToWatchlist = (id: string) => {
    setItemsAddedInWatchlist((prevState) => {
      return prevState.map((wlItem) => {
        return { ...wlItem, [id]: true };
      });
    });
    addWatchlistItem(id)
      .then((response) => {
        updateWatchlist();
      })
      .catch((error) => {
        setItemsAddedInWatchlist((prevState) => {
          return prevState.map((wlItem) => {
            return { ...wlItem, [id]: false };
          });
        });
        errorToast(`Failed to update watchlist`);
      })
  }

  return (

    <Box onClick={() => handleMediaClick()} sx={[{ width: 'inherit', height: '463px', borderRadius: '8px', background: `url(${card?.url}) center/cover no-repeat`, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'relative', cursor: 'pointer' }, (theme) => ({ '.similarity-overlay': { backdropFilter: 'blur(17px)' }, ':hover .overlay': { height: '123px', backdropFilter: 'blur(17px)' } })]}>
      <Box sx={{ marginTop: '20px', paddingLeft: '16px', paddingRight:"16px", display:"flex", justifyContent:"space-between" }}>
        {showConfidence && <Button variant="outlined" size="small" sx={{ marginLeft: '0px', borderRadius: '50px', border: 'none', color: blackColor, background: getConfidenceColoByConfidence(card?.confidence), textTransform: 'capitalize', marginRight: '18px', ':hover': { border: 'none', color: blackColor, background: getConfidenceColoByConfidence(card?.confidence) } }}>{card?.confidence}</Button>}
          <Box className="buttons-group-svgicons">{showWatchlistButton ? ItemsAddedInWatchlist.find((wlItem) => wlItem.hasOwnProperty(card?.id))?.[card.id] || isInWatchlist(card?.id, watchlistData) ?
          <IconButton
            aria-label="watchlist"
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            className="btn-fav selected"
          >
            <IconFavSvg />
          </IconButton> :
          <IconButton
            aria-label="watchlist"
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              addToWatchlist(card.id);
            }}
            className="btn-fav"
          >
            <IconFavSvg />
          </IconButton> : ''
        }
        {showDebugButton && <IconButton aria-label="debug" onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleDebugClick() }} className="btn-bug-svg"><IconBugSvg /></IconButton>}
        </Box>
      </Box>
    </Box>
  );

}