import { AppBar, Box, Chip, IconButton, Toolbar, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import iconMovieOpenCogOutline from '../../assets/movie-open-cog-outline_1.svg';
import "./index.css";

export default function Header(props:{sidebarOpen: boolean; handleDrawerOpenClose: () => void; children:any;handleUpdateSearchPayload: (field:any) => void;}) {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [profile, setProfile] = useState<any>(user);
    const [isProfileLoaded, setIsProfileLoaded] = useState<boolean>(true);
    const [anchorEl, setAnchorEl] = useState<boolean>(false);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(!anchorEl);
    };

    return (
        <>
        <Box className="header" sx={{background:"#070110", right: 'auto', width:"100%", zIndex: (theme) => theme.zIndex.drawer + 1}} position="fixed">
            <AppBar className="main-header container"  sx={{ background: props.sidebarOpen ? 'transparent' : 'transparent', position:"static", boxShadow: 'none' }}>
                <Toolbar sx={{height: '100%', justifyContent: 'space-between', padding:'0'}}>
                    <Box sx={{display: 'flex', alignItems: 'center', width:'100px'}}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => props.handleDrawerOpenClose()}
                            sx={{ marginLeft: '8px', marginRight: '23px' }}
                        >
                        </IconButton>
                        <Link style={{listStyle: 'none', textDecoration: 'none', color: 'inherit'}} to="/" onClick={() => props.handleUpdateSearchPayload({text: ''})}>
                            <img src="/images/logo_only.png" alt=".." style={{width: 23, display:"block"}} />
                        </Link>
                    </Box>
                    <Box className="header-middle-container">
                        {props.children} 
                        <Box className="generate-video-icon-container" onClick={() => navigate("/generate-video")}>
                            <img src={iconMovieOpenCogOutline} alt="" className="" />
                        </Box>
                    </Box>
                    {isProfileLoaded?
                    <>
                        <Box sx={{width:'100px', display:'flex', justifyContent:'flex-end'}} >
                            <div  className="profile-right-icon">
                                <button onClick={handleClick} className="profile-image-btn">
                                    <img src={profile?.picture}
                                        alt=""
                                        style={{
                                        height: '40px',
                                        width: '40px',
                                        borderRadius: '100px',
                                        border: '1px solid #303030',
                                        display:'block',
                                        cursor:'pointer',
                                        }}
                                    />
                                </button>
                                <ul className="profile-drop-down" style={{display: anchorEl ? 'block' : 'none'}}>
                                    <li>
                                        <div className="profile-row">
                                            <img src={profile?.picture} className="profile-image" alt={`${profile?.first_name} &nbsp; ${profile?.last_name}`} />
                                            <div className="profile-desc">
                                                <h3>
                                                    {`${profile?.first_name}  ${profile?.last_name}`}
                                                </h3>
                                                <h4>
                                                    {profile?.email}
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <button className="btn-logout" onClick={(event)=>{
                                            localStorage.clear()
                                            window.location.replace("/")
                                        }}
                                        >
                                            Sign Out
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </Box>
                    </>
                        // <Chip 
                        // variant="outlined" 
                        // // icon={<AccountCircleOutlined sx={{fontSize: '36px', marginLeft: '-3px!important', color: `${whiteColor}!important`}} />} 
                        // icon={<img src={profile.picture}
                        //         style={{
                        //             fontSize: '36px',
                        //             marginLeft: '0px!important',
                        //             height: '30px',
                        //             width: '30px',
                        //             borderRadius: '50%',
                        //             color: `${whiteColor}!important`}} />} 
                        // label={profile.name}
                        // sx={{color: whiteColor, marginRight: '20px', cursor: 'pointer'}}
                        // onClick={() => {}} 
                        // />
                    : 
                    // <Chip 
                    //     variant="outlined" 
                    //     icon={<AccountCircleOutlined sx={{fontSize: '36px', marginLeft: '-3px!important', color: `${whiteColor}!important`}} />} 
                    //     label={"..."}
                    //     sx={{color: whiteColor, marginRight: '20px', cursor: 'pointer'}}
                    //     onClick={() => {}} 
                    //     />
                    <div style={{
                        height: '40px'
                    }}>
                        <LoadingButton />
                    </div>

                    }
                </Toolbar>
            </AppBar>     
            </Box>   
        </>
    );
}