import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import { Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import assetStaticImage from '../../assets/asset_static.png';
import iconPlay from '../../assets/iconPlay.svg';
import { MoreVert } from "@mui/icons-material";
import videoCameraGif from '../../assets/video_camera.gif';
import videoFailedImage from '../../assets/video_failed.png';
import moment from "moment";
import { VIRTUAL_VIDEO_STATUS } from "../../types/interface";
import { errorToast, getFormatedTime, successToast } from "../../common/utils";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import iconCheck from '../../assets/icon-check.svg';
import iconClose from '../../assets/icon-close.svg';
import { toast } from "react-toastify";
import { updateVideoStatus } from "../../services/searchService";
import { generatingVideoStatusMessage } from "../../common/constants";

const options = [
    'Rename',
    'Share',
    'Remove'
];

const ITEM_HEIGHT = 48;

interface IAssetCardProps {
    assetData: any;
    userlist: any[];
    handleClickShare: (resource:any) => void;
    handleGetVideoList: () => void;
    handleClickDelete: (resource:any) => void;
    handleUpdateVideoListLocallyAfterRename: (newObj:any) => void;
}

export default function AssetCard(props: IAssetCardProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const [commonContext] = useOutletContext<any | null>();
    const { searchpayload } = commonContext;
    const { assetData, userlist, handleClickShare, handleGetVideoList, handleClickDelete, handleUpdateVideoListLocallyAfterRename } = props;
    const shared_by_user = userlist?.find((userItem) => userItem.id === assetData?.shared_by);

    const [renameLoading, setRenameLoading] = useState<boolean>(false);
    const [renameText, setRenameText] = useState<string>('');
    const [showRenameField, setShowRenameField] = useState<boolean>(false);
    
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
      
    const handleClickRename = useCallback(() => {
        setRenameText(assetData?.name);
        setShowRenameField(true);
    }, [assetData]);

    const handleCancelRename = useCallback(() => {
        setRenameText('');
        setShowRenameField(false);
    }, []);


    const handleSubmitRename = useCallback(() => {
        if (!renameText.length) {
            errorToast('Name cannot be empty!');
            return;
        }
        // setIsloading(true);
        const reqBody = {
            name: renameText
        };
        setRenameLoading(true);
        updateVideoStatus(assetData?.id ?? '', reqBody)
            .then((response) => {
                successToast('Video renamed successfully!');
                setRenameLoading(false);
                handleCancelRename();
                handleUpdateVideoListLocallyAfterRename({...assetData, name: renameText});
                // setIsloading(false);
            })
            .catch((err) => {
                errorToast('Failed to rename video');
                setRenameLoading(false);
                // setIsloading(false);
            })
    }, [renameText, assetData]);

    return (
        <Box 
            className="asset-card-container" 
        >
            <Box className="asset-card-image-container"
                onClick={() => {
                    navigate(`/generated-video/${assetData?.id}`, { state: { from: location } });
                }}
            >
                {assetData?.status === VIRTUAL_VIDEO_STATUS.COMPLETED ? 
                <>
                    {/* <img src={assetStaticImage} alt="" className="asset-card-image" /> */}
                    <video className="asset-card-video" key={assetData?.id}>
                        <source src={assetData?.url} type="video/mp4"></source>
                    </video>
                    <Box className="asset-card-duration-box">{getFormatedTime(assetData?.duration)}</Box>
                    {(assetData?.owned && !assetData?.viewed) && <Box className="asset-card-alert-box">{'New'}</Box>}
                    <img src={iconPlay} alt="" className="asset-card-play-image" />
                </>
                :
                <Box className="gvb-video-inner">
                    <Box className="gvb-video-content-container">
                        {assetData?.status === VIRTUAL_VIDEO_STATUS.FAILED ?
                            <>
                                <img src={videoFailedImage} alt="" style={{width: '34px'}} />
                                <Typography sx={{fontSize: '16px'}}>Result unavailable</Typography>
                                <Typography sx={{fontSize: '12px', color: '#C5C5C5', marginTop: '7px', textAlign: 'center'}}>
                                    Video generation is failed due to some error.
                                </Typography>                                                    
                            </>
                            : 
                            <>
                                <img src={videoCameraGif} alt="" style={{width: '45px'}} />
                                <Typography sx={{fontSize: '16px'}}>{generatingVideoStatusMessage.label}</Typography>
                                <Typography sx={{fontSize: '12px', color: '#C5C5C5', marginTop: '7px', textAlign: 'center'}}>
                                    {generatingVideoStatusMessage.description}
                                </Typography>                                                    
                            </>
                        }
                    </Box>
                </Box>}                

            </Box>
            
            <Box className="asset-card-details-container">
                <Box className="asset-card-top-section">
                    <Box>
                        {!showRenameField ? <Typography 
                                                className="asset-card-details-title"
                                            >
                                                {assetData?.name}
                                            </Typography>
                        :
                        <Box className="rename-field-box">
                            <TextField 
                                id="rename-textbox-field"
                                className="rename-textbox-field"
                                size="small"
                                value={renameText}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setRenameText(event.target.value);
                                }}
                            />
                            <Box className="rename-check-box" sx={[renameLoading ? {pointerEvents: 'none'} : {}]} onClick={handleSubmitRename}>
                                <img src={iconCheck} alt="" />
                            </Box>
                            <Box className="rename-cancel-box" onClick={handleCancelRename}>
                                <img src={iconClose} alt="" />
                            </Box>
                        </Box>}
                        
                        <Typography className="asset-card-date-time">{moment.utc(assetData?.created_on).local().format('DD MMMM YYYY | hh:mm A')}</Typography>                        
                    </Box>
                    {assetData?.owned && !showRenameField && <Box className="asset-card-ellipsis-menu-container">
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            className="asset-card-ellipsis-menu-icon"
                            style={{color: '#C5C5C5'}}
                        >
                            <MoreVert />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                            'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                className: 'asset-card-long-menu',
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                },
                            }}
                        >
                            {assetData?.owned && <MenuItem onClick={(event) => { event.preventDefault(); event.stopPropagation(); handleClose(); handleClickRename()}}>
                                {'Rename'}
                            </MenuItem>}
                            {assetData?.owned && assetData?.status === VIRTUAL_VIDEO_STATUS.COMPLETED && <MenuItem onClick={(event) => { event.preventDefault(); event.stopPropagation(); handleClose(); handleClickShare(assetData)}}>
                                {'Share'}
                            </MenuItem>}
                            {assetData?.owned && (assetData?.status === VIRTUAL_VIDEO_STATUS.COMPLETED || assetData?.status === VIRTUAL_VIDEO_STATUS.FAILED) && <MenuItem onClick={(event) => { event.preventDefault(); event.stopPropagation(); handleClose(); handleClickDelete(assetData)}}>
                                {'Remove'}
                            </MenuItem>}
                            {assetData?.owned && <MenuItem onClick={(event) => { event.preventDefault(); event.stopPropagation(); handleClose(); navigate(`/generate-video/${assetData?.id}`, { state: { from: location } })}}>
                                {'Update'}
                            </MenuItem>}
                        </Menu>
                    </Box>}
                </Box>
                <Box sx={{background: '#312E35', height: '1px', minHeight: '1px', width: '100%', my: '12px'}}></Box>
                <Typography className="asset-card-movie-name">
                    {searchpayload.movies?.find((m:any) => m.name.toLowerCase() === assetData?.movie_name.toLowerCase())?.display_name}
                </Typography>
                <Tooltip placement="bottom-start" title={assetData?.query} PopperProps={{className: 'asset-card-description-tooltip'}} arrow>
                    <Typography className="asset-card-details-description">
                        {assetData?.query}
                    </Typography>
                </Tooltip>
                <Box sx={{flexGrow: 1}}></Box>
                {assetData?.shared_by && <List className="share-user-list" dense={true}>
                    <ListItem sx={{padding: '0px'}}
                    >
                        <ListItemAvatar>
                        <Avatar alt={shared_by_user?.name} src={shared_by_user?.picture} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={shared_by_user?.name}
                            sx={{fontSize: '14px'}}
                            secondary={
                                <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                >
                                    {shared_by_user?.email}
                                </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                </List>}            
            </Box>
        </Box>
    );
}