import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {useLocation, useNavigate, useOutletContext} from 'react-router-dom';
import FsLightbox from "fslightbox-react";
import { toast } from "react-toastify";
import Header from "../components/Header";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, CircularProgress, Drawer, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, Tab, Tabs, TextField, Toolbar, Typography, styled } from "@mui/material";
import { ChevronLeft, ExpandMore, Movie, PlayArrow, SearchOutlined, SortOutlined, Tune } from "@mui/icons-material";
import Sidebar from "../components/Sidebar";
import { getCategoriesForUser, getFilmsList, getVersions, get_media_count, search } from "../services/searchService";
import { blackColor, confidenceSliderMarks, whiteColor } from "../common/constants";
import { ISearchPayload, ISelectedFiltersState } from "../types/interface";
import Footer from "../components/Footer";
import HomepageSidebar from "../components/HomepageSidebar";
import { error } from "console";
import ImageTabContent from "../components/SearchResultImageTab";
import VideoTabContent from "../components/SearchResultVideoTab";
import iconFilter from "../assets/iconFilter.svg";

// const defaultTheme = createTheme();

// const drawerWidth = 311;
const filterDrawerWidth = 331;

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {/* {value === index && ( */}
        <Box sx={{ padding: "0px" }}>
          {children}
        </Box>
      {/* )} */}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Search() {
    const navigate = useNavigate();
    const location = useLocation();
    const [commonContext] = useOutletContext<any | null>();

    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const {
        searchpayload, 
        setSearchpayload,
        selectedfilters,
        setSelectedfilters,
        handleSubmit,
        handleApplyFilter,
        handleChangeInputs,
        versionlist,
        showResults,
        searchResult,
        mediaCountResult,
        showMediaCountResult,
        searchedInputForResult,
        isloading
    } = commonContext;

    const [initialrender, setInitialrender] = useState<boolean>(true);
    const [films, setFilms] = useState<any[]>([{title: 'kk'}]);
    const [showversion, setShowversion] = useState<boolean>(true);
    const [recentFieldChanged, setRecentFieldChanged] = useState<any>(null);
    const [tabvalue, setTabvalue] = useState(0);

    //For storing selected item for timeline
    const [selectedItemForTimeline, setSelectedItemForTimeline] = useState<null | any>(null);

    //For Lightbox
    const [toggler, setToggler] = useState(false);
    const [productIndex, setProductIndex] = useState(1);

    //To control between timeline and list view
    const [viewBy, setViewBy] = useState<string>("clip");

    useEffect(() => {
        if(location.state?.tabValue){
            setTabvalue(location.state.tabValue)
        }
        if(location.state?.viewBy){
            setViewBy(location.state.viewBy) 
        }
        
        
    }, [location.state]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedItemForTimeline(null);
        setTabvalue(newValue);
    };

    const handleDrawerOpenClose = () => {
        setSidebarOpen(prev => !prev);
    }

    const handleDeleteFilterItem = (chip: string, filmItem?:string, spmIndex?:number) => {
        if (!searchpayload.text.length) {
            return;
        }                    
        
        const new_filter:ISelectedFiltersState = {...selectedfilters};
        switch (chip) {
            case "film":
                const films_newlist = [...searchpayload.movies];
                films_newlist[spmIndex as number].selected = films_newlist[spmIndex as number].selected ? false : true;
                setRecentFieldChanged({"movies": [...searchpayload.movies]});
                setInitialrender(false);
                setSearchpayload((prev:ISearchPayload) => ({...prev, "movies": films_newlist}))
                break;
            case "unique":
                new_filter.unique = false;
                break;
            case "similarity":
                new_filter.minSimilarity = 0;
                new_filter.maxSimilarity = 0;
                break;
            case "confidence":
                new_filter.confidence = 0;
                break;
            case "videoHS":
                new_filter.videoHS = false;
                break;
            case "topKHS":
                new_filter.topKHS = "";
                break;
            case "topKresult":
                new_filter.topKresult = "20";
                break;
        
            default:
                break;
        }
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <CssBaseline />        
        <Drawer
            variant="persistent"
            anchor="right"
            open={sidebarOpen}
            sx={{
            width: sidebarOpen ? filterDrawerWidth : 0,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: filterDrawerWidth, boxSizing: 'border-box', },
            }}
            className="filter-menu"
        >
            
            <Sidebar 
                handleDrawerOpenClose={handleDrawerOpenClose}
                versionlist={versionlist}
                showversion={showversion}
                version={selectedfilters.version}
                handleChangeVersion={(newValue) => {
                    setRecentFieldChanged({"version": selectedfilters.version});
                    setInitialrender(false);
                    setSelectedfilters((prev:ISelectedFiltersState) => ({...prev, version: newValue}));
                    // setSearchpayload((prev) => ({...prev, "version": newValue}))
                }}
                unique={searchpayload.unique}
                handleChangeUnique={(newValue) => setSearchpayload((prev:ISearchPayload) => ({...prev, "unique": newValue}))}
                minSimilarity={searchpayload.similarity_min}
                handleChangeMinSimilarity={(newValue) => setSearchpayload((prev:ISearchPayload) => ({...prev, "similarity_min": Number(newValue)}))}
                maxSimilarity={searchpayload.similarity_max}
                handleChangeMaxSimilarity={(newValue) => setSearchpayload((prev:ISearchPayload) => ({...prev, "similarity_max": Number(newValue)}))}
                confidenceValue={selectedfilters.confidence}
                handleChangeConfidenceValue={(newValue) => {
                    setSelectedfilters((prev:ISelectedFiltersState) => ({...prev, confidence: newValue}));
                }}
                handleChangeInputs={handleChangeInputs}
                handleApplyFilter={handleApplyFilter}
                handleChangeFilmslist={(index) => {
                    if (!searchpayload.text.length) {
                        return;
                    }                    
                    const films_newlist = [...searchpayload.moviesByVersion];
                    films_newlist[index].selected = films_newlist[index].selected ? false : true;
                    setRecentFieldChanged({"moviesByVersion": [...searchpayload.moviesByVersion]});
                    setInitialrender(false);
                    setSearchpayload((prev:ISearchPayload) => ({...prev, moviesByVersion: films_newlist}))
                }}
                searchpayload={searchpayload}
                videoHS={selectedfilters.videoHS}
                handleChangeVideoHS={(newValue) => setSelectedfilters((prev:ISelectedFiltersState) => ({...prev, videoHS: newValue}))}
                topKHS={selectedfilters.topKHS}
                handleChangeTopKHS={(newValue) => setSelectedfilters((prev:ISelectedFiltersState) => ({...prev, topKHS: (newValue.length == 0 ? "": (Number.isNaN(Number(newValue)) ?"": newValue))}))}
                topKresult={selectedfilters.topKresult}
                handleChangeTopKresult={(newValue) => setSelectedfilters((prev:ISelectedFiltersState) => ({...prev, topKresult: (newValue.length == 0 ? "": (Number.isNaN(Number(newValue)) ?"": newValue))}))}
                categories={searchpayload.categories}
                handleChangeCategories={(index) => {
                    const categories_newlist = [...searchpayload.categories];
                    categories_newlist[index].selected = categories_newlist[index].selected ? false : true;
                    setRecentFieldChanged({"categories": [...searchpayload.categories]});
                    setSearchpayload((prev:ISearchPayload) => ({...prev, categories: categories_newlist}));
                }}
            />
        </Drawer>
          
        <Box sx={{ flexGrow: 1, paddingBottom:"60px" }} className="">
            
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}} className="container">
            <Box sx={{display: 'flex', flexWrap: 'wrap', listStyle: 'none', p: 1}} component="ul"  className="filter-bar">
                {searchpayload.movies.filter((mItem:any) => mItem.selected).map((filmItem:any, index:number) => {
                    return (<ListItem key={filmItem.url}>
                        <Chip 
                            sx={{color: whiteColor, backgroundColor: '#707070'}}
                            icon={<IconButton aria-label="play" sx={{p: 0, background: 'rgba(27, 129, 212, 0.1)'}} onClick={() => {
                                setProductIndex(index + 1);
                                setToggler((val) => !val);
                              }} ><PlayArrow sx={{color: '#1B81D4'}} /></IconButton>}
                            label={filmItem.title}
                            onDelete={() => handleDeleteFilterItem("film", filmItem, index)
                            } 
                        />
                    </ListItem>);
                })}
                {searchpayload.version && <ListItem>
                    <Chip 
                        label={`Version: ${searchpayload.version}`}
                        sx={{color: whiteColor, backgroundColor: '#707070'}}
                        // onDelete={() => console.log('delete')
                        // } 
                    />
                </ListItem>}
            </Box>

            </Box>
            {showResults ? 
            <Box className=" ">
                <Box
                    sx={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    padding: "0px 0",
                    
                    }}
                    
                >
                    <Box className="search-tab">
                        <Box className="container"  sx={{ 
                            display: 'flex', 
                            alignItems:{lg:"center", md:"center", sm:"flex-start", xs:"flex-start"},
                            justifyContent: 'space-between', 
                            flexDirection:{lg:"row", md:"row", sm:"column", xs:"column"},
                            rowGap:{lg:"0px", md:"0", sm:"15px", xs:"15px"}
                            }}>
                            <Box sx={{
                                display: 'flex', 
                                alignItems:{lg:"center", md:"center", sm:"center", xs:"flex-start"},
                                justifyContent:{lg:"flex-start", md:"flex-start", sm:"space-between", xs:"space-between"},
                                width:{lg:"auto", md:"auto", sm:"100%", xs:"100%"},
                                flexDirection:{lg:"row", md:"row", sm:"row", xs:"column"},
                                rowGap:{lg:"0px", md:"0", sm:"0", xs:"15px"}
                        }}>
                            <Tabs
                                value={tabvalue}
                                onChange={handleTabChange}
                                // variant="fullWidth"
                                aria-label="search-result-tabs"
                                
                                className=""
                                
                            >
                                <Tab label={<span>Videos <em>({searchResult?.video?.total})</em></span>} {...a11yProps(0)} />
                                <Tab sx={(location.state?.option === 2) ? {display: 'none'} : {}} label={<span>Dialogue <em>({searchResult.dialogue?.total})</em></span>} {...a11yProps(1)} />
                                <Tab sx={(location.state?.option === 2) ? {display: 'none'} : {}} label={<span>Stills <em>({searchResult.image?.total})</em></span>} {...a11yProps(2)} />
                            </Tabs>
                            {!(location.state?.option === 2) && 
                            <Box sx={{display: 'flex', paddingLeft: {lg:"60px", md:"60px", sm:"0px", xs:"0px"}}}>
                                <Box sx={{fontSize: '16px', color: '#989898', display:'flex', alignItems:'center', columnGap:'12px'}}><b>Timeline</b>
                                    <Switch
                                        checked={viewBy === "clip"}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setViewBy(event.target.checked ? "clip" : "list");
                                            if (!event.target.checked) {
                                                setSelectedItemForTimeline(null);                                                
                                            }
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        className="mui-switch"
                                    />
                                </Box>
                            </Box>}
                        </Box>
                        {/* {!(location.state?.option === 2) && <Box sx={{color: "rgba(255, 255, 255, 0.7)" }}>
                            <img src={iconFilter} alt="" style={{cursor: 'pointer', marginRight:"8px", verticalAlign: 'top'}} onClick={() => {
                            setSidebarOpen(val => !val)
                            }} /> Filters
                        </Box>} */}

                    </Box>
                    </Box>
                    
                    <CustomTabPanel value={tabvalue} index={0}>
                        {searchResult?.video?.videos?.length > 0 ? 
                        <Box className="container">
                            <VideoTabContent 
                                cardData={searchResult.video?.videos} 
                                item={searchResult.video} 
                                tabType="video" 
                                viewBy={viewBy} 
                                setViewBy={(view) => setViewBy(view)} 
                                searchpayload={searchpayload} 
                                handleChangeInputs={handleChangeInputs} 
                                showMediaCountResult={showMediaCountResult} 
                                mediaCountResult={mediaCountResult} 
                                searchedInputForResult={searchedInputForResult} 
                                isActiveTab={tabvalue === 0} 
                                showTimelineButtonInListViewCard={true}
                                showWatchlistButtonInListViewCard={true}
                                showPlaylistButtonInListViewCard={true}
                                showDebugButtonInListViewCard={true}
                                withoutSearch={false}
                                selectedItemForTimeline={selectedItemForTimeline}
                                handleClickTimeline={(card, index) => {
                                    setSelectedItemForTimeline(card);
                                    setViewBy("clip");
                                }}
                                canAddToPlaylist={true}
                            />
                        </Box> : (
                            <Box className="no-data-message" >
                                <Typography variant="h6">No videos found </Typography>
                            </Box>)
                        }
                    </CustomTabPanel>
                    <CustomTabPanel value={tabvalue} index={1}>
                        {searchResult?.dialogue?.videos?.length > 0 ? 
                        <Box className="container">
                            <VideoTabContent 
                                cardData={searchResult.dialogue?.videos} 
                                item={searchResult.dialogue} 
                                tabType="dialogue" 
                                viewBy={viewBy} 
                                setViewBy={(view) => setViewBy(view)} 
                                searchpayload={searchpayload} 
                                handleChangeInputs={handleChangeInputs} 
                                showMediaCountResult={showMediaCountResult} 
                                mediaCountResult={mediaCountResult} 
                                searchedInputForResult={searchedInputForResult} 
                                isActiveTab={tabvalue === 1} 
                                showTimelineButtonInListViewCard={true}
                                showWatchlistButtonInListViewCard={true}
                                showPlaylistButtonInListViewCard={true}
                                showDebugButtonInListViewCard={true}
                                withoutSearch={false}
                                selectedItemForTimeline={selectedItemForTimeline}
                                handleClickTimeline={(card, index) => {
                                    setSelectedItemForTimeline(card);
                                    setViewBy("clip");
                                }}
                                canAddToPlaylist={true}
                            />
                        </Box> : (
                            <Box className="no-data-message" >
                                <Typography variant="h6">No dialogues found </Typography>
                            </Box>)
                        }
                    </CustomTabPanel>
                    <CustomTabPanel value={tabvalue} index={2}>
                        {searchResult?.image?.images?.length > 0 ? 
                        <Box className="container">
                            <ImageTabContent
                                cardData={searchResult.image?.images} 
                                item={searchResult.image}
                                tabType="image" 
                                viewBy={viewBy} 
                                setViewBy={(view) => setViewBy(view)} 
                                searchpayload={searchpayload} 
                                handleChangeInputs={handleChangeInputs} 
                                showMediaCountResult={showMediaCountResult} 
                                mediaCountResult={mediaCountResult} 
                                searchedInputForResult={searchedInputForResult} 
                                isActiveTab={tabvalue === 2} 
                                showTimelineButtonInListViewCard={true}
                                showWatchlistButtonInListViewCard={true}
                                showPlaylistButtonInListViewCard={true}
                                showDebugButtonInListViewCard={true}
                                withoutSearch={false}
                                selectedItemForTimeline={selectedItemForTimeline}
                                handleClickTimeline={(card, index) => {
                                    setSelectedItemForTimeline(card);
                                    setViewBy("clip");
                                }}
                                canAddToPlaylist={true}
                            />
                        </Box> : (
                            <Box className="no-data-message" >
                                <Typography variant="h6">No stills found </Typography>
                            </Box>)
                        }
                    </CustomTabPanel>
                </Box>

            </Box>
            :
            <Box className="empty-container">
                {
                isloading ? "":
                "Do you have something in your mind? Search in the search box above."
                }
            </Box>
            }
        </Box>

        <FsLightbox
            toggler={toggler}
            // sources={filmslist.filter((flItem) => selectedfilters.films.includes(flItem.title)).map((cItem) => cItem.url)}
            sources={searchpayload.moviesByVersion.filter((flItem:any) => flItem.selected).map((cItem:any) => cItem.url)}
            slide={productIndex}
            type={"video"}
        />
          
        </Box>
    );
}