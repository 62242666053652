import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import { Autocomplete, Box, Button, CircularProgress, CssBaseline, FormControl, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import iconMovieOpenCogOutline from '../../assets/movie-open-cog-outline_1.svg';
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Generate_Video_Mode, IGenerateVideoPayload, ISearchPayload } from "../../types/interface";
import { generateVideo, getFilmsListBasedOnVersion, getVersionsForVirtualVideo, getVideo } from "../../services/searchService";
import { toast } from "react-toastify";
import { clipLengthPreferenceList, generateVideo_defaultConfigJson, whiteColor } from "../../common/constants";
import { errorToast } from "../../common/utils";

interface IGenerateVideoComponentProps {
    updateLoader: (val:boolean) => void;
    searchpayload: ISearchPayload;
    generateMode: Generate_Video_Mode;
}

export default function GenerateVideoComponent(props: IGenerateVideoComponentProps) {
    let { resourceId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    let from = location.state?.from?.pathname;
    const [commonContext] = useOutletContext<any | null>();
    // const { version:defaultVersion, versionlist } = commonContext;
    const {
        updateLoader,
        searchpayload,
        generateMode
    } = props;

    const { clip_length_preference, ...generateVideo_defaultConfigJson_rest } = generateVideo_defaultConfigJson;

    const [versionlist, setVersionlist] = useState<string[]>([]);
    const [activeResource, setActiveResource] = useState<any>(null);
    const [genvidstate, setGenvidState] = useState<{version: string; movie: any; text: string, config: string, clipLengthPreference: string}>({
        version: '',
        movie: null,
        text: '',
        config: `${JSON.stringify(generateVideo_defaultConfigJson_rest)}`,
        clipLengthPreference: clip_length_preference,
    });
    const [filteredMovieList, setFilteredMovieList] = useState<any[]>([]);
    const [genvidResult, setGenvidResult] = useState<any>({});

    const handleGetMoviesBasedOnVersion = useCallback((param: {version: string}) => {
        setFilteredMovieList([]);
        getFilmsListBasedOnVersion(param)
            .then((response) => {
                setFilteredMovieList(response || []);
                //For clearing movie field when version change only in case of create mode. For update mode, movie field is unchangeable
                if (!!!resourceId) {
                    setGenvidState((prev) => ({...prev, movie: null}));
                }
            })
            .catch((err) => {
                // setFilteredMovieList([]);
                errorToast('Failed to fetch movies list');
            })
    }, []);

    const handleGetVideo = useCallback((id: string) => {
        updateLoader(true);
        getVideo(id)
            .then((response) => {
                setActiveResource(response);
                const { clip_length_preference: response_clip_length_preference, ...response_configRest } = response.config;
                setGenvidState((prev) => ({
                    ...prev, 
                    version: response?.version || '',
                    movie: searchpayload.movies?.find((m:any) => m.name?.toLowerCase() === response?.movie_name?.toLowerCase()), 
                    text: response?.query || '', 
                    config: response?.config ? JSON.stringify(response_configRest) : '',
                    clipLengthPreference: response_clip_length_preference ?? clip_length_preference,
                }));
                updateLoader(false);
            })
            .catch((err) => {
                // updateLoader(false);
            })
    }, [searchpayload.movies]);

    const handleGetVersionsForVirtualVideo = useCallback(() => {
        getVersionsForVirtualVideo()
            .then((response) => {
                setVersionlist(response?.versions || []);

                //For setting default version only in case of create mode, not update mode
                if (!!!resourceId) {
                    setGenvidState((prev) => ({
                        ...prev, 
                        version: response?.current || ''
                    }));    
                }

            })
            .catch((err) => {
                // updateLoader(false);
            })
    }, []);

    useEffect(() => {
        handleGetVersionsForVirtualVideo();
        resourceId && handleGetVideo(resourceId);
    }, []);
    
    //For updating movie list on change in version
    useEffect(() => {
        if (genvidstate.version.length) {
            handleGetMoviesBasedOnVersion({version: genvidstate.version});            
        }
    }, [genvidstate.version]);

    const handleSubmit = useCallback((event:any) => {
        event.preventDefault();
        if (!genvidstate.version.length || !genvidstate.clipLengthPreference.length || !genvidstate.movie || (genvidstate.movie && !Object.keys(genvidstate.movie).length) || !genvidstate.text.length) {
            return;
        }
        const reqConfig = {...JSON.parse(genvidstate.config), clip_length_preference: genvidstate.clipLengthPreference};
        const reqBody:IGenerateVideoPayload = {
            query: genvidstate.text,
            version: genvidstate.version,
            movie_name: genvidstate.movie.name,
            config: reqConfig,
            parent_id: generateMode === Generate_Video_Mode.UPDATE ? activeResource?.id : null,
            root_id: generateMode === Generate_Video_Mode.UPDATE ? activeResource?.root_id : null
        };
        updateLoader(true);
        generateVideo(reqBody)
            .then((response) => {
                setGenvidResult(response);
                updateLoader(false);
                navigate(`/generated-video/${response.id}`, {state: { videoObject: response, videoList: null }});
            })
            .catch((err) => {
                if (err?.response?.status === 429) {
                    errorToast("Your daily limit of generating videos is exhausted.");
                } else {
                    errorToast('Cannot generate video');
                }
                updateLoader(false);
            })
    }, [genvidstate, generateMode, activeResource, updateLoader])

    return (
        <Box className="sub-container-body container" component={'form'} onSubmit={handleSubmit} >
            <Box>
                <Typography className="gen-vid-heading">
                    {generateMode === Generate_Video_Mode.UPDATE ? 'Update Video' : 'Generate video using text'}
                </Typography>
            </Box>
            <Box className="genvid-movie_name-box">
                <FormControl size="small" className="genvid-version-select">
                    <Select
                        id="version-simple-select"
                        name="version"
                        value={genvidstate.version}
                        inputProps={{
                            MenuProps: {
                                MenuListProps: {
                                    sx: {
                                            backgroundColor: '#1e1f20',
                                            color:'#ffffff'
                                        }
                                    }
                                }
                            }}
                        onChange={(event: SelectChangeEvent) => setGenvidState((prev) => ({...prev, version: event.target.value as string}))}
                        readOnly={generateMode === Generate_Video_Mode.UPDATE}
                        sx={[{borderRadius: '9px', color: '#6E737D', border: '1px solid #6E737D'}, (theme) => ({'.MuiOutlinedInput-notchedOutline': {borderColor: '#303030!important'}, '.MuiSelect-icon': {color: '#6E737D'}})]}
                        >
                            <MenuItem value="" disabled>Select Version</MenuItem>
                        {versionlist?.map((vItem:string) => <MenuItem key={vItem} value={vItem}>{vItem}</MenuItem>)}
                    </Select>
                </FormControl>                
                <FormControl size="small" className="genvid-clip-length-preference-select">
                    <Select
                        id="clip-length-preference-simple-select"
                        name="clipLengthPreference"
                        value={genvidstate.clipLengthPreference}
                        inputProps={{
                            MenuProps: {
                                MenuListProps: {
                                    sx: {
                                            backgroundColor: '#1e1f20',
                                            color:'#ffffff'
                                        }
                                    }
                                }
                            }}
                        onChange={(event: SelectChangeEvent) => setGenvidState((prev) => ({...prev, clipLengthPreference: event.target.value as string}))}
                        sx={[{borderRadius: '9px', color: '#6E737D', border: '1px solid #6E737D'}, (theme) => ({'.MuiOutlinedInput-notchedOutline': {borderColor: '#303030!important'}, '.MuiSelect-icon': {color: '#6E737D'}})]}
                        >
                            <MenuItem value="" disabled>Select Length</MenuItem>
                        {clipLengthPreferenceList?.map((clpItem:string) => <MenuItem key={clpItem} value={clpItem}><span style={{textTransform: 'capitalize'}}>{clpItem} Video</span></MenuItem>)}
                    </Select>
                </FormControl>                
                <Autocomplete 
                    id="movie_name"
                    className="movie_name"
                    disablePortal
                    filterSelectedOptions
                    size="small"
                    options={filteredMovieList || []}
                    value={genvidstate.movie}
                    onChange={(event, value, reason) => {
                        setGenvidState((prev) => ({...prev, movie: value}));
                    }}
                    getOptionLabel={(option:any) => searchpayload.movies?.find((m:any) => m.name?.toLowerCase() === option?.name?.toLowerCase())?.display_name}
                    // renderOption={(props, option) => (<>{option}</>)}
                    renderInput={(params) => (
                        <TextField {...params} placeholder="Movie Name" />
                    )}
                    readOnly={generateMode === Generate_Video_Mode.UPDATE}
                />
            </Box>
            <Box className="genvid-textarea-box">
                <TextField
                    id="outlined-genvid-textarea"
                    placeholder="Type word and Generate video"
                    multiline
                    rows={5}
                    className="genvid-textarea"
                    value={genvidstate.text}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setGenvidState((prev) => ({ ...prev, text: event.target.value}));
                    }}   
                    InputProps={{readOnly: generateMode === Generate_Video_Mode.UPDATE}}
                />
            </Box>
            {!(process.env.REACT_APP_ENV === "production") && <Box className="genvid-textarea-box">
                <TextField
                    id="outlined-genvid-config-textarea"
                    placeholder="Enter configuration"
                    multiline
                    rows={5}
                    className="genvid-textarea"
                    value={genvidstate.config || ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setGenvidState((prev) => ({ ...prev, config: event.target.value}));
                    }}                        
                />
            </Box>}
            <Box className="genvid-button-container">
                {generateMode === Generate_Video_Mode.UPDATE && <Button 
                    type="button"
                    variant="outlined" 
                    className="genvid-cancel-button"
                    size="small"
                    onClick={() => {from ? navigate(from) : navigate('/assets')}}
                >
                    Cancel
                </Button>}
                <Button 
                    variant="contained" 
                    className="generate-button" 
                    type="submit"
                    disabled={!genvidstate.version.length || !genvidstate.movie || (genvidstate.movie && !Object.keys(genvidstate.movie).length) || !genvidstate.text.length}
                >
                    {generateMode === Generate_Video_Mode.UPDATE ? 'Update' : 'Generate'}
                </Button>
            </Box>
        </Box>
    );
}