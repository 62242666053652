import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import { Autocomplete, Box, Button, CircularProgress, CssBaseline, TextField, Typography } from "@mui/material";
import iconMovieOpenCogOutline from '../../assets/movie-open-cog-outline_1.svg';
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Generate_Video_Mode, IGenerateVideoPayload } from "../../types/interface";
import { generateVideo } from "../../services/searchService";
import { toast } from "react-toastify";
import { whiteColor } from "../../common/constants";
import GenerateVideoComponent from "../../components/GenerateVideo";

export default function GenerateVideo() {
    const [commonContext] = useOutletContext<any | null>();
    const navigate = useNavigate();
    let { resourceId } = useParams();
    const generateMode = resourceId ? Generate_Video_Mode.UPDATE : Generate_Video_Mode.CREATE;

    const { searchpayload } = commonContext;
    const [isloading, setIsloading] = useState<boolean>(false);

    const updateLoader = useCallback((val:boolean) => {
        setIsloading(val);
    }, []);

    return (
        <Box>
        <CssBaseline />
        {isloading && (
            <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                width: "100%",
                height: "100vh",
                zIndex: "9999",
                background: "rgba(0,0,0,0.75)",
            }}
            >
            <CircularProgress sx={{color: whiteColor}} />
            </Box>
        )}
        
        <Box className="sub-container">
            <Box className="generate-video-tab">
                <Box className="container">
                    <Box className="icon-container">
                        <img src={iconMovieOpenCogOutline} alt="" className="" />
                        <Typography className="subheader-text">Generate Video</Typography>
                    </Box>
                </Box>
            </Box>

            <Box>
                <GenerateVideoComponent 
                    updateLoader={updateLoader}
                    searchpayload={searchpayload}
                    generateMode={generateMode}
                />
            </Box>
        </Box>
        </Box>
    );
}
