import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import FsLightbox from "fslightbox-react";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Chip, CircularProgress, Drawer, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, Tab, Tabs, TextField, Toolbar, Typography, styled } from "@mui/material";
import { ChevronLeft, ExpandMore, PlayArrow, SearchOutlined, SortOutlined, Tune } from "@mui/icons-material";
import Sidebar from "../../components/Sidebar";
import { addUserQuery, getCategoriesForUser, getFilmsList, getFilmsListBasedOnVersion, getUserHistory, getVersions, get_media_count, search } from "../../services/searchService";
import TabContent from "../../components/SearchResultVideoTab";
import { blackColor, confidenceSliderMarks, drawerWidth, filterDrawerWidth, whiteColor } from "../../common/constants";
import { ISearchPayload, ISelectedFiltersState } from "../../types/interface";
import Footer from "../../components/Footer";
import HomepageSidebar from "../../components/HomepageSidebar";
import { WatchListProvider } from "../../hooks/useWatchList";
import iconCopySearch from "../../assets/icon-copy-to-search.svg"
import iconSearch from "../../assets/icon-search.svg"
import iconSearchFilter from "../../assets/Group_72751.svg";
import { errorToast } from "../../common/utils";

export default function CommonLayout() {
    const navigate = useNavigate();
    const location = useLocation();

    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [sidebarMainOpen, setSidebarMainOpen] = useState<boolean>(false);
    const [filmslist, setFilmslist] = useState<any[]>([]);
    const searchInputRef = useRef<null | HTMLInputElement>(null);

    const [searchpayload, setSearchpayload] = useState<ISearchPayload>({
        text: '',
        search_for: 'all',
        movies: [],
        moviesByVersion: [],
        version: '',
        unique: true,
        similarity_min: 0,
        similarity_max: 1,
        confidence: 30,
        is_dialog: false,
        apply_video_HS: false,
        topK_from_video_HS_set: "",
        topK_videos: "20",
        categories: [],
    });
    const [initialrender, setInitialrender] = useState<boolean>(true);
    const [initialVersion, setInitialVersion] = useState<string>("");
    const [versionlist, setVersionlist] = useState<string[]>([]);
    const [showversion, setShowversion] = useState<boolean>(true);
    const [recentFieldChanged, setRecentFieldChanged] = useState<any>(null);

    const [isloading, setIsloading] = useState(false);
    const [selectedfilters, setSelectedfilters] = useState<ISelectedFiltersState>({
        films: [],
        version: "",
        unique: true,
        minSimilarity: 0,
        maxSimilarity: 1,
        confidence: 30,
        videoHS: false,
        topKHS: "",
        topKresult: "20",
        categories:[],

    });
    const [searchpayloadInitialState, setSearchpayloadInitialState] = useState<ISearchPayload>(searchpayload);
    const [selectedfiltersInitialState, setSelectedfiltersInitialState] = useState<ISelectedFiltersState>(selectedfilters);
    const [showResults, setShowResults] = useState(false);
    const [searchResult, setSearchResult] = useState<any>({});
    const [mediaCountResult, setMediaCountResult] = useState<any>({});
    const [showMediaCountResult, showSetMediaCountResult] = useState<boolean>(false);
    const [searchedInputForResult, setSearchedInputForResult] = useState<string>('');
    const [tabvalue, setTabvalue] = useState(0);
    const [searchAutocompleteDropdownOpen, setSearchAutocompleteDropdownOpen] = useState<boolean>(false);

    //For CardDetailDialog
    const [openCardDetailDialog, setOpenCardDetailDialog] = useState<boolean>(false);
    const [selectedCardDetailDialogData, setselectedCardDetailDialogData] = useState<{type:string; value:any; autoplayVideo: boolean;}>({type: '', value: {}, autoplayVideo: false});
  
    //For Lightbox
    const [toggler, setToggler] = useState<boolean>(false);
    const [productIndex, setProductIndex] = useState<number>(1);
    const [fullScreenMediaSource, setFullScreenMediaSource] = useState<string>('');

    //For playlist dialog
    const [openPlaylistDialog, setOpenPlaylistDialog] = useState<boolean>(false);
    const [selectedPlaylistDialog, setSelectedPlaylistDialog] = useState<{type:string; value:any}>({type: '', value: {}});

    //To control between timeline and list view
    const [viewBy, setViewBy] = useState<string>("clip");


    const [searchHistoryData, setSearchHistoryData] = useState<any[]>([]);
    const getUserHistorylist = () => {
        getUserHistory().then((response) => {
            setSearchHistoryData(response || []);
            // console.log(response)           
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const addUserSearchQuery = (query: string) => {
        if(query.length>0)
        {
          const requestBody = {
            query: query,
          };
          addUserQuery(requestBody)
            .then((response) => {
              // console.log(response);
              getUserHistorylist();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      };
      
    useEffect(() => {
        const getFilms = async () => {
         const result = await getFilmsList();
         setFilmslist(result ?? []);   
         setSearchpayload((prev) => ({...prev, "movies": result ?? []}))
        }
        getFilms();
        getUserHistorylist();
    }, []);

    useEffect(() => {
        const getVersionList = async () => {
        const response = await getVersions();
        setVersionlist(response?.versions ?? []);
        // setVersion(response?.current ?? '');
        setSearchpayload((prev) => ({...prev, "version": response?.current ?? ''}));
        setSelectedfilters((prev) => ({...prev, version: response?.current ?? ''}));
        setInitialVersion(response?.current ?? '');
        }
        getVersionList();

        if(location.state?.tabValue){
            setTabvalue(location.state.tabValue)
        }
        if(location.state?.viewBy){
            setViewBy(location.state.viewBy) 
        }
        
        
    }, []);

    useEffect(() => {
        setCategoriesData();
    }, []);

    const setCategoriesData = () => {
        getCategoriesForUser()
            .then((response) => {
                // setCategories(response || []);
                setSearchpayload((prev) => ({...prev, categories: response || []}));
            })
            .catch((error) => {
                console.log(error);
            })
    }

  const handleGetMoviesBasedOnVersion = useCallback((param: {version: string}) => {
      setSearchpayload((prev) => ({...prev, moviesByVersion: []}));
      getFilmsListBasedOnVersion(param)
          .then((response) => {
              setSearchpayload((prev) => ({...prev, moviesByVersion: response || []}));
          })
          .catch((err) => {
              // setFilteredMovieList([]);
              errorToast('Failed to fetch movies list');
          })
  }, []);

    //For updating moviesByVersion list on change in version
    useEffect(() => {
      if (selectedfilters.version.length) {
        handleGetMoviesBasedOnVersion({version: selectedfilters.version});
      }
    }, [selectedfilters.version]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabvalue(newValue);
    };

    // useEffect(() => {
    //     if (initialrender) {
    //         return;
    //     } else {
    //         let new_filter:ISelectedFiltersState;
    //         new_filter = {
    //             films: searchpayload.movies.filter((filmItem:any) => filmItem.selected).map((flItem) => flItem.title),
    //             version: searchpayload.version,
    //             unique: searchpayload.unique,
    //             minSimilarity: searchpayload.similarity_min,
    //             maxSimilarity: searchpayload.similarity_max,
    //             confidence: searchpayload.confidence,
    //             videoHS: searchpayload.apply_video_HS,
    //             topKHS: searchpayload.topK_from_video_HS_set,
    //             topKresult: searchpayload.topK_videos,
    //             categories: [],
    //         };            categories: searchpayload.categories,

    //         handleSubmitOnFilterChange(new_filter);

    //     }
    // }, [searchpayload.is_dialog, searchpayload.version, searchpayload.movies]);

    const handleDrawerOpenClose = () => {
        setSidebarOpen(prev => !prev);
    }

    const handleMainDrawerOpenClose = () => {
        // console.log(sidebarMainOpen)
        setSidebarMainOpen(prev => !prev);
    }

    const handleUpdateSearchPayload = (field: {field: any}) => {
        setSearchpayload((prev) => ({...prev, ...field}));
    }

    const handleChangeInputs = (event:any) => {
        const name = event.target.name;
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        if (name !== "text" && !searchpayload.text.length) {
            // toast.error("Search text must not be blank!");
            return;
        }
        setRecentFieldChanged({[name]: value});
        setInitialrender(false);
        setSearchpayload((prev) => ({...prev, [name]: value}));
    };

    //Called when user searches new query on search page. Fresh call with clear state due to page refresh
    const handleSubmit = (event: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLDivElement>) => {
        event.preventDefault();
        navigate(location.pathname, { replace: true });
        setRecentFieldChanged({"text": searchpayload.text});
        const reqBody = {
            text: searchpayload.text, 
            search_for: searchpayload.search_for,
            movies: searchpayload.moviesByVersion.filter((filmItem:any) => filmItem.selected).map((flItem) => flItem.name),
            version: selectedfilters.version,
            unique: selectedfilters.unique,
            similarity_min: selectedfilters.minSimilarity,
            similarity_max: selectedfilters.maxSimilarity,
            confidence: confidenceSliderMarks.find((csmItem:any) => csmItem.value === selectedfilters.confidence)?.label,
            is_dialogue: searchpayload.is_dialog,
            apply_video_HS: selectedfilters.videoHS,
            topK_from_video_HS_set: selectedfilters.topKHS.length ? Number(selectedfilters.topKHS) : -1,
            topK_videos: selectedfilters.topKresult.length ? Number(selectedfilters.topKresult) : 20,
            categories: searchpayload.categories.filter((catItem:any) => catItem.selected).map((cItem) => cItem.id),
        };
        setShowResults(false);
        setIsloading(true);
        search(reqBody)
          .then((response) => {
            addUserSearchQuery(searchpayload.text);
            setSearchResult({...response});
            setSearchedInputForResult(searchpayload.text);
            setShowResults(true);
            setIsloading(false);
          })
          .catch((error) => {
            setSearchpayload(prev => ({...prev, ...recentFieldChanged}));
            setIsloading(false);
            errorToast(`Cannot search`);
          });
          const mediaCountsReqBody = {
            version: reqBody.version, 
            is_dialogue: reqBody.is_dialogue
            }
          handleMediaCount(mediaCountsReqBody);
      };
    
    const handleMediaCount = (reqBody: any) =>{
        showSetMediaCountResult(false)
        get_media_count(reqBody)
          .then((response) => {

            setMediaCountResult(response);
            showSetMediaCountResult(true);

          })
          .catch((error) => {
          });
    }

    //Called when user searches with filter changes. Selected filters are passed to the payload
    const handleSubmitOnFilterChange = (new_filter: ISelectedFiltersState) => {
      if (location.pathname === "/search") {
        navigate(location.pathname, { replace: true });
      }
        const reqBody = {
            text: searchpayload.text, 
            search_for: searchpayload.search_for,
            movies: new_filter.films,
            version: new_filter.version,
            unique: new_filter.unique,
            similarity_min: Number(new_filter.minSimilarity),
            similarity_max: Number(new_filter.maxSimilarity),
            confidence: confidenceSliderMarks.find((csmItem:any) => csmItem.value === new_filter.confidence)?.label,
            is_dialogue: searchpayload.is_dialog,
            // apply_video_HS: searchpayload.apply_video_HS,
            // topK_from_video_HS_set: searchpayload.topK_from_video_HS_set.length ? Number(searchpayload.topK_from_video_HS_set) : -1,
            // topK_videos: searchpayload.topK_videos.length ? Number(searchpayload.topK_videos) : 20
            apply_video_HS: selectedfilters.videoHS,
            topK_from_video_HS_set: selectedfilters.topKHS.length ? Number(selectedfilters.topKHS) : -1,
            topK_videos: selectedfilters.topKresult.length ? Number(selectedfilters.topKresult) : 20,
            categories: new_filter.categories,
        };
        setShowResults(false);
        setIsloading(true);
        search(reqBody)
          .then((response) => {
            // setSearchResult({});
            setSearchedInputForResult(searchpayload.text);
            // setSelectedfilters(new_filter);  
            setSearchResult(response);
            setShowResults(true);
            setIsloading(false);
            if (location.pathname !== "/search") {
              navigate('/search');
            }
          })
          .catch((error) => {
            setSearchpayload(prev => ({...prev, ...recentFieldChanged}));
            setIsloading(false);
            errorToast(`Cannot search`);
          });
          
          const mediaCountsReqBody = {
            version: reqBody.version, 
            is_dialogue: reqBody.is_dialogue
            }
          handleMediaCount(mediaCountsReqBody);
      };

    const handleApplyFilter = (apply:boolean) => {
        let new_filter:ISelectedFiltersState;
        if (apply) {
            new_filter = {
                films: searchpayload.moviesByVersion.filter((filmItem:any) => filmItem.selected).map((flItem) => flItem.name),
                version: selectedfilters.version,
                unique: selectedfilters.unique,
                minSimilarity: selectedfilters.minSimilarity,
                maxSimilarity: selectedfilters.maxSimilarity,
                confidence: selectedfilters.confidence,
                videoHS: selectedfilters.videoHS,
                topKHS: selectedfilters.topKHS,
                topKresult: selectedfilters.topKresult,
                categories: searchpayload.categories.filter((catItem:any) => catItem.selected).map((cItem) => cItem.id),
            };
            handleSubmitOnFilterChange(new_filter);
            // setSelectedfilters(new_filter);                
        } else {
            let clear_films = searchpayload.moviesByVersion.map((filmItem) => {
                filmItem.selected = false;
                return filmItem;
            })
            let clear_categories = searchpayload.categories.map((catItem) => {
                catItem.selected = false;
                return catItem;
            });
            setRecentFieldChanged({...searchpayload});
            // setInitialrender(false);
            setSearchpayload((prev) => ({...prev, moviesByVersion: clear_films, categories: clear_categories}));

            new_filter = {
                films: [],
                version: searchpayload.version,
                unique: true,
                minSimilarity: 0,
                maxSimilarity: 1,
                confidence: 30,
                videoHS: false,
                topKHS: "",
                topKresult: "20",
                categories:[],
            };
            setSelectedfilters({...new_filter});
        }
    }

    const handleDeleteFilterItem = (chip: string, filmItem?:string, spmIndex?:number) => {
        if (!searchpayload.text.length) {
            // toast.error("Search text must not be blank!");
            return;
        }                    
        
        const new_filter:ISelectedFiltersState = {...selectedfilters};
        switch (chip) {
            case "film":
                // const itemIndex = new_filter.films.indexOf(filmItem as string);
                // new_filter.films.splice(itemIndex, 1);

                const films_newlist = [...searchpayload.moviesByVersion];
                films_newlist[spmIndex as number].selected = films_newlist[spmIndex as number].selected ? false : true;
                setRecentFieldChanged({"moviesByVersion": [...searchpayload.moviesByVersion]});
                setInitialrender(false);
                setSearchpayload((prev) => ({...prev, moviesByVersion: films_newlist}))
                break;
            case "unique":
                new_filter.unique = false;
                break;
            case "similarity":
                new_filter.minSimilarity = 0;
                new_filter.maxSimilarity = 0;
                break;
            case "confidence":
                new_filter.confidence = 0;
                break;
            case "videoHS":
                new_filter.videoHS = false;
                break;
            case "topKHS":
                new_filter.topKHS = "";
                break;
            case "topKresult":
                new_filter.topKresult = "20";
                break;
        
            default:
                break;
        }
        // handleSubmitOnFilterChange(new_filter);
    }

    const handleSubmitSearchContext = (fObj: {page: string; argList: {
        event: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLDivElement> | React.FormEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement, MouseEvent>;
        tabValue?: number | undefined; 
        viewBy?: string | undefined; 
        option?: number;
         cardItem?: any; 
         categories?:any[];
         movies?: any[];
    }}) => {
        const {
            page,
            argList: {
                event,
                tabValue = undefined,
                viewBy = undefined,
                option = 1,
                cardItem = null,
                categories = [],
                movies = []
            }
        } = fObj;
        event.preventDefault();
        let reqBody:any;
        if (location.pathname === "/search") {
            handleSubmit(event as React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLDivElement>);
        } else {
          // handleApplyFilter(false);
            reqBody = {
                text: '', 
                search_for: searchpayload.search_for,
                // movies: searchpayload.movies.filter((filmItem:any) => filmItem.selected).map((flItem) => flItem.name),
                movies: movies,
                version: selectedfilters.version,
                unique: searchpayload.unique,
                similarity_min: searchpayload.similarity_min,
                similarity_max: searchpayload.similarity_max,
                // confidence: confidenceSliderMarks.find((csmItem:any) => csmItem.value === selectedfilters.confidence)?.label
                is_dialogue: searchpayload.is_dialog,
                apply_video_HS: searchpayload.apply_video_HS,
                topK_from_video_HS_set: searchpayload.topK_from_video_HS_set.length ? Number(searchpayload.topK_from_video_HS_set) : -1,
                topK_videos: searchpayload.topK_videos.length ? Number(searchpayload.topK_videos) : 20,
                option: option,
                categories: categories
            };    
            setIsloading(true);
            search(reqBody)
              .then((response) => {
                addUserSearchQuery(searchpayload.text);
                setSearchResult({});
                setSearchResult(response);
                setSearchedInputForResult(searchpayload.text);
                setShowResults(true);
                setIsloading(false);
    
                const mediaCountsReqBody = {
                    version: reqBody.version, 
                    is_dialogue: reqBody.is_dialogue
                    }
                handleMediaCount1(mediaCountsReqBody, response, tabValue, viewBy, option, cardItem);
              })
              .catch((error) => {
                setIsloading(false);
                errorToast(`Cannot search`);
              });
    
        }
    };

    const handleMediaCount1 = (reqBody: any, searchResp:any, tabValue: number | undefined = undefined, viewBy: string |undefined=undefined, option: number = 1, cardItem: any) =>{
        get_media_count(reqBody)
          .then((response) => {

            setMediaCountResult(response);
            showSetMediaCountResult(true);
            navigate('/search', {state: {searchQuery: searchpayload.text, searchResponse: searchResp, mediaCount: response, tabValue:tabValue, viewBy: viewBy, option: option, cardItem: cardItem}});

          })
          .catch((error) => {
          });
    }

    //For identifying if any filter field is changed
    const isFiltered = useMemo(() => {
      if (
        searchpayload.moviesByVersion.some((mItem) => mItem?.selected) 
        || searchpayload.version !== selectedfilters.version
        || searchpayloadInitialState.unique !== selectedfilters.unique
        || searchpayloadInitialState.similarity_min !== selectedfilters.minSimilarity
        || searchpayloadInitialState.similarity_max !== selectedfilters.maxSimilarity
        || searchpayloadInitialState.confidence !== selectedfilters.confidence
        || searchpayloadInitialState.apply_video_HS !== selectedfilters.videoHS
        || searchpayloadInitialState.topK_from_video_HS_set !== selectedfilters.topKHS
        || searchpayloadInitialState.topK_videos !== selectedfilters.topKresult
        || searchpayload.categories.some((cItem) => cItem?.selected)
      ) {
        return true;        
      } else {
        return false;
      }
    }, [searchpayloadInitialState, selectedfilters, searchpayload]);

    const commonContext = {
        searchpayload: searchpayload,
        setSearchpayload: setSearchpayload,
        selectedfilters: selectedfilters,
        setSelectedfilters: setSelectedfilters,
        handleSubmit: handleSubmitSearchContext,
        handleApplyFilter: handleApplyFilter,
        handleChangeInputs: handleChangeInputs,
        versionlist: versionlist,
        showResults: showResults,
        searchResult: searchResult,
        mediaCountResult: mediaCountResult,
        showMediaCountResult: showMediaCountResult,
        searchedInputForResult: searchedInputForResult,
        isloading: isloading
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', background: '#070110'}}>
          <CssBaseline />
        {isloading && (
            <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                width: "100%",
                height: "100vh",
                zIndex: "9999",
                background: "rgba(0,0,0,0.7)",
            }}
            >
            <CircularProgress sx={{color: whiteColor}} />
            </Box>
        )}
        
          <Header sidebarOpen={sidebarMainOpen} handleDrawerOpenClose={handleMainDrawerOpenClose} handleUpdateSearchPayload={handleUpdateSearchPayload}>
            <Box
              component="form"
              onSubmit={(e) => 
                { 
                  e.preventDefault();
                  // if(searchpayload.text.length<1) {
                  if(searchInputRef.current && searchInputRef.current?.querySelector('input') && searchInputRef.current?.querySelector('input')?.value?.length as number >= 1) {
                    let search_box =document.getElementById("search-box-wrapper")
                    search_box?.blur();
                    searchpayload.text = searchInputRef.current?.querySelector('input')?.value || "";
                    // setSearchpayload((prev) => ({...prev, text: searchInputRef.current?.querySelector('input')?.value || ""}));
                    // handleSubmitSearchContext({page: '', argList: {event: e}})
                    handleApplyFilter(true);
                  } 
                }}
              autoComplete="off"
              className="search-bar"
            >
            <Autocomplete
              id = "search-box-wrapper"
              clearOnBlur = {false}
              value = {searchpayload.text}
              options={searchHistoryData.map((item) => item.query)}
              autoHighlight={false}
              getOptionLabel={(option) => option}
              classes={{ paper: "autocomplete-paper" }}
              noOptionsText={'No matches...'}
              selectOnFocus={false}
              onChange={(event: any, newValue:string | null) => {
                if (newValue) {
                  if(event.target.tagName == "IMG" || event.target.id == "copyTextIcon"){
                      searchpayload.text = newValue; 
                      return
                  }
                searchpayload.text = newValue;
                // handleSubmitSearchContext({page: '', argList: {event: event}})
                event.preventDefault();
                handleApplyFilter(true);
                }
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                  key={option.id}
                  sx={{
                    width: "100%",
                    background: "#070110 !important",
                    color: "#ffffff",
                    fontSize: "16px",
                    margin: "0px !important",
                    padding: "0px 20px !important",
                    "&:hover": {
                      backgroundColor: "#1E1F20 !important",
                    },
                  }}
                >
                  <div
                    style={{
                      borderTop: "1px solid rgba(110, 115, 125, 0.5)",
                      display: "flex",
                      minHeight: "43px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    <div
                      style={{ cursor: "pointer" , width:"100%"}}>
                      {String(option)}
                    </div>
                    <div
                      id="copyTextIcon"
                      style={{padding:"10px 5px 10px 10px"}}
                    >
                      <img
                        loading="lazy"
                        width="9"
                        srcSet={iconCopySearch}
                        src={iconCopySearch}
                        alt=""
                        style={{ marginLeft: "auto"}}
                      />
                    </div>
                  </div>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                    {...params}
                    id="search-input-adornment"
                    className="searchInputTextBox"
                    InputProps={{
                        // startAdornment: <InputAdornment position="start"><SearchOutlined /></InputAdornment>,
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <div 
                              className={`searchFilterImageDiv ${searchAutocompleteDropdownOpen ? "searchInputTextBox-applied" : ""}`} 
                              onClick={(event) => {
                              event.preventDefault();
                              setSidebarOpen((prev) => !prev);
                              }}
                            >
                              <img src={iconSearchFilter} alt="" />
                            </div>
                            {isFiltered && <div className="filter-highlighter"></div>}
                        </InputAdornment>
                        ),
                        endAdornment: 
                        <FormControl className="search-btn">
                            <img src={iconSearch} alt="" />
                        </FormControl>,
                        className: "searchField",
                        style: {color: whiteColor},
                    }}
                    size="small"
                    variant="outlined"
                    name="text"
                    ref={searchInputRef}
                    tabIndex={0}
                    // value={searchpayload.text}
                    // onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    //     setSearchInput(event.target.value)
                    // }
                    // onChange={handleChangeInputs}
                    placeholder="Search here..."
                />)}
                onOpen={() => setSearchAutocompleteDropdownOpen(true)}
                onClose={() => setSearchAutocompleteDropdownOpen(false)}
                />

            </Box>
          </Header>
        <Drawer
            variant="persistent"
            anchor="right"
            open={sidebarOpen}
            sx={{
            width: sidebarOpen ? filterDrawerWidth : 0,
            flexShrink: 0,
            overflow:"hidden",
            [`& .MuiDrawer-paper`]: { width: filterDrawerWidth, boxSizing: 'border-box', },
            }}
            className="filter-menu"
        >
            
            <Sidebar 
                handleDrawerOpenClose={handleDrawerOpenClose}
                versionlist={versionlist}
                showversion={showversion}
                version={selectedfilters.version}
                handleChangeVersion={(newValue) => {
                    setRecentFieldChanged({"version": selectedfilters.version});
                    setInitialrender(false);
                    setSelectedfilters((prev:ISelectedFiltersState) => ({...prev, version: newValue}));
                    // setSearchpayload((prev) => ({...prev, "version": newValue}))
                }}
                unique={searchpayload.unique}
                handleChangeUnique={(newValue) => setSearchpayload((prev:ISearchPayload) => ({...prev, "unique": newValue}))}
                minSimilarity={searchpayload.similarity_min}
                handleChangeMinSimilarity={(newValue) => setSearchpayload((prev:ISearchPayload) => ({...prev, "similarity_min": Number(newValue)}))}
                maxSimilarity={searchpayload.similarity_max}
                handleChangeMaxSimilarity={(newValue) => setSearchpayload((prev:ISearchPayload) => ({...prev, "similarity_max": Number(newValue)}))}
                confidenceValue={selectedfilters.confidence}
                handleChangeConfidenceValue={(newValue) => {
                    // setSearchpayload((prev) => ({...prev, "confidence": newValue}));
                    setSelectedfilters((prev) => ({...prev, confidence: newValue}));
                }}
                handleChangeInputs={handleChangeInputs}
                handleApplyFilter={handleApplyFilter}
                handleChangeFilmslist={(filmCard) => {
                    // if (!searchpayload.text.length) {
                    //     return;
                    // }                    
                    const films_newlist = [...searchpayload.moviesByVersion];
                    const filmItemIndex = films_newlist.findIndex((fItem) => fItem.id === filmCard.id);
                    films_newlist[filmItemIndex].selected = films_newlist[filmItemIndex].selected ? false : true;
                    setRecentFieldChanged({"moviesByVersion": [...searchpayload.moviesByVersion]});
                    setInitialrender(false);
                    setSearchpayload((prev:ISearchPayload) => ({...prev, moviesByVersion: films_newlist}))
                }}
                searchpayload={searchpayload}

                // videoHS={searchpayload.apply_video_HS}
                // handleChangeVideoHS={(newValue) => setSearchpayload((prev) => ({...prev, "apply_video_HS": newValue}))}
                videoHS={selectedfilters.videoHS}
                handleChangeVideoHS={(newValue) => setSelectedfilters((prev) => ({...prev, videoHS: newValue}))}

                // topKHS={searchpayload.topK_from_video_HS_set}
                // handleChangeTopKHS={(newValue) => setSearchpayload((prev) => ({...prev, "topK_from_video_HS_set": (newValue.length == 0 ? "": (Number.isNaN(Number(newValue)) ?"": newValue))}))}
                topKHS={selectedfilters.topKHS}
                handleChangeTopKHS={(newValue) => setSelectedfilters((prev) => ({...prev, topKHS: (newValue.length == 0 ? "": (Number.isNaN(Number(newValue)) ?"": newValue))}))}

                // topKresult={searchpayload.topK_videos}
                // handleChangeTopKresult={(newValue) => setSearchpayload((prev) => ({...prev, "topK_videos": (newValue.length == 0 ? "": (Number.isNaN(Number(newValue)) ?"": newValue))}))}
                topKresult={selectedfilters.topKresult}
                handleChangeTopKresult={(newValue) => setSelectedfilters((prev) => ({...prev, topKresult: (newValue.length == 0 ? "": (Number.isNaN(Number(newValue)) ?"": newValue))}))}

                categories={searchpayload.categories}
                handleChangeCategories={(index) => {
                    const categories_newlist = [...searchpayload.categories];
                    categories_newlist[index].selected = categories_newlist[index].selected ? false : true;
                    setRecentFieldChanged({"categories": [...searchpayload.categories]});
                    // setCategories([...categories_newlist]);
                    setSearchpayload((prev) => ({...prev, categories: categories_newlist}));
                }}
            />
        </Drawer>

        <Drawer
            variant="persistent"
            anchor="left"
            open={sidebarMainOpen}
            sx={{width: drawerWidth, flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', background: `#070110` },
            }}
            className="left-menu-drawer"
          >
            
            <HomepageSidebar handleDrawerOpenClose={handleMainDrawerOpenClose} handleUpdateSearchPayload={handleUpdateSearchPayload} />
          </Drawer>
          
        <Box component="main" sx={{ flexGrow: 1, paddingBottom:{lg:"60px", md:"40px", sm:"20px", xs:"0px"}, marginLeft: 'auto', marginRight: 'auto', width: '100%' }} className="">
            <Toolbar sx={{height: '82px', minHeight: '82px!important'}} />
            <WatchListProvider>
                <Outlet 
                    context={[commonContext]}
                />
            </WatchListProvider>
            
        </Box>
        <Footer></Footer>       

        <FsLightbox
            toggler={toggler}
            // sources={filmslist.filter((flItem) => selectedfilters.films.includes(flItem.title)).map((cItem) => cItem.url)}
            sources={searchpayload.moviesByVersion.filter((flItem) => flItem.selected).map((cItem) => cItem.url)}
            slide={productIndex}
            type={"video"}
        />
        {searchpayload.movies.length && searchpayload.movies.map((movieItem) => <video key={movieItem.id} width="320" height="240" preload="auto" controls style={{display: 'none'}} >
        <source src={movieItem?.url} type="video/mp4" ></source>
        </video>) }
          
        </Box>
    );
}