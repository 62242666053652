import React, { useEffect, useState } from "react";
import { Box, List, ListItem, ListItemButton, ListItemText, MenuItem, Select, SelectChangeEvent, Slider, Switch, TextField, Toolbar, Typography } from "@mui/material";
import { Close, Add, ExpandMore, QueueMusic, SearchOutlined, SortOutlined, Widgets, Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import iconCategory from '../../assets/iconCategory.svg'
import iconPlayList from '../../assets/iconPlayList.svg'
import iconAssets from '../../assets/assets_icon.svg'

interface SidebarProps {
    handleDrawerOpenClose:() => void;
    handleUpdateSearchPayload: (field:any) => void;
}
export default function HomepageSidebar(props:SidebarProps) {
    const {handleDrawerOpenClose, handleUpdateSearchPayload} = props;
    const navigate = useNavigate();

    return (
        <Box className="">
            {
                handleDrawerOpenClose &&
                <Close className="left-menu-close-icon" onClick={() => handleDrawerOpenClose()} />
            }
            <nav aria-label="secondary mailbox folders">
                <List >
                    <ListItem disablePadding>
                        <ListItemButton component="a" onClick={() => {
                            handleDrawerOpenClose();
                            handleUpdateSearchPayload({text: ''});
                            navigate("/playlists")
                            }}
                            sx={{display:"flex", columnGap:"5px", alignItems:"center"}}
                            >
                            <img src={iconPlayList} alt="" />
                            <ListItemText  primary="Playlists" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component="a" onClick={() => {
                            handleDrawerOpenClose();
                            handleUpdateSearchPayload({text: ''});
                            navigate("/categories")
                            }}
                            sx={{display:"flex", columnGap:"5px", alignItems:"center"}}
                            >
                            <img src={iconCategory} alt="" />
                            <ListItemText primary="Categories" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component="a" onClick={() => {
                            handleDrawerOpenClose();
                            handleUpdateSearchPayload({text: ''});
                            navigate("/assets")
                            }}
                            sx={{display:"flex", columnGap:"5px", alignItems:"center"}}
                            >
                            <img src={iconAssets} alt="" />
                            <ListItemText primary="Assets" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component="a" onClick={() => {
                            handleDrawerOpenClose();
                            handleUpdateSearchPayload({text: ''});
                            navigate("/settings")
                            }}>
                            <Settings />
                            <ListItemText primary="Settings" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </nav>                    
            
        </Box>
    );
}
