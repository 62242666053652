import React from "react";
import logo from "../../assets/logo-main.svg";
import appBanner from "../../assets/app-banner.jpg";

const Index = () => {
  return (
    <div className="index-page">
      <div className="app-header">
        <div className="container">
          <div className="app-header-inner">
            <div className="app-logo">
              <img src={logo} alt="" />
            </div>
            <div className="app-menu">
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">Product</a>
                </li>
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="app-banner">
        <img src={appBanner} alt="" />
        <div className="container position-relative">
          <div className="app-baner-content">
            <h4>WELCOME</h4>
            <h1>GENERATIVE SPORTS AND MEDIA</h1>
            <h5>Imagining New Worlds</h5>
            <button className="btn-contact">CONTACT US</button>
          </div>
        </div>
      </div>
      <div className="app-footer">
        <div className="container">
          <div className="app-footer-inner">
            Copyright &copy; 2023 Language Machines &ndash; All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
