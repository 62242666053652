import React, { useCallback, useEffect, useRef, useState } from "react";
import "./index.css";
import { Box, Button, CircularProgress, CssBaseline, Grid, IconButton, Slider, TextField, Typography } from "@mui/material";
import iconMovieOpenCogOutline from '../../assets/movie-open-cog-outline_1.svg';
import { Link, useParams } from "react-router-dom";
import videoCameraGif from '../../assets/video_camera.gif';
import videoFailedImage2 from '../../assets/video_failed@2x.png';
import iconShare from '../../assets/share_icon.svg';
import iconDelete from '../../assets/delete_icon.svg';
import iconPlayVideo from '../../assets/icon-video-play.svg';
import iconPauseVideo from '../../assets/icon-video-pause.svg'
import iconZoomVideo from '../../assets/icon-video-zoom.svg';
import iconFullVolume from '../../assets/iconFullVolume.svg';
import iconHalfVolume from '../../assets/iconHalfVolume.svg';
import iconMute from '../../assets/iconMute.svg';
import { ICurrentVideoState, VIRTUAL_VIDEO_STATUS } from "../../types/interface";
import ReactPlayer from 'react-player/lazy'
import { generatingVideoStatusMessage, whiteColor } from "../../common/constants";
import { getFormatedTime } from "../../common/utils";
import screenfull from 'screenfull';

interface IVirtualVideoPlayerProps {
    videoData: any;
}

export default function VirtualVideoPlayer(props: IVirtualVideoPlayerProps) {
    const {
        videoData
    } = props;

    const playerRef = useRef<ReactPlayer | null>(null);
    const [volume, setVolume] = useState(1);
    const [muted, setMuted] = useState<boolean>(false);

    //For video player
    const [currentVideoState, setCurrentVideoState] = useState<ICurrentVideoState>({
        url: videoData?.url ?? null,
        movie: {},
        pip: false,
        playing: false,
        controls: false,
        light: false,
        volume: 0.8,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false
    });
    const [playerReady, setPlayerReady] = useState<boolean>(false);
    const [loadingVideo, setLoadingVideo] = useState<boolean>(true);

    const handleClickFullscreen = () => {
        if (playerRef.current !== null) {
            screenfull.request(playerRef.current.getInternalPlayer() as unknown as Element)   
        }
    }
    
    useEffect(() => {
        setCurrentVideoState(val => ({...val, url: videoData?.url}));
    }, [videoData]);

    useEffect(() => {
        if (playerReady) {
            if (playerRef.current !== null) {                
                setLoadingVideo(false);
            }
        }
    }, [playerReady]);

    useEffect(() => {
        if (screenfull.isEnabled) {        
            screenfull.on('change', () => {                
                if(screenfull.isFullscreen) {
                    setCurrentVideoState(prev => ({...prev, controls:true}));
                } else {
                    setCurrentVideoState(prev => ({...prev, controls:false}));
                }
            });
        }
    },[])

    return (
        <>
            <Box className="gvb-video-wrapper">
                <Box className="gvb-video-inner">
                    { !!!videoData ? 
                    <Box sx={{width: '100%', height: '100%'}}></Box>
                     : 
                    (videoData?.status !== VIRTUAL_VIDEO_STATUS.COMPLETED) ?
                    (<Box className="gvb-video-content-container">
                        {videoData?.status === VIRTUAL_VIDEO_STATUS.FAILED ?
                        <>
                            <img src={videoFailedImage2} alt="" style={{width: '84px', marginBottom: '24px'}} />
                            <Typography sx={{fontSize: '20px'}}>Result unavailable</Typography>
                            <Typography sx={{fontSize: '14px', color: '#C5C5C5', marginTop: '16px'}}>
                                Video generation is failed due to some error.
                            </Typography>
                        </>
                        :
                        <>
                            <img src={videoCameraGif} alt="" />
                            <Typography sx={{fontSize: '20px'}}>{generatingVideoStatusMessage.label}</Typography>
                            <Typography sx={{fontSize: '14px', color: '#C5C5C5', marginTop: '16px'}}>
                                {generatingVideoStatusMessage.description}
                            </Typography>
                            <Typography sx={{fontSize: '14px', color: '#C5C5C5', marginTop: '5px'}}>
                                Your Video will be saved to <Link to={"/assets"} style={{color: '#FFFFFF', fontSize: '14px'}}>Assets</Link>.
                            </Typography>
                        </>
                        }
                    </Box>)
                    :
                    (<Box sx={{width: '100%', height: '100%'}}>
                        <Box sx={{
                            width: '100%', 
                            height: '100%',
                            minHeight: '400px', 
                            background: '#000000',
                            position: 'relative'
                        }}
                        >
                            <ReactPlayer 
                            className="video-player"
                            ref={playerRef}
                            url={currentVideoState.url || undefined}
                            width={"100%"}
                            height={"100%"}
                            playing={currentVideoState.playing}
                            controls={currentVideoState.controls}
                            volume={volume}
                            muted = {muted}
                            onReady={() => {
                            setPlayerReady(true);
                            }}
                            onPlay={() => setCurrentVideoState(val => ({...val, playing: true}))}
                            onPause={() => {
                            setCurrentVideoState(val => ({...val, playing: false}));
                            setLoadingVideo(false);
                            }}
                            onEnded={() => {
                            setCurrentVideoState(val => ({...val, playing: false}));
                            setLoadingVideo(false);
                            }}
                            onProgress={(state) => setCurrentVideoState(val => ({...val, played: state.played}))}
                            onDuration={(duration) => setCurrentVideoState(val => ({...val, duration: duration}))}
                            onBuffer={()=> {
                            setLoadingVideo(true)
                            }}
                            onBufferEnd={()=> {
                            setLoadingVideo(false)
                            }}
                            />
                        {(loadingVideo || !playerReady) && <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "absolute",
                                top: '0px',
                                left: '0px',
                                right: '0px',
                                bottom: '0px',
                                width: "100%",
                                height: "100%",
                                zIndex: "1",
                                background: "rgba(0,0,0,1)",
                            }}
                            >
                            <CircularProgress sx={{color: whiteColor}} />
                        </Box>}
                            
                        </Box>
                        <Grid container 
                            className="video-player-controls"
                            spacing={4}>
                            <Box className="video-player-controls-upper">
                                <IconButton 
                                className="play-btn"
                                aria-label="play-video" 
                                onClick={(event) => { 
                                    if (!currentVideoState.url) {
                                    // handleLoadMovie();
                                    } else {
                                    setCurrentVideoState(val => ({...val, playing: !val.playing}))                
                                    }
                                }}     
                                title={!currentVideoState.playing ? 'Play' : 'Pause'}
                                >
                                    {!currentVideoState.playing ? 
                                        <img src={iconPlayVideo} alt="" />
                                        : 
                                        <img src={iconPauseVideo} alt="" />
                                    }
                                </IconButton>
                                <Box className="volume-box"> 
                                    {
                                    muted?
                                    <img src={iconMute} className="cursor-pointer" alt="" 
                                    onClick={()=>{setMuted(false)}} />
                                    :
                                    (
                                    volume < 0.5 ? 
                                    <img src={iconHalfVolume} className="cursor-pointer" alt="" 
                                    onClick={()=>{setMuted(true)}}  />
                                    :
                                    <img src={iconFullVolume} className="cursor-pointer" alt="" 
                                    onClick={()=>{setMuted(true)}}  />
                                    )
                                    }
                                    <input
                                        className="volume-progress"
                                        type="range"
                                        min={0}
                                        max={1}
                                        step={0.02}
                                        value={volume}
                                        onChange={event => {
                                            setVolume(event.target.valueAsNumber)
                                            if(event.target.valueAsNumber == 0){
                                                setMuted(true)
                                            }else{
                                                setMuted(false)
                                            }
                                        }}
                                    />
                                </Box>
                                <Box className="controls-time">
                                    {`${(getFormatedTime(currentVideoState.played * currentVideoState.duration)) || '0:00'} / ${getFormatedTime(currentVideoState.duration)}`}
                                </Box>
                                <Box className="right-controls">
                                    <img className="full-screen" onClick={handleClickFullscreen} src={iconZoomVideo} alt="" />
                                </Box>
                            </Box>
                            <Box className="video-seeker-bar">
                                <Slider
                                    className="video-slider-seeker"
                                    sx={[{height: '6px', padding: '0', display: 'flex'}, (theme) => ({'& .MuiSlider-track': {border: 'none'}, '& .MuiSlider-thumb': {width: '12px', height: '12px', color: whiteColor}, '& .MuiSlider-rail': {height: '6px', borderRadius: 0, opacity: 1}})]}
                                    aria-labelledby="video-play-range-slider"
                                    getAriaValueText={(valuetext) => `${valuetext}s`}
                                    value={currentVideoState.played}
                                    onChange={(event, value, activeThumb) => {
                                        event.preventDefault(); 
                                        if (event.type === "mousedown") {
                                            setCurrentVideoState(val => ({...val, seeking: true})); 
                                            if (playerRef.current !== null) {
                                                playerRef.current.seekTo(value as number);                  
                                                // setCurrentVideoState(val => ({...val, playing: true}));            
                                            }
                                            return;         
                                        }              
                                        if (event.type === "mousemove") {
                                            setCurrentVideoState(val => ({...val, seeking: false}));   
                                            if (playerRef.current !== null) {
                                            playerRef.current.seekTo(value as number);                  
                                            }
                                            
                                        }
                                        setCurrentVideoState(val => ({...val, played: value as number}));                
                                    }}
                                    onChangeCommitted={(event, value) => {
                                    }}
                                    min={0}
                                    max={0.999999}
                                    step={0.000001}
                                    valueLabelDisplay="auto"
                                    valueLabelFormat={(x) => getFormatedTime(x * currentVideoState.duration)}
                                />
                            </Box>
                        </Grid>
                    </Box>)
                    }
                </Box>
            </Box>                        
        </>
    );
}