import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {useNavigate, useOutletContext} from 'react-router-dom';

import { toast } from "react-toastify";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ListItem, Chip, CircularProgress, Drawer, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, Tab, Tabs, TextField, Toolbar, Typography, styled, Skeleton } from "@mui/material";
import { blackColor, whiteColor } from "../../common/constants";
import { Favorite, PlayArrow, QueueMusic, SearchOutlined, Widgets } from "@mui/icons-material";
import { ISearchPayload, ViewBy } from "../../types/interface";
import { addWatchlistItem, getCategoriesForUser, getCategoryItems, getCategoryItemsForSearch, get_media_count, search } from "../../services/searchService";
import { useSearchParams } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import CardDetailDialog from "../../components/CardDetailDialog";
import PlaylistDialog from "../../components/PlaylistDialog";
import VideoTabContent from "../../components/SearchResultVideoTab";
import iconCategory from "../../assets/iconCategory.svg";


function otherProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >

            <Box sx={{ padding: "0px" }}>
                {children}
            </Box>

        </div>
    );
}

export default function Categories() {
    const [commonContext] = useOutletContext<any | null>();
    const {
        searchpayload, 
        mediaCountResult,
        showMediaCountResult,
    } = commonContext;

    const [searchParams, setSearchParams] = useSearchParams();
    let selected_item = searchParams.get("selectedItem");

    const [isLoadedData, setIsLoadedData] = useState<boolean>(false);

    //For storing active category data
    const [categoryData, setCategoryData] = useState<any>([]);

    //For storing categories
    const [categories, setCategories] = useState<any[]>([]);
    //For storing selected category
    const [selectedCategory, setselectedCategory] = useState<any>();

    //For storing selected item for timeline
    const [selectedItemForTimeline, setSelectedItemForTimeline] = useState<null | any>(null);
    
    const [isloading, setIsloading] = useState(false);

    //To control between timeline and list view
    const [viewBy, setViewBy] = useState<ViewBy>(selected_item ? ViewBy.Clip : ViewBy.List);
    
    //For CardDetailDialog
    const [openCardDetailDialog, setOpenCardDetailDialog] = useState<boolean>(false);
    const [selectedCardDetailDialogData, setselectedCardDetailDialogData] = useState<{type:string; value:any; autoplayVideo: boolean;}>({type: '', value: {}, autoplayVideo: false});

    //For Lightbox
    const [toggler, setToggler] = useState<boolean>(false);
    const [productIndex, setProductIndex] = useState<number>(1);
    const [fullScreenMediaSource, setFullScreenMediaSource] = useState<string>('');
    
    const navigate = useNavigate();

    const [tabvalue, setTabvalue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedItemForTimeline(null);
        setTabvalue(newValue);
    };

    const handleGetCategories = () => {
        let selected_category = searchParams.get("category");
        
        getCategoriesForUser()
            .then((response) => {
                setCategories([{name:"All"}].concat(response));
                handleUpdateCategoryData(selected_category ? response.find((rItem:any) => rItem?.id === selected_category) : {name: "All"});
            })
            .catch((error) => {
                console.log(error);
                
            })
    }

    //For updating active category data
    const handleUpdateCategoryData = (category: any) => {
        setIsLoadedData(false);
        getCategoryItemsForSearch(category.id ?? category.name)
            .then((response) => {
                //For selecting item for timeline on navigating from homepage on click of category timeline button
                selected_item && setSelectedItemForTimeline(response?.video?.videos?.find((rItem:any) => rItem.id === selected_item));

                setCategoryData(response || []);
                setselectedCategory(category);
                setIsLoadedData(true);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        // let category = searchParams.get("category")
        handleGetCategories();
    },[]);

    const handleCategorySelect = (cat:any) => {
        handleUpdateCategoryData(cat);        
    }

    const handleOpenCloseCardDetailDialog = (val:boolean) => {
        setOpenCardDetailDialog(val);
    };

    const handleClickPlay = () => {
        setToggler(val => !val);
        setFullScreenMediaSource(selectedCardDetailDialogData.value.url);
    }

    const handleClickMore = (dataObj: {type:string; value:any; autoplayVideo: boolean;}) => {
        setselectedCardDetailDialogData(dataObj);
        setOpenCardDetailDialog(true);
    }

    //For playlist dialog
    const [openPlaylistDialog, setOpenPlaylistDialog] = useState<boolean>(false);
    const [selectedPlaylistDialog, setSelectedPlaylistDialog] = useState<{type:string; value:any}>({type: '', value: {}});

    const handleOpenPlaylistDialog = (val:boolean) => {
        setOpenPlaylistDialog(val);
    };
    const handleAddToPlaylist = (dataObj: {type:string; value:any}) => {
        setSelectedPlaylistDialog(dataObj);
        setOpenPlaylistDialog(true);
    }
    
    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}} className="">
            <CssBaseline />
            {isloading && (
                <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "fixed",
                    width: "100%",
                    height: "100vh",
                    zIndex: "9999",
                    background: "rgba(0,0,0,0.75)",
                }}
                >
                <CircularProgress sx={{color: whiteColor}} />
                </Box>
            )}
            
            <Box className="page-min-height-1" sx={{ flexGrow: 1, marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>
                <Box className="playlist-tab">
                <Box className="container" sx={{ 
                        display: 'flex', 
                        alignItems: {lg:"center", md:"center", sm:"flex-start", xs:"flex-start"}, 
                        rowGap:{lg:0, md:0, sm:1.5, xs:1.5}, 
                        justifyContent: 'space-between', 
                        flexDirection:{lg:'row', md:'row', sm:'column', xs:'column'} 
                        }}>
                    <Box sx={{ display: 'flex', columnGap:"11px", alignItems:"center" }}>
                        <img src={iconCategory} alt="" />
                        <Typography sx={{color: "rgba(255, 255, 255, 0.7)", fontSize: '20px', fontWeight:"400"}}>Categories</Typography>
                    </Box>
                    <Box sx={{display: 'flex', rowGap:{lg:0, md:0, sm:1, xs:1}, alignItems: 'center', flexWrap:{lg:"wrap", md:"wrap", sm:"wrap", xs:"wrap", }, justifyContent:{lg:'flex-end', md: 'flex-end', sm: 'flex-start', xs: 'flex-start'}, columnGap:{lg:2, md:2, sm:1, xs:.5},}}>
                        {
                            categories?.map((item, index) => (
                                <ListItem 
                                key={index}
                                sx={{ padding: '0px', width: 'auto'}}
                                >
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="inherit"
                                        size="small"
                                        onClick={()=> {
                                            setSelectedItemForTimeline(null);

                                            //For removing selectedItem query param on change of category
                                            if (searchParams.has('selectedItem')) {
                                                searchParams.delete('selectedItem');
                                                setSearchParams(searchParams);
                                            }

                                            handleCategorySelect(item);
                                        }}
                                        className={item?.name === selectedCategory?.name ? "active" : ""}
                                    >
                                        {item?.name}
                                    </Button>
                                </ListItem>
                            )
                            )
                        }
                    </Box>
                </Box>
                </Box>

                <Box className="playlist-tab-2">
                    <Box className="container" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: "center" }}>
                            <Tabs
                                value={tabvalue}
                                onChange={handleTabChange}
                                // variant="fullWidth"
                                aria-label="categories-tabs"
                                className=""
                            >
                                <Tab label={<span>Videos <em>({categoryData?.video?.total || '0'})</em></span>} {...otherProps(0)} />
                            </Tabs>
                            <Box sx={{display: 'flex', paddingLeft: '60px'}}>
                                <Box sx={{fontSize: '16px', color: '#989898', display:'flex', alignItems:'center', columnGap:'12px'}}><b>Timeline</b>
                                    <Switch
                                        checked={viewBy === ViewBy.Clip}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setViewBy(event.target.checked ? ViewBy.Clip : ViewBy.List);
                                            if (!event.target.checked) {
                                                setSelectedItemForTimeline(null);                                                
                                            }
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        className="mui-switch"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <CustomTabPanel value={tabvalue} index={0}>
                    {isLoadedData ? (
                        categoryData.video?.videos?.length > 0 ? (
                            <Box className="container" sx={{ marginBottom: 8 }}>
                                <VideoTabContent 
                                    cardData={categoryData.video.videos}
                                    item={categoryData.video}
                                    tabType="video"
                                    viewBy={viewBy === 0 ? "list" : "clip"}
                                    setViewBy={(view) => setViewBy(view === "list" ? 0 : 1)}
                                    searchpayload={searchpayload}
                                    handleChangeInputs={() => {}}
                                    showMediaCountResult={showMediaCountResult}
                                    mediaCountResult={mediaCountResult}
                                    searchedInputForResult=""
                                    isActiveTab={tabvalue === 0}
                                    showTimelineButtonInListViewCard={true}
                                    showWatchlistButtonInListViewCard={true}
                                    showPlaylistButtonInListViewCard={true}
                                    showDebugButtonInListViewCard={false}
                                    withoutSearch={true}
                                    selectedItemForTimeline={selectedItemForTimeline}
                                    handleClickTimeline={(card, index) => {
                                        setSelectedItemForTimeline(card);
                                        setViewBy(ViewBy.Clip);
                                    }}
                                    canAddToPlaylist={true}
                                />
                            </Box>
                        ) : (
                            <Box className="no-data-message" >
                                <Typography variant="h6">There are currently no videos in this category. </Typography>
                            </Box>)
                    ) : (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', padding: '20px', gap: '40px', marginTop: 3 }} className="container">
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                            <Skeleton variant="rounded" width={286} height={311} style={{ color: '#cfcfcf', backgroundColor: '#4e474a', borderRadius: '16px' }} />
                        </Box>
                    )}
                </CustomTabPanel>

            </Box>
            {openCardDetailDialog && 
                <CardDetailDialog 
                    openCardDetailDialog={openCardDetailDialog}
                    handleOpenCloseCardDetailDialog={handleOpenCloseCardDetailDialog}
                    selectedCardDetailDialogData={selectedCardDetailDialogData}
                    handleClickPlay={handleClickPlay}
                    searchpayload={searchpayload}
                />
            }
            {openPlaylistDialog && 
            <PlaylistDialog 
                openPlaylistDialog={openPlaylistDialog}
                handleOpenClosePlaylistDialog={handleOpenPlaylistDialog}
                selectedPlaylistDialogData={selectedPlaylistDialog}
                handleCreatePlaylist = {()=>{}}
            />
          }
            <FsLightbox
                toggler={toggler}
                sources={[fullScreenMediaSource]}
                slide={productIndex}
                type={"video"}
            />
        </Box>
    );
}
