import React, { useState } from "react";
import {useNavigate} from 'react-router-dom';

import CssBaseline from "@mui/material/CssBaseline";
import {
  Grid,
  Button,
  Box,
  FormControl,
  InputAdornment,
  TextField,
  CircularProgress,
} from "@mui/material";
import {
  Favorite,
  PlayArrow,
  QueueMusic,
  SearchOutlined,
  Widgets,
} from "@mui/icons-material";
import { toast } from "react-toastify";

import { whiteColor } from "../../common/constants";
import { ISearchPayload } from "../../types/interface";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "./index.css";
import contactBanner from '../../assets/contact-banner.png'
import { get_media_count, search } from "../../services/searchService";
import { errorToast } from "../../common/utils";

const Contact = () => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [isloading, setIsloading] = useState(false);

  const [searchpayload, setSearchpayload] = useState<ISearchPayload>({
    text: "",
    search_for: "all",
    movies: [],
    moviesByVersion: [],
    version: "",
    unique: true,
    similarity_min: 0,
    similarity_max: 1,
    confidence: 30,
    is_dialog: false,
    apply_video_HS: false,
    topK_from_video_HS_set: "",
    topK_videos: "20",
    categories: [],
  });
  const [searchResult, setSearchResult] = useState<any>({});
  const [mediaCountResult, setMediaCountResult] = useState<any>({});

  const navigate = useNavigate();

  const handleDrawerOpenClose = () => {
    setSidebarOpen((prev) => !prev);
  };

  const handleChangeInputs = (event:any) => {
    const name = event.target.name;
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    if (name !== "text" && !searchpayload.text.length) {
        // toast.error("Search text must not be blank!");
        return;
    }
    setSearchpayload({...searchpayload, [name]: value});
};

const handleSubmitSearch = (event: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const reqBody = {
        text: searchpayload.text, 
        search_for: searchpayload.search_for,
        movies: searchpayload.movies.filter((filmItem:any) => filmItem.selected).map((flItem) => flItem.name),
        version: searchpayload.version,
        unique: searchpayload.unique,
        similarity_min: searchpayload.similarity_min,
        similarity_max: searchpayload.similarity_max,
        // confidence: confidenceSliderMarks.find((csmItem:any) => csmItem.value === selectedfilters.confidence)?.label
        is_dialogue: searchpayload.is_dialog,
        apply_video_HS: searchpayload.apply_video_HS,
        topK_from_video_HS_set: searchpayload.topK_from_video_HS_set.length ? Number(searchpayload.topK_from_video_HS_set) : -1,
        topK_videos: searchpayload.topK_videos.length ? Number(searchpayload.topK_videos) : 20
    };
    setIsloading(true);
    search(reqBody)
      .then((response) => {
        setSearchResult({});
        setSearchResult(response);
        setIsloading(false);

        const mediaCountsReqBody = {
            version: reqBody.version, 
            is_dialogue: reqBody.is_dialogue
            }
        handleMediaCount(mediaCountsReqBody, response);
      })
      .catch((error) => {
        setIsloading(false);
        errorToast(`Cannot search`);
      });
  };

  const handleMediaCount = (reqBody: any, searchResp:any) =>{
    get_media_count(reqBody)
      .then((response) => {

        setMediaCountResult(response);
        navigate('/search', {state: {searchQuery: searchpayload.text, searchResponse: searchResp, mediaCount: response}});

      })
      .catch((error) => {
      });
}

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}  className="contact-page">
      <CssBaseline />
      {isloading && (
            <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                width: "100%",
                height: "100vh",
                zIndex: "9999",
                background: "rgba(0,0,0,0.75)",
            }}
            >
            <CircularProgress sx={{color: whiteColor}} />
            </Box>
        )}
        <Box className="contact-banner">
          <img src={contactBanner} alt="" />
        </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
        }}
        maxWidth={"xl"}
      >
        <Box>

          <Grid
            container
            columnSpacing={10}
            className="contact-row"
          >
            <Grid item xs={12} sm={12} md={6} lg={5} xl={5} >
              <Box className="contact-info">
                <h3>Love to hear from you, Get in touch</h3>
                <p>
                  Fill up the form and our team will get back to you within 24
                  hours.
                </p>
                <ul>
                  <li className="first">hello.xyz@gmail.com</li>
                  <li className="second">30-2106019311 (landline)</li>
                  <li className="third">
                    Keas 69 Str. 15234, Chalandri Athens, Greece
                  </li>
                </ul>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
              <Box className="contact-form">
                <Grid container rowSpacing={5} columnSpacing={5}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      style={{ width: "100%" }}
                      type="text"
                      label="Full name*"
                      variant="outlined"
                      placeholder="Enter full name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      style={{ width: "100%" }}
                      type="text"
                      label="Email*"
                      variant="outlined"
                      placeholder="Enter email"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      style={{ width: "100%" }}
                      type="text"
                      label="Contact Number*"
                      variant="outlined"
                      placeholder="Enter contact number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      style={{ width: "100%" }}
                      type="text"
                      label="Full subject*"
                      variant="outlined"
                      placeholder="Enter subject"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      style={{ width: "100%" }}
                      type="text"
                      label="Full message*"
                      variant="outlined"
                      placeholder="Enter message"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"flex-end"} display={"flex"}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      
                      className="btn-submit"
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
