import type { CSSProperties, FC, ReactNode } from 'react'
import { useDrag } from 'react-dnd'

export interface BoxProps {
    itemObj: any;
    onDragEnd: (item:any) => void;
  children: ReactNode;
}

interface DropResult {
  name: string
}

export const DragBox: FC<BoxProps> = function Box({ itemObj, onDragEnd, children }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'box',
    item: { itemObj },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>()
      if (item && dropResult) {
        // alert(`You dropped ${JSON.stringify(item.itemObj)} !`)
        onDragEnd(itemObj);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))

  const opacity = isDragging ? 0.4 : 1
  return (
    <div ref={drag} style={{ opacity }} data-testid={`box`}>
      {children}
    </div>
  )
}
