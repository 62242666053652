import * as React from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { Box, DialogContent, Divider, IconButton } from '@mui/material';
import { blackColor, whiteColor } from '../../common/constants';
import { Cancel, Favorite, PestControlOutlined, PlayArrow, PlayCircleRounded } from '@mui/icons-material';
import { errorToast, getConfidenceColoByConfidence, successToast } from '../../common/utils';
import './index.css';
import { addItemInPlaylist, createUserPlaylist, create_playListBulk, getPlaylistsForUser } from '../../services/searchService';
import { toast } from 'react-toastify';
import { useWatchList } from '../../hooks/useWatchList';

export interface PlaylistDialogProps {
  openPlaylistDialog: boolean;
  handleOpenClosePlaylistDialog: (value: boolean) => void;
  selectedPlaylistDialogData: {type:string; value:any};
  addPlaylistCase?: boolean;
  handleCreatePlaylist: (value: string) => void;
  bulkInsert?: boolean;
}

export default function PlaylistDialog(props: PlaylistDialogProps) {
  const { openPlaylistDialog, handleOpenClosePlaylistDialog, selectedPlaylistDialogData: {type, value:card}, addPlaylistCase = false, handleCreatePlaylist,bulkInsert} = props;
  const [playlists, setPlaylists] = React.useState<any[]>([]);
  // State with list of all checked item
  const [checkedList, setCheckedList] = React.useState<any[]>([]);
  const [showAddPlaylist, setShowAddPlaylist] = React.useState<boolean>(addPlaylistCase);
  const [newPlaylistName, setNewPlaylistName] = React.useState<string>('');
  const [watchlistData, updateWatchlist] = useWatchList() as any[];

  const handleSelect = (event: { target: { value: any; checked: any; }; }) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
 
    if (isChecked) {
      //Add checked item into checkList
      setCheckedList([...checkedList, value]);
    } else {
      //Remove unchecked item from checkList
      const filteredList = checkedList.filter((item) => item !== value);
      setCheckedList(filteredList);
    }
  };
  
  const handleClose = () => {
    handleOpenClosePlaylistDialog(false);
  };

  const getData = async () => {
    const result  = await getPlaylistsForUser()
    setPlaylists(result)
 }

  React.useEffect(() => {
    getData();    
  },[]);

  const addItem = async () => {
    for(let i = 0; i< checkedList.length; i++){
      const requestBody = {
        playlist_id: checkedList[i],
        oracle_id: card.id
      }
      const result = await addItemInPlaylist(requestBody);
      handleOpenClosePlaylistDialog(false);
    }
    updateWatchlist();
  }

  const handleAddNewPlaylistName = () => {
    if(!bulkInsert){
    const requestBody = {
        name: newPlaylistName
    }
    createUserPlaylist(requestBody)
    .then((response) => {
      successToast('New playlist name is added successfully.');
      setNewPlaylistName('');

      if (addPlaylistCase) {
        handleClose();
      } else {
        getData();
        setShowAddPlaylist(false);
      }
      handleCreatePlaylist(newPlaylistName)
    })
    .catch(((error) => {
      errorToast(`Cannot add new playlist name`);
    })) 
    } else {
      const requestBody = {
        name: newPlaylistName,
        items: card?.map((item: any) => item.id)
      }
      create_playListBulk(requestBody)
      .then((response) => {
        handleClose();
        successToast('New playlist created successfully.');
      })
      .catch(((error) => {
        if (error?.response && error.response?.status === 409) {
          errorToast(`${error.response?.data?.detail}`);        
        } else {
          errorToast(`Cannot create new playlist`);
        }
      }))
    }
  };

  return (
    <Dialog onClose={handleClose} open={openPlaylistDialog} sx={[(theme) => ({'.MuiDialog-paper': {minWidth: '280px', minHeight: 'auto', borderRadius: '6px', background: blackColor, position: 'relative', overflow: 'initial'}, '.MuiDialog-container': {background: 'rgba(42,42,42,0.6)'}})]}
    className='common-dailog'
    >

      <Box className="btn-cancel" onClick={() => handleClose()}>
          <span>

          </span>
      </Box>
      <DialogContent sx={{padding: '21px 16px', color: whiteColor, fontWeight: '300'}}>

        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <h3>
             {showAddPlaylist ? 'Add new play List name' : 'Add to Playlist'}
          </h3>
          <div className="inner-dailog">
          {!showAddPlaylist ?
            <ul>
              {
                playlists.map((item, index)=>{
                  return (
                    <li>
                      <div key={index} className="checkbox-container">
                        <label className="listhead listhead-checkbox">
                        {item.name}
                          <input
                          type="checkbox"
                          name="playlists"
                          value={item.id}
                          onChange={handleSelect}
                        />
                          <div className="control_indicator"></div>
                        </label>
                      </div>
                    </li>
                  )
                }) 
              }
            </ul>
            :  
            <input 
              className='form-control' 
              placeholder='Write name here...' 
              name='add-new-playlist-name'
              value={newPlaylistName}
              onChange={(e) => setNewPlaylistName(e.target.value)}
              required
            />
            }

              {!showAddPlaylist && <button className='btn-none' onClick={() => setShowAddPlaylist(true)}>
                <span></span> Add new playlist name
              </button>}
            {/* need to update functionality */}
          </div>

          {/* <Box className='sep' sx={{marginBottom:'10px'}}></Box> */}
          
          {!showAddPlaylist ? <Button variant='contained'
          className="btn-blue"

          onClick={(event)=>{
            addItem()
          }}
          >Done</Button>
          :
          <Button 
            variant='contained'
            className="btn-blue"
            sx={[(theme) => ({'&.Mui-disabled': {backgroundColor: 'rgba(38, 116, 227, 0.5)'}})]}
            disabled={!newPlaylistName.length}
            onClick={(event)=>{
              handleAddNewPlaylistName()
            }}
          >
            Save
          </Button>}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
