import { CheckCircle } from "@mui/icons-material";
import { Card, CardActionArea, CardContent, CardMedia, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { whiteColor } from "../../common/constants";
import iconPlay from '../../assets/icon-play-video-white-hover.svg'
import iconCheck from '../../assets/icon-check-gray.svg'
import iconChecked from '../../assets/icon-checked-gray.svg'

export default function VideoSelectCard({ card, component, setToggler, setProductIndex, handleChangeFilmslist }: { card: any; component: any; setToggler: () => void; setProductIndex: () => void; handleChangeFilmslist: () => void }) {
    return (
      <>
        <Card sx={{ display: "flex", flexDirection: "row", position: 'relative' }} className="film-list-card movie-list">
          <div className="movie-icon">
            <CardMedia
            component={"img"}
            sx={{
              width: "25%",
              minWidth: "25%",
              objectFit: 'fill',
              borderRadius:"10px"
            }}
             src={card.thumbnail_url}
            />
            <div className="icon-play-video"  onClick={() => {setProductIndex(); setToggler()}}>
              <img src={iconPlay} alt="" />
            </div>
          </div>
          {/* <IconButton aria-label="play" style={{position: 'absolute', top: '6%', right: '8px', zIndex:"9999"}}>
              <img src="/images/play_btn.png" alt="play" style={{width: '36px' }} />
          </IconButton> */}
          <CardActionArea onClick={() => handleChangeFilmslist()} className='card-description'>
            <CardContent sx={{padding:0}} >
              <div className="checkbox-icon">
                  {card.selected ? <img src={iconChecked} alt='' className="checked" /> : <img src={iconCheck} alt='' className="unchecked" />}
              </div>
              <Typography className="h3">
                  {card.display_name}
              </Typography>
              <Typography className="h4" >
                  {card.year}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </>
    );
  }
  